import * as z from 'zod';
import { useFeatureFlagConfig } from '@/shared/providers/FeatureFlagConfigProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Card } from '@/components-shad/ui/card';
import { FormTextField } from '@/components-shad/form';
import { Button } from '@/components-shad/ui/button';
import { PlusIcon, XIcon } from 'lucide-react';
import { Switch } from '@/components-shad/ui/switch';

const schema = z.object({
  key: z.string().trim().nonempty(),
});
type FormData = z.infer<typeof schema>;

export function FeaturesShad() {
  const { featureFlags, setFeatureFlag, deleteFeatureFlag } = useFeatureFlagConfig();
  const { control, handleSubmit, reset } = useForm<FormData>({ resolver: zodResolver(schema) });

  return (
    <Page>
      <Header title="Features" />
      <PageContent variant="column">
        <Card>
          <div className="flex flex-col gap-6">
            <form
              onSubmit={handleSubmit(({ key }) => {
                setFeatureFlag(key, false);
                // must be in a timeout to properly reset the validation mode of the form post-submission
                setTimeout(reset);
              })}
              className="flex gap-2"
            >
              <FormTextField
                control={control}
                name="key"
                placeholder="Add a feature flag"
                autoFocus
              />
              <div>
                <Button type="submit" icon={<PlusIcon />}>
                  Add
                </Button>
              </div>
            </form>

            <div className="grid grid-cols-[auto_1fr] gap-x-6 gap-y-2">
              {Object.entries(featureFlags).map(([key, isEnabled]) => (
                <div key={key} className="contents">
                  <Switch
                    isSelected={isEnabled}
                    onChange={(value) => {
                      setFeatureFlag(key, value);
                      // TODO LYNK-3372: remove this special case
                      if (key === 'new-ui') {
                        setTimeout(() => window.location.reload(), 100);
                      }
                    }}
                  >
                    {key}
                  </Switch>
                  <Button
                    variant="destructive"
                    size="sm"
                    icon={<XIcon />}
                    aria-label={`Delete ${key}`}
                    onPress={() => {
                      deleteFeatureFlag(key);
                      // TODO LYNK-3372: remove this special case
                      if (key === 'new-ui' && isEnabled) {
                        setTimeout(() => window.location.reload(), 100);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </Card>
      </PageContent>
    </Page>
  );
}

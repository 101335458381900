import { PropsWithChildren } from 'react';

/**
 * Places children in a flexbox in case multiple tool bars exist for a table, for example when
 * the table has both tabs and a search panel:
 *
 * ```
 * <DataTableHeader>
 *   <Tabs/>
 *   <SearchPanel/>
 * </DataTableHeader>
 * ```
 */
export function DataTableHeader({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-3">{children}</div>;
}

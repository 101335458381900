import * as z from 'zod';
import { ShippingPriceDTO } from '@invenco/common-interface/shipping';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FormCombobox,
  FormDatePicker,
  FormTextField,
  getFormDefaults,
} from '@/components-shad/form';
import { useQueryWithInput } from '@/shared/hooks/queries';
import { useGateways } from '@/gateways/GatewayProvider';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { ComboboxItem } from '@/components-shad/ui/combobox';
import { useEffect, useState } from 'react';
import { Checkbox } from '@/components-shad/ui/checkbox';

const schema = z
  .object({
    name: z.string().nonempty(),
    accountId: z.string().nonempty(),
    validFrom: z.string().date(),
    validTo: z.string().date(),
    locationId: z.string().nullish(),
  })
  .refine((data) => new Date(data.validFrom) < new Date(data.validTo), {
    message: 'Must be a date occurring after valid from',
    path: ['validTo'],
  });
type FormData = z.infer<typeof schema>;

type Props = {
  isNew: boolean;
  shippingPrice: Partial<ShippingPriceDTO>;
  onSubmit: (data: Partial<ShippingPriceDTO>) => void;
  isLoadingAccounts: boolean;
  accounts: AccountDTO[];
};

export function ShippingPriceDetailDialog({
  isNew,
  shippingPrice,
  onSubmit,
  isLoadingAccounts,
  accounts,
}: Props) {
  const { accountsGateway } = useGateways();
  const { control, handleSubmit, watch, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getFormDefaults(schema, shippingPrice),
  });
  const [hasLocation, setHasLocation] = useState(!!shippingPrice.locationId);
  const selectedAccountId = watch('accountId', shippingPrice.accountId);

  const { data: locations, isLoading: isLoadingLocations } = useQueryWithInput({
    parentKey: 'accounts',
    input: selectedAccountId,
    query: (input, { signal }) => accountsGateway.getAccount(input, { signal }),
    select: (data) => data?.locations || [],
    enabled: !!selectedAccountId,
  });

  useEffect(() => {
    setValue(
      'locationId',
      selectedAccountId === shippingPrice.accountId && hasLocation
        ? shippingPrice.locationId
        : null,
    );
  }, [setValue, selectedAccountId, shippingPrice.accountId, shippingPrice.locationId, hasLocation]);

  return (
    <FormDialog form={{ handleSubmit }} onSubmit={onSubmit} title="Edit shipping price">
      <FormTextField control={control} name="name" label="Name" />
      <FormCombobox
        control={control}
        name="accountId"
        label="Account"
        isLoading={isLoadingAccounts}
        defaultItems={accounts}
        isDisabled={!isNew}
        description={
          !isNew ? 'Account cannot be changed after creating the shipping price' : undefined
        }
      >
        {(account) => <ComboboxItem>{account.name}</ComboboxItem>}
      </FormCombobox>

      <FormDatePicker control={control} name="validFrom" label="Valid from" />
      <FormDatePicker control={control} name="validTo" label="Until" />

      <Checkbox isSelected={hasLocation} onChange={setHasLocation}>
        Apply this price to a specific location
      </Checkbox>

      {hasLocation && (
        <FormCombobox
          control={control}
          name="locationId"
          label="Location"
          isLoading={isLoadingLocations}
          defaultItems={locations}
        >
          {(location) => <ComboboxItem>{location.name}</ComboboxItem>}
        </FormCombobox>
      )}
    </FormDialog>
  );
}

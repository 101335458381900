import {
  Tab as AriaTab,
  TabList as AriaTabList,
  TabListProps as AriaTabListProps,
  TabPanel as AriaTabPanel,
  TabPanelProps as AriaTabPanelProps,
  TabProps as AriaTabProps,
  Tabs as AriaTabs,
  TabsProps as AriaTabsProps,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

function Tabs({ className, ...props }: AriaTabsProps) {
  return (
    <AriaTabs
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'group flex flex-col gap-2',
          /* Orientation */
          'data-[orientation=vertical]:flex-row',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

function TabList<T extends object>({ className, ...props }: AriaTabListProps<T>) {
  return (
    <AriaTabList
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground',
          /* Orientation */
          'data-[orientation=vertical]:h-auto data-[orientation=vertical]:flex-col',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

function Tab({ className, ...props }: AriaTabProps) {
  return (
    <AriaTab
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'inline-flex cursor-pointer justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium outline-none ring-offset-background transition-all',
          /* Focus Visible */
          'data-[focus-visible]:ring-2 data-[focus-visible]:ring-ring data-[focus-visible]:ring-offset-2',
          /* Disabled */
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          /* Selected */
          'data-[selected]:bg-background data-[selected]:text-foreground data-[selected]:shadow',
          /* Orientation */
          'group-data-[orientation=vertical]:w-full',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

function TabPanel({ className, ...props }: AriaTabPanelProps) {
  return (
    <AriaTabPanel
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'mt-2 ring-offset-background',
          /* Focus Visible */
          'data-[focus-visible]:outline-none data-[focus-visible]:ring-2 data-[focus-visible]:ring-ring data-[focus-visible]:ring-offset-2',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

export { Tabs, TabList, TabPanel, Tab };

import React from 'react';
import { ChevronDownIcon, LoaderCircleIcon } from 'lucide-react';
import {
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
  composeRenderProps,
  ListBox as AriaListBox,
  ListBoxProps as AriaListBoxProps,
  PopoverProps as AriaPopoverProps,
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  SelectValue as AriaSelectValue,
  SelectValueProps as AriaSelectValueProps,
  ValidationResult as AriaValidationResult,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { FieldDescription, FieldError, Label } from './field';
import { ListBoxCollection, ListBoxHeader, ListBoxItem, ListBoxSection } from './list-box';
import { Popover } from './popover';

const BaseSelect = AriaSelect;

const SelectItem = ListBoxItem;

const SelectHeader = ListBoxHeader;

const SelectSection = ListBoxSection;

const SelectCollection = ListBoxCollection;

function SelectListBox<T extends object>({ className, ...props }: AriaListBoxProps<T>) {
  return (
    <AriaListBox
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'max-h-[inherit] overflow-auto p-1 outline-none [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]',
          /* Empty */
          'data-[empty]:p-6 data-[empty]:text-center data-[empty]:text-sm',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

function SelectValue<T extends object>({ className, ...props }: AriaSelectValueProps<T>) {
  return (
    <AriaSelectValue
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'line-clamp-1 data-[placeholder]:text-muted-foreground',
          /* Description */
          '[&>[slot=description]]:hidden',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

interface SelectTriggerProps extends AriaButtonProps {
  isLoading?: boolean; // different from isPending which is for actions triggered by the button
}
const SelectTrigger = React.forwardRef<React.ElementRef<typeof AriaButton>, SelectTriggerProps>(
  ({ className, isLoading, children, ...props }, ref) => (
    <AriaButton
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'flex h-9 w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm',
          /* Disabled */
          'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
          /* Focused */
          'data-[focus-visible]:outline-none data-[focus-visible]:ring-1 data-[focus-visible]:ring-ring',
          /* Resets */
          'focus-visible:outline-none',
          composedClassName,
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (composedChildren) => (
        <>
          {composedChildren}
          {isLoading ? (
            // TODO: LYNK-3438 replace with accessible loader
            <LoaderCircleIcon aria-hidden="true" className="size-4 animate-spin opacity-50" />
          ) : (
            <ChevronDownIcon aria-hidden="true" className="size-4 opacity-50" />
          )}
        </>
      ))}
    </AriaButton>
  ),
);

function SelectPopover({ className, ...props }: AriaPopoverProps) {
  return (
    <Popover
      className={composeRenderProps(className, (composedClassName) =>
        cn('w-[--trigger-width]', composedClassName),
      )}
      {...props}
    />
  );
}

interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'children'>,
    Pick<AriaListBoxProps<T>, 'children' | 'items'> {
  isLoading?: boolean;
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

// TODO: remove this once upgraded to React 19
type SelectRefT = React.Ref<React.ElementRef<typeof SelectTrigger>>;
const Select = React.forwardRef(
  <T extends object>(
    {
      label,
      description,
      errorMessage,
      autoFocus,
      items,
      isLoading,
      className,
      children,
      ...props
    }: SelectProps<T>,
    ref: SelectRefT,
  ) => (
    <BaseSelect
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      <Label>{label}</Label>
      <SelectTrigger autoFocus={autoFocus} ref={ref} isLoading={isLoading}>
        <SelectValue />
      </SelectTrigger>
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
      <SelectPopover>
        <SelectListBox items={items}>{children}</SelectListBox>
      </SelectPopover>
    </BaseSelect>
  ),
) as <T extends object>(props: SelectProps<T> & { ref?: SelectRefT }) => React.ReactElement;

export {
  BaseSelect,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectPopover,
  SelectHeader,
  SelectListBox,
  SelectSection,
  SelectCollection,
  Select,
};
export type { SelectProps };

import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { ShippingMethodDTO } from '@invenco/common-interface/shipping';
import {
  ShippingMethodStatus,
  ShippingMethodRateMatchMethod,
  ShippingMethodRateCalculationMethod,
  ShippingMethodAutoInvite,
  ShippingMethodType,
} from '@invenco/common-domain/enums/ShippingMethod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { FormDialog } from '../../../../../../components-shad/form-dialog/FormDialog';
import { FormCombobox, FormSelect, FormTextField } from '../../../../../../components-shad/form';
import { SelectItem } from '../../../../../../components-shad/ui/select';
import { ComboboxItem } from '../../../../../../components-shad/ui/combobox';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import {
  shippingMethodStatusTitle,
  shippingMethodRateMatchMethodTitle,
  shippingMethodRateCalculationMethodTitle,
  shippingMethodAutoInviteTitle,
} from '../../../../../../shared/title-maps';
import { useGatewayMutation, useQueryWithInput } from '../../../../../../shared/hooks/queries';
import { Alert, AlertDescription, AlertTitle } from '../../../../../../components-shad/ui/alert';

const shippingMethodStatusItems = Object.entries(ShippingMethodStatus).map(([key, value]) => ({
  id: key,
  value: key,
  label: shippingMethodStatusTitle[value],
}));

const rateMatchMethodItems = Object.entries(ShippingMethodRateMatchMethod).map(([key, value]) => ({
  id: key,
  value: key,
  label: shippingMethodRateMatchMethodTitle[value],
}));

const rateCalculationMethodItems = Object.entries(ShippingMethodRateCalculationMethod).map(
  ([key, value]) => ({
    id: key,
    value: key,
    label: shippingMethodRateCalculationMethodTitle[value],
  }),
);

const autoInviteItems = Object.entries(ShippingMethodAutoInvite).map(([key, value]) => ({
  id: key,
  value: key,
  label: shippingMethodAutoInviteTitle[value],
}));

type Props = {
  existingShippingMethods: ShippingMethodDTO[];
  accountId: string;
  locationId: string;
};

const formSchema = z.object({
  carrierServiceId: z.string().nonempty(),
  status: z.nativeEnum(ShippingMethodStatus),
  ratesCarrierAccountId: z.string().nonempty(),
  bookingCarrierAccountId: z.string().nonempty(),
  name: z.string().nonempty(),
  rateMatchMethod: z.nativeEnum(ShippingMethodRateMatchMethod),
  rateCalculationMethod: z.nativeEnum(ShippingMethodRateCalculationMethod),
  autoInvite: z.nativeEnum(ShippingMethodAutoInvite),
});

type ShippingMethodFormData = z.infer<typeof formSchema>;

export function WarehouseShippingMethodDialog({
  existingShippingMethods,
  accountId,
  locationId,
}: Props) {
  const { shippingGateway } = useGateways();

  const { handleSubmit, control, watch, setValue } = useForm<ShippingMethodFormData>({
    resolver: zodResolver(formSchema),
  });

  const { data: allCarrierServices, isLoading: isLoadingCarrierServices } = useQuery({
    queryKey: ['carrierServices'],
    queryFn: ({ signal }) => shippingGateway.getCarrierServices(undefined, { signal }),
  });

  /**
   * Users can only select carrier services that are not already
   * used in a shipping method
   *
   */
  const selectableCarrierServices = useMemo(() => {
    const previouslyAddedCarrierServiceIds = new Set(
      existingShippingMethods.map((method) => method.carrierServiceId),
    );

    const remainingCarrierServices =
      allCarrierServices?.items.filter(
        (carrierService) =>
          !carrierService.id || !previouslyAddedCarrierServiceIds.has(carrierService.id),
      ) ?? []; /* when error or loading */

    return remainingCarrierServices;
  }, [allCarrierServices, existingShippingMethods]);

  const selectedCarrierServiceId = watch('carrierServiceId');

  /**
   * Needed to:
   * - lookup the related Carrier ID and fetch carrier accounts for the
   *   rates and booking dropdowns
   * - Prefill the name and rateMatchMethod form fields
   */
  const selectedService = useMemo(
    () =>
      selectableCarrierServices.find(
        (carrierService) => carrierService.id === selectedCarrierServiceId,
      ),
    [selectableCarrierServices, selectedCarrierServiceId],
  );

  const { data: carrierAccounts, isLoading: isLoadingCarrierAccounts } = useQueryWithInput({
    parentKey: 'carrierAccounts',
    input: { accountId, carrierIds: selectedService?.carrierId ? [selectedService.carrierId] : [] },
    query: (input, { signal }) => shippingGateway.getCarrierAccounts(input, { signal }),
    enabled: selectedService !== undefined,
  });

  useEffect(() => {
    if (selectedService) {
      setValue('name', selectedService?.name ?? '', {
        shouldDirty: true,
      });
      setValue('rateMatchMethod', ShippingMethodRateMatchMethod[selectedService.rateMatchMethod], {
        shouldDirty: true,
      });
    }
  }, [selectedService, setValue]);

  const { mutate } = useGatewayMutation({
    mutationFn: (form: ShippingMethodFormData) =>
      shippingGateway.createShippingMethod({
        ...form,
        accountId,
        locationId,
        type: ShippingMethodType.WAREHOUSE,
      }),
    linkedQuery: ['shippingMethods', { locationId }],
    linkResponseToQuery: false,
    successMessage: 'Warehouse shipping method added',
  });

  const onSubmit = (form: ShippingMethodFormData) => mutate(form);

  const areAllCarriersAdded = selectableCarrierServices.length === 0 && !isLoadingCarrierServices;

  return (
    <FormDialog
      title="Add warehouse shipping method"
      description="Add a new shipping method to this warehouse"
      form={{ handleSubmit }}
      onSubmit={onSubmit}
      isSubmitDisabled={areAllCarriersAdded}
      // when true, combobox input is focused but fails to open popover. Popover can then only open
      // by clicking the icon rather than the full input. Easier to manually focus which instantly
      // opens the popover
      autoFocus={false}
    >
      {areAllCarriersAdded && (
        <Alert>
          <AlertTitle>All carrier services have been added</AlertTitle>
          <AlertDescription>
            All available carrier services have already been assigned to this warehouse
          </AlertDescription>
        </Alert>
      )}

      <FormCombobox
        label="Carrier Service"
        control={control}
        name="carrierServiceId"
        defaultItems={selectableCarrierServices}
        isLoading={isLoadingCarrierServices}
        placeholder="Select a carrier service"
      >
        {(carrierService) => <ComboboxItem>{carrierService.name}</ComboboxItem>}
      </FormCombobox>

      <FormSelect label="Status" control={control} name="status" items={shippingMethodStatusItems}>
        {(status) => <SelectItem>{status.label}</SelectItem>}
      </FormSelect>

      <FormCombobox
        label="Rates Carrier Account"
        control={control}
        name="ratesCarrierAccountId"
        defaultItems={carrierAccounts?.items}
        isLoading={isLoadingCarrierAccounts}
        placeholder="Select a carrier account"
      >
        {(carrierAccount) => <ComboboxItem>{carrierAccount.name}</ComboboxItem>}
      </FormCombobox>

      <FormCombobox
        label="Booking Carrier Account"
        control={control}
        name="bookingCarrierAccountId"
        defaultItems={carrierAccounts?.items}
        isLoading={isLoadingCarrierAccounts}
        placeholder="Select a carrier account"
      >
        {(carrierAccount) => <ComboboxItem>{carrierAccount.name}</ComboboxItem>}
      </FormCombobox>

      <FormTextField label="Name" control={control} name="name" />

      <FormSelect
        label="Rate Match Method"
        control={control}
        name="rateMatchMethod"
        items={rateMatchMethodItems}
      >
        {(rateMatchMethod) => <SelectItem>{rateMatchMethod.label}</SelectItem>}
      </FormSelect>

      <FormSelect
        label="Rate Calculation Method"
        control={control}
        name="rateCalculationMethod"
        items={rateCalculationMethodItems}
      >
        {(rateCalculationMethod) => <SelectItem>{rateCalculationMethod.label}</SelectItem>}
      </FormSelect>

      <FormSelect label="Auto Invite" control={control} name="autoInvite" items={autoInviteItems}>
        {(autoInvite) => <SelectItem>{autoInvite.label}</SelectItem>}
      </FormSelect>
    </FormDialog>
  );
}

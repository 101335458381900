import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import {
  Button as AriaButton,
  composeRenderProps,
  type ButtonProps as AriaButtonProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';
import { LoaderCircleIcon } from 'lucide-react';

export const buttonVariants = cva(
  [
    'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-[background-color,color,border-color,opacity]',
    /* Disabled */
    'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
    /* Focus Visible */
    'data-[focus-visible]:outline-none data-[focus-visible]:ring-1 data-[focus-visible]:ring-ring',
    /* Resets */
    'focus-visible:outline-none',
    /* Icons */
    '[&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  ],
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground shadow data-[hovered]:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm data-[hovered]:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-sm data-[hovered]:bg-accent data-[hovered]:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm data-[hovered]:bg-secondary/80',
        ghost: 'data-[hovered]:bg-accent data-[hovered]:text-accent-foreground',
        link: 'text-primary underline-offset-4 data-[hovered]:underline',
        transparent: '',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 px-3 text-xs [&_svg]:size-3',
        lg: 'h-10 px-8',
        inline: '', // remove all padding to make the button's content inline
      },
      iconOnly: { true: 'px-0' },
    },
    compoundVariants: [
      { size: 'default', iconOnly: true, className: 'size-9' },
      { size: 'sm', iconOnly: true, className: 'size-8 [&_svg]:size-4' },
      { size: 'lg', iconOnly: true, className: 'size-10' },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
      iconOnly: false,
    },
  },
);

export interface ButtonProps
  extends AriaButtonProps,
    Omit<VariantProps<typeof buttonVariants>, 'iconOnly'> {
  /**
   * Displays an icon to the left of the button text. When pending, this icon will be replaced by a spinner.
   *
   * If no children are given to the button, it will be shaped as a square (sized according to `size`) around the icon.
   * Be sure to provide an `aria-label` if this is the case.
   */
  icon?: React.ReactNode;

  /**
   * Displays a spinner in place of the icon when the button is pending. Defaults to true. Override this if you have
   * a custom pending state to display.
   */
  showPendingSpinner?: boolean;
}

export const Button = React.forwardRef<React.ElementRef<typeof AriaButton>, ButtonProps>(
  ({ className, variant, size, icon, showPendingSpinner = true, children, ...props }, ref) => (
    <AriaButton
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          buttonVariants({
            variant,
            size,
            iconOnly: !!icon && children === undefined,
            className: composedClassName,
          }),
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (composedChildren, { isPending }) => (
        // TODO: LYNK-3438 replace loader icon with accessible loader and find a way to avoid/smooth layout shift
        <>
          {isPending && showPendingSpinner ? <LoaderCircleIcon className="animate-spin" /> : icon}
          {composedChildren}
        </>
      ))}
    </AriaButton>
  ),
);

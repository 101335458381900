import * as z from 'zod';
import { CarrierDTO } from '@invenco/common-interface/shipping';
import { FormCombobox, getFormDefaults } from '@/components-shad/form';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ComboboxItem } from '@/components-shad/ui/combobox';

const schema = z.object({
  carrierId: z.string().nonempty(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  isLoadingCarriers: boolean;
  carriers: CarrierDTO[];
  onSubmit: (carrier: CarrierDTO) => void;
};

export function CarrierSelectionDialog({ isLoadingCarriers, carriers, onSubmit }: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getFormDefaults(schema),
  });

  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={(data) => onSubmit(carriers.find(({ id }) => id === data.carrierId)!)}
      title="Select carrier"
    >
      <FormCombobox
        control={control}
        name="carrierId"
        label="Carrier"
        isLoading={isLoadingCarriers}
        defaultItems={carriers}
      >
        {(carrier) => <ComboboxItem>{carrier.name}</ComboboxItem>}
      </FormCombobox>
    </FormDialog>
  );
}

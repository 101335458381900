import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuLink,
  SidebarMenuSub,
  SidebarMenuSubLink,
  SidebarMenuSubItem,
  SidebarRail,
} from '@/components-shad/ui/sidebar';
import LogoIcon from 'assets/lynk-icon.svg?react';
import LogoName from 'assets/lynk-name.svg?react';
import { useAuthToken } from '@/shared/hooks/useAuthToken';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components-shad/ui/collapsible';
import { ChevronRightIcon } from 'lucide-react';
import { useLocation } from 'react-router';
import { useMemo } from 'react';
import { getItems } from '@/pages/home/navigation-shad/items';
import { AccountMenu } from '@/pages/home/navigation-shad/account-menu/AccountMenu';

export function NavigationSidebar() {
  const { accessContext } = useAuthToken();
  const location = useLocation();

  const items = useMemo(
    () =>
      getItems(accessContext).map((item) => ({
        ...item,
        isActive: item.url ? location.pathname.startsWith(item.url) : false,
      })),
    [accessContext, location.pathname],
  );

  return (
    <Sidebar variant="inset" collapsible="icon">
      <SidebarHeader>
        <AccountMenu />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {items.map((item) => {
              if (!item.children?.length) {
                return (
                  <SidebarMenuLink
                    key={item.title}
                    tooltip={item.title}
                    isActive={item.isActive}
                    href={item.url}
                  >
                    {item.icon && <item.icon />}
                    <span>{item.title}</span>
                  </SidebarMenuLink>
                );
              }
              return (
                <Collapsible
                  asChild
                  key={item.title}
                  className="group/collapsible"
                  defaultOpen={item.isActive}
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton tooltip={item.title} isActive={item.isActive}>
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                        <ChevronRightIcon className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </SidebarMenuButton>
                    </CollapsibleTrigger>

                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.children?.map((subItem) => (
                          <SidebarMenuSubItem key={subItem.title}>
                            <SidebarMenuSubLink href={subItem.url}>
                              {subItem.title}
                            </SidebarMenuSubLink>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              );
            })}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <div className="flex items-center gap-2 px-2 py-2 transition-colors">
          <LogoIcon className="h-5 w-5 shrink-0 rotate-0 transition-transform ease-in-out dark:rotate-[720deg]" />
          <LogoName className="h-4 w-auto group-data-[collapsible=icon]:hidden" />
        </div>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}

import { Form } from 'antd';
import { Input } from '../input';
import { BaseRow } from '../list-table/types';
import { StyledFormItem } from './styles';
import { EditableColumn } from './types';
import { useEditableCellComponent } from './useEditableCellComponent';

type Props<T extends BaseRow> = {
  column: EditableColumn<T>;
  selected: boolean;
  editable: boolean;
  content: any;
  record: T;
  recordIndex: number;
};

export function EditableCell<T extends BaseRow>({
  column: { key, align, title, render, renderFromForm, rules, required, pattern, autoFocus },
  editable,
  selected,
  content,
  record,
  recordIndex,
}: Props<T>) {
  const {
    models: { itemRules },
    operations: { normalize },
  } = useEditableCellComponent({ rules, required, pattern });

  const getStandardRender = () => (render ? render(content, record, recordIndex) : content);

  if (selected && editable) {
    return (
      <StyledFormItem name={key} rules={itemRules} normalize={normalize}>
        <Input
          style={{ textAlign: align }}
          autoFocus={autoFocus}
          aria-label={typeof title === 'string' ? title : undefined}
        />
      </StyledFormItem>
    );
  }

  return selected && renderFromForm ? (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => renderFromForm(getFieldsValue())}
    </Form.Item>
  ) : (
    getStandardRender()
  );
}

import { PropsWithChildren } from 'react';
import { BrowserRouter, useNavigate } from 'react-router';
import { RouterProvider as AriaRouterProvider } from 'react-aria-components';

/**
 * InnerRouterProvider returns a react-aria-components RouterProvider that receives the `navigate`
 * function from react-router.
 *
 * Connecting react-aria with react-router like this allows those aria components that have a
 * href prop to trigger an SPA transition rather than a full page load when the href
 * is set to a relative path.
 *
 * The full explanation is found here:
 * https://react-spectrum.adobe.com/react-aria/routing.html#introduction
 *
 * Warning: the documentation is misleading. It seems like only links containing a valid protocol
 * will force browser navigation. Things like `www.` do not work, it must be 'http://www.'
 *
 * Examples:
 * <Link href="google.com"> ❌ https://app.lynksupplychain.com/google.com
 * <Link href="www.google.com"> ❌ https://app.lynksupplychain.com/www.google.com
 * <Link href="www.google.com" target="_blank"> ❌ https://app.lynksupplychain.com/www.google.com (new tab)
 * <Link href="http://google.com"> -  ✅ https://google.com
 * <Link href="https://google.com"> - ✅ https://google.com
 * <Link href="mailto:user@example.com"> - ✅ opens mail client
 * <Link href="locations"> - ✅ https://app.lynksupplychain.com/locations
 * <Link href="/locations"> - ✅ https://app.lynksupplychain.com/locations
 * <Link href="/locations" target="_blank"> - ✅ https://app.lynksupplychain.com/locations (new tab)
 */
function InnerRouterProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  return <AriaRouterProvider navigate={navigate}>{children}</AriaRouterProvider>;
}

export function RouterProvider({ children }: PropsWithChildren) {
  return (
    <BrowserRouter>
      <InnerRouterProvider>{children}</InnerRouterProvider>
    </BrowserRouter>
  );
}

import { useState } from 'react';
import { Button } from '@/components-shad/ui/button';
import { PlusIcon, XIcon } from 'lucide-react';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { cn } from '@/shared/helpers';
import { NewItemForm } from './NewItemForm';

type ItemProps = {
  value?: string;
  isLoading?: boolean;
  onDelete?: () => void;
};

function ListItem({ value, isLoading, onDelete }: ItemProps) {
  return (
    <li
      className={cn(
        '-ml-3 flex min-h-8 items-center justify-between gap-4 rounded-md border border-transparent pl-3 transition-colors',
        'has-[button[data-hovered]]:-ml-3 has-[button[data-hovered]]:border-border has-[button[data-hovered]]:pl-3',
      )}
    >
      <span className="min-w-0 flex-1 text-sm">
        {isLoading ? <Skeleton className="h-4 w-full" /> : value}
      </span>
      <Button
        variant="ghost"
        size="sm"
        icon={<XIcon />}
        aria-label={`Delete "${value}"`}
        isDisabled={isLoading}
        onPress={onDelete}
      />
    </li>
  );
}

type Props = {
  /** Whether the list is in a loading state. */
  isLoading?: boolean;

  /** The list of string items currently in the list. */
  items?: string[];

  /** Callback with the current list of items when an item is added or removed. */
  onChange: (items: string[]) => void;

  /** The descriptor for the kind of items in the list. */
  descriptor?: string;

  /** Placeholder text for the new item input. */
  placeholder?: string;
};

// TODO: LYNK-3462 improve keyboard support (escape to stop adding)
export function EditableList({
  isLoading,
  items,
  onChange,
  descriptor = '',
  placeholder = '',
}: Props) {
  const [isAdding, setIsAdding] = useState(false);

  const addItem = (text: string) => {
    onChange([...(items ?? []), text]);
    setIsAdding(false);
  };

  const removeItem = (index: number) => {
    onChange(items?.filter((_, i) => i !== index) ?? []);
  };

  return (
    <div className="space-y-4">
      {(isLoading || (items && items.length > 0)) && (
        <ul className="space-y-2">
          {isLoading ? (
            <ListItem isLoading />
          ) : (
            items?.map((text, index) => (
              <ListItem
                key={text}
                value={text}
                isLoading={isLoading}
                onDelete={() => removeItem(index)}
              />
            ))
          )}
        </ul>
      )}
      {isAdding ? (
        <NewItemForm
          onSave={addItem}
          onCancel={() => setIsAdding(false)}
          descriptor={descriptor}
          placeholder={placeholder}
        />
      ) : (
        <Button
          variant="outline"
          size="sm"
          icon={<PlusIcon />}
          onPress={() => setIsAdding(true)}
          isDisabled={isLoading}
        >
          Add {descriptor}
        </Button>
      )}
    </div>
  );
}

import { LocationInventoryDTO } from '@invenco/common-interface/inventory';
import {
  FulfillmentStrategy,
  SkuAction,
  SkuType,
  SkuUnitOfMeasure,
  UnitDTO,
  UnitType,
} from '@invenco/common-interface/products';
import { ListingDTO, ListingStatus, ListingStrategy } from '@invenco/common-interface/sales';
import { MeasurementDTO } from '@invenco/common-interface/shipping';

import { Dropdown } from 'antd';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { DetailList } from 'components/detail-list/DetailList';
import { DetailsBlock } from 'components/details-block';
import { DetailsCard } from 'components/details-card';
import { Header } from 'components/header';
import { Label, SkuLabel, StatusLabel } from 'components/label';
import { formatMeasurement, formatMoney, formatNumber } from 'shared/helpers';
import { listingStatusType, locationInventoryStatusType } from 'shared/status-maps';
import {
  listingStatusTitle,
  listingStrategyTitle,
  locationInventoryStatusTitle,
  skuTypeTitle,
} from 'shared/title-maps';
import {
  Container,
  DetailContainer,
  HorizontallySpaced,
  MainContainer,
  SideContainer,
} from 'styles/layout';
import { Column, DataTable } from '../../../../components/data-table';
import { ModalConfirm } from '../../../../components/modal-confirm';
import { BundleComponentModal } from './bundle-component-modal/BundleComponentModal';
import { ComponentsCard } from './components-card/ComponentsCard';
import { DetailsModal } from './details-modal/DetailsModal';
import { MeasurementsModal } from './measurements-modal/MeasurementsModal';
import { MysteryComponentModal } from './mystery-component-modal/MysteryComponentModal';
import { PriceModal } from './price-modal/PriceModal';
import { useMysteryComponents } from './useMysteryComponents';
import { useSkuDetailsPage } from './useSkuDetailsPage';

type UnitRow = Omit<UnitDTO, 'id'> & { id: string };

const unitColumns: Column<UnitRow>[] = [
  {
    title: 'Type',
    key: 'unitType',
    render: (type: UnitType) => <Label>{type}</Label>,
  },
  {
    title: 'Measure',
    key: 'unitOfMeasure',
    render: (measure: SkuUnitOfMeasure) => <Label>{measure}</Label>,
  },
  {
    title: 'Qty',
    key: 'baseQuantity',
    render: (qty: number) => formatNumber(qty),
    align: 'right',
  },
  { title: 'Barcode', key: 'barcode', align: 'right' },
  {
    title: 'Verified Dimensions (LxWxH)',
    key: 'dimensions',
    dataIndex: 'verifiedMeasurement',
    render: (verifiedMeasurement: MeasurementDTO | null) =>
      verifiedMeasurement
        ? `${verifiedMeasurement.length}x${verifiedMeasurement.width}x${
            verifiedMeasurement.height
          }${verifiedMeasurement.dimensionUnit.toLocaleLowerCase()}`
        : '',
    align: 'right',
  },
  {
    title: 'Verified Weight',
    key: 'weight',
    dataIndex: 'verifiedMeasurement',
    render: (verifiedMeasurement: MeasurementDTO | null) =>
      verifiedMeasurement
        ? `${verifiedMeasurement.weight}${verifiedMeasurement.weightUnit.toLocaleLowerCase()}`
        : '',
    align: 'right',
  },
];

const inventoryColumns: Column<LocationInventoryDTO>[] = [
  { title: 'Location', key: 'locationName' },
  {
    title: 'Status',
    key: 'status',
    render: (status) => (
      <StatusLabel
        status={status}
        type={locationInventoryStatusType}
        title={locationInventoryStatusTitle}
      />
    ),
  },
  { title: 'Expected', key: 'qtyExpected', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'On Hand', key: 'qtyOnHand', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'Reserved', key: 'qtyReserved', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'Available', key: 'qtyAvailable', align: 'right', render: (qty) => formatNumber(qty) },
];

const listingColumns: Column<ListingDTO>[] = [
  { title: 'Channel', key: 'channelName' },
  {
    title: 'Status',
    key: 'status',
    render: (status: ListingStatus) => (
      <StatusLabel status={status} type={listingStatusType} title={listingStatusTitle} />
    ),
  },
  {
    title: 'Strategy',
    key: 'strategy',
    render: (strategy: ListingStrategy) => listingStrategyTitle[strategy],
  },
  {
    title: 'Expected',
    key: 'qtyExpected',
    render: (qtyExpected: number) => formatNumber(qtyExpected),
    align: 'right',
  },
  {
    title: 'Available',
    key: 'qtyAvailable',
    render: (qtyAvailable: number) => formatNumber(qtyAvailable),
    align: 'right',
  },
  {
    title: 'Listed',
    key: 'qtyListed',
    render: (qtyListed: number) => formatNumber(qtyListed),
    align: 'right',
  },
];

type AssemblyTableRowData = {
  id: string;
  skuName: string;
  skuDescription: string;
  skuType: string;
  fulfillmentStrategy: FulfillmentStrategy;
};

const assemblyColumns: Column<AssemblyTableRowData>[] = [
  {
    key: 'skuDescription',
    title: 'SKU / Description',
    render: (_, data) => (
      <HorizontallySpaced $factor={0.5}>
        <SkuLabel name={data.skuName} fulfillmentStrategy={data.fulfillmentStrategy} />
        <span>{data.skuDescription}</span>
      </HorizontallySpaced>
    ),
  },
  { key: 'skuType', title: 'Type' },
];

function MeasurementDetails({
  measurement,
  loading,
}: {
  measurement: MeasurementDTO | null | undefined;
  loading?: boolean;
}) {
  if (!measurement) return null;
  return (
    <DetailsBlock loading={loading} loadingRows={4}>
      <DetailList
        items={[
          {
            label: 'Length',
            value: `${formatMeasurement(measurement.length, measurement.dimensionUnit)}`,
          },
          {
            label: 'Width',
            value: `${formatMeasurement(measurement.width, measurement.dimensionUnit)}`,
          },
          {
            label: 'Height',
            value: `${formatMeasurement(measurement.height, measurement.dimensionUnit)}`,
          },
          {
            label: 'Weight',
            value: `${formatMeasurement(measurement.weight, measurement.weightUnit)}`,
          },
        ]}
      />
    </DetailsBlock>
  );
}

type ConvertAction =
  | SkuAction.CONVERT_TO_BUNDLE
  | SkuAction.CONVERT_TO_MYSTERY
  | SkuAction.CONVERT_TO_STANDARD;

/**
 * The backend sends generic allowableActions (e.g. CONVERT_TO_STANDARD) that we need to convert to
 * a valid SKU Type (e.g. STANDARD) that we can give to the conversion API
 */
const conversionActionToSkuType: Record<ConvertAction, SkuType> = {
  [SkuAction.CONVERT_TO_BUNDLE]: SkuType.BUNDLE,
  [SkuAction.CONVERT_TO_MYSTERY]: SkuType.MYSTERY,
  [SkuAction.CONVERT_TO_STANDARD]: SkuType.STANDARD,
};

export function SkuDetails() {
  const {
    models: {
      isLoading,
      isLoadingInventories,
      isLoadingListings,
      isConverting,
      isDetailsModalOpen,
      isPriceModalOpen,
      isMeasurementsModalOpen,
      isBundleComponentModalOpen,
      confirmingConversionType,
      showComponents,
      showInventories,
      showUnits,
      sku,
      inventories,
      listings,
    },
    operations: {
      openDetailsModal,
      closeDetailsModal,
      openPriceModal,
      closePriceModal,
      openMeasurementsModal,
      closeMeasurementsModal,
      startConversion,
      confirmConversion,
      cancelConversion,
      refresh,
      updateSku,
      openBundleComponentModal,
      closeBundleComponentModal,
      updateAssemblyComponents,
    },
  } = useSkuDetailsPage();

  const {
    models: { isNewMysteryComponentModalOpen, editingMysteryComponent, mysteryComponents },
    operations: {
      addMysteryComponent,
      editMysteryComponent,
      closeMysteryComponentModal,
      deleteMysteryComponent,
      saveMysteryComponent,
    },
  } = useMysteryComponents({ sku });

  const assemblyTableRowData: AssemblyTableRowData[] =
    sku?.assemblies?.map(({ assemblySku }) => ({
      id: assemblySku.id,
      skuName: assemblySku.name,
      skuDescription: assemblySku.description,
      skuType: skuTypeTitle[assemblySku.type],
      fulfillmentStrategy: assemblySku.fulfillmentStrategy,
    })) ?? [];

  const convertDropdownMenuItems: {
    key: SkuType;
    label: string;
  }[] = (sku?.allowableActions ?? [])
    .filter(
      (action): action is ConvertAction =>
        action === SkuAction.CONVERT_TO_BUNDLE ||
        action === SkuAction.CONVERT_TO_MYSTERY ||
        action === SkuAction.CONVERT_TO_STANDARD,
    )
    .map((action) => ({
      key: conversionActionToSkuType[action],
      label: skuTypeTitle[conversionActionToSkuType[action]],
    }));

  return (
    <Container>
      <Header
        title={sku?.name}
        description={sku?.description}
        loading={isLoading}
        loadDescription
        actionContent={
          convertDropdownMenuItems.length > 0 && (
            <Dropdown
              trigger={['click']}
              menu={{
                items: convertDropdownMenuItems,
                onClick: ({ key }) => void startConversion(key as SkuType),
              }}
            >
              <Button type="primary" loading={isConverting}>
                Convert to...
              </Button>
            </Dropdown>
          )
        }
        extraContent={<Label aria-label={`Status: ${sku.type}`}>{sku.type}</Label>}
        links={[
          { url: '/products', title: 'Products' },
          { url: '/products/skus', title: 'SKUs' },
          { url: `/products/skus/${sku?.id}`, title: sku?.name, loading: isLoading },
        ]}
        backURL="/products/skus"
        onRefresh={refresh}
      />
      <DetailContainer>
        <MainContainer>
          {showComponents && (
            <ComponentsCard
              sku={sku}
              mysteryComponents={mysteryComponents}
              editBundleComponents={openBundleComponentModal}
              addMysteryComponent={addMysteryComponent}
              editMysteryComponent={editMysteryComponent}
              deleteMysteryComponent={deleteMysteryComponent}
            />
          )}

          {sku.type === SkuType.STANDARD && (
            <Card
              initiallyCollapsed
              title="Used as component"
              count={isLoading ? undefined : assemblyTableRowData.length}
              collapsible
            >
              <DataTable
                simple
                columns={assemblyColumns}
                rows={assemblyTableRowData}
                loading={isLoading}
              />
            </Card>
          )}

          {showUnits && (
            <Card title="Units" count={sku.units?.length ?? 0}>
              <DataTable
                simple
                columns={unitColumns}
                rows={(sku.units as UnitRow[]) ?? []}
                loading={isLoading}
              />
            </Card>
          )}

          {showInventories && (
            <Card title="Inventory" count={inventories.length}>
              <DataTable
                simple
                columns={inventoryColumns}
                rows={inventories}
                loading={isLoadingInventories}
              />
            </Card>
          )}

          <Card title="Listings" count={listings.length}>
            <DataTable
              simple
              columns={listingColumns}
              rows={listings}
              loading={isLoadingListings}
            />
          </Card>
        </MainContainer>

        <SideContainer>
          <DetailsCard title="Details" hasEditButton editLabel="Manage" onEdit={openDetailsModal}>
            <DetailsBlock loadingRows={6} loading={isLoading} contentTitle={sku.name}>
              <DetailList
                items={[
                  { label: 'Barcode', value: sku.barcode },
                  { label: 'Type', value: <Label>{sku.type}</Label> },
                  { label: 'Unit Type', value: <Label>{sku.unitType}</Label> },
                  { label: 'Measure', value: <Label>{sku.unitOfMeasure}</Label> },
                  {
                    label: 'Fulfillment Strategy',
                    value: <Label>{sku.fulfillmentStrategy}</Label>,
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>

          <DetailsCard title="Prices" hasEditButton onEdit={openPriceModal}>
            <DetailsBlock loadingRows={2} loading={isLoading}>
              <DetailList
                items={[
                  {
                    label: 'Purchase',
                    value: sku.purchasePrice !== undefined && formatMoney(sku.purchasePrice),
                  },
                  {
                    label: 'Sell',
                    value: sku.sellPrice !== undefined && formatMoney(sku.sellPrice),
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>

          <DetailsCard title="Measurements" hasEditButton onEdit={openMeasurementsModal}>
            <MeasurementDetails measurement={sku.measurement} loading={isLoading} />
          </DetailsCard>

          {sku.verifiedMeasurement && (
            <DetailsCard title="Verified Measurements">
              <MeasurementDetails measurement={sku.verifiedMeasurement} />
            </DetailsCard>
          )}
        </SideContainer>
      </DetailContainer>

      <DetailsModal
        isOpen={isDetailsModalOpen}
        sku={sku}
        updateSku={updateSku}
        onClose={closeDetailsModal}
      />
      <PriceModal
        isOpen={isPriceModalOpen}
        sku={sku}
        updateSku={updateSku}
        onClose={closePriceModal}
      />
      <MeasurementsModal
        isOpen={isMeasurementsModalOpen}
        sku={sku}
        updateSku={updateSku}
        onClose={closeMeasurementsModal}
      />

      {sku?.type === SkuType.BUNDLE && (
        <BundleComponentModal
          skuType={sku.type ?? SkuType.STANDARD}
          isOpen={isBundleComponentModalOpen}
          onClose={closeBundleComponentModal}
          existingComponents={sku.components}
          updateAssemblyComponents={updateAssemblyComponents}
        />
      )}
      {sku?.type === SkuType.MYSTERY && (
        <MysteryComponentModal
          open={!!editingMysteryComponent || isNewMysteryComponentModalOpen}
          existingMysteryComponent={editingMysteryComponent}
          allAssemblyComponents={sku.components ?? []}
          onSave={saveMysteryComponent}
          onClose={closeMysteryComponentModal}
        />
      )}

      <ModalConfirm
        isSubmitting={isConverting}
        onCancel={cancelConversion}
        onOk={confirmConversion}
        open={!!confirmingConversionType}
        title="Are you sure you want to convert this SKU?"
        description={
          <span>
            Converting the SKU&apos;s type from{' '}
            <strong>{skuTypeTitle[sku?.type ?? '']?.toLocaleLowerCase()}</strong> to{' '}
            <strong>{skuTypeTitle[confirmingConversionType ?? '']?.toLocaleLowerCase()}</strong>{' '}
            will erase all components.
          </span>
        }
      />
    </Container>
  );
}

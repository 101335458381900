import { Input, Select } from 'antd';
import { CarrierDTO, ShippingMethodDTO } from '@invenco/common-interface/shipping';
import { IncoTerms } from '@invenco/common-interface/sales';
import { WmsInstanceDTO, WarehouseCarrierServiceDTO } from '@invenco/common-interface/warehousing';
import { ShippingMethodType } from '@invenco/common-domain/enums';
import { Result } from '../../../../shared/helpers/Result';
import { FormModal } from '../../../../components/form-modal';
import { Form } from '../../../../components/form';
import { useAddWarehouseCarrierServiceModalComponent } from './useAddWarehouseCarrierServiceModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  wmsInstances?: WmsInstanceDTO[];
  isLoadingWmsInstances: boolean;
  carriers?: CarrierDTO[];
  isLoadingCarriers: boolean;
  createWarehouseCarrierService: (
    warehouseCarrierService: Partial<WarehouseCarrierServiceDTO>,
  ) => Promise<Result>;
};

export function AddWarehouseCarrierServiceModal({
  isOpen,
  onClose,
  wmsInstances,
  isLoadingWmsInstances,
  carriers,
  isLoadingCarriers,
  createWarehouseCarrierService,
}: Props) {
  const {
    models: {
      form,
      carrierAccounts,
      isLoadingCarrierAccounts,
      shippingMethods,
      isLoadingShippingMethods,
      isSaving,
      selectedCarrierAccountId,
    },
    operations: { selectCarrierId, selectCarrierServiceId, selectCarrierAccountId, submit },
  } = useAddWarehouseCarrierServiceModalComponent({
    carriers,
    isOpen,
    onClose,
    createWarehouseCarrierService,
  });

  const renderShippingMethodName = (shippingMethod: ShippingMethodDTO): string =>
    `${shippingMethod.name || ''}_${shippingMethod.type === ShippingMethodType.WAREHOUSE ? shippingMethod.locationName : shippingMethod.warehouseLocationId}_${shippingMethod.accountName || ''}_${shippingMethod.bookingCarrierAccountName || ''}`;

  return (
    <FormModal
      open={isOpen}
      onCancel={onClose}
      onSave={() => form.submit()}
      isSaving={isSaving}
      title="Create warehouse carrier service"
    >
      <Form
        form={form}
        layout="vertical"
        name="warehouseCarrierService"
        onFinish={(data) => void submit(data)}
        initialValues={{
          signatureRequired: true,
          incoTerms: IncoTerms.DDU,
        }}
      >
        <Form.Item
          name="wmsInstanceId"
          label="Wms Instance"
          rules={[{ required: true, message: 'Please select a wms instance' }]}
        >
          <Select
            loading={isLoadingWmsInstances}
            options={wmsInstances?.map((instance) => ({
              value: instance.id,
              label: instance.code,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="carrierId"
          label="Carrier"
          rules={[{ required: true, message: 'Please select a carrier' }]}
        >
          <Select
            loading={isLoadingCarriers}
            options={carriers?.map((carrier) => ({
              value: carrier.id,
              label: carrier.name,
            }))}
            onChange={selectCarrierId}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['carrierId']}>
          {({ getFieldValue }) => {
            const carrierId = getFieldValue('carrierId');
            return (
              <Form.Item
                name="carrierServiceId"
                label="Carrier Service"
                rules={[{ required: true, message: 'Please select a carrier service' }]}
              >
                <Select
                  options={carriers
                    ?.find((carrier) => carrier.id === carrierId)
                    ?.carrierServices?.map((carrierService) => ({
                      value: carrierService.id,
                      label: carrierService.name,
                    }))}
                  onChange={selectCarrierServiceId}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="carrierAccountId" label="Carrier Account">
          <Select loading={isLoadingCarrierAccounts} onChange={selectCarrierAccountId}>
            <Select.Option value="default">Default(ie. null)</Select.Option>
            {carrierAccounts?.map((carrierAccount) => (
              <Select.Option key={carrierAccount.id} value={carrierAccount.id}>
                {carrierAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="shippingMethodId" label="Shipping Method">
          <Select
            loading={isLoadingShippingMethods}
            disabled={selectedCarrierAccountId === 'default'}
          >
            <Select.Option value="">Default(ie. null)</Select.Option>
            {shippingMethods?.map((shippingMethod) => (
              <Select.Option key={shippingMethod.id} value={shippingMethod.id}>
                <>{renderShippingMethodName(shippingMethod)}</>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="signatureRequired" label="Signature Required">
          <Select>
            <Select.Option value>Signature required</Select.Option>
            <Select.Option value={false}>Authority to leave</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="incoTerms" label="incoTerms">
          <Select>
            <Select.Option value={IncoTerms.DDU}>DDU</Select.Option>
            <Select.Option value={IncoTerms.DDP}>DDP</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="primaryCode"
          label="Primary Export Code"
          rules={[
            { required: true, message: 'Please enter primary export code' },
            {
              validator: (_, value) => {
                if (
                  value &&
                  form.getFieldValue('secondaryCodes') &&
                  form.getFieldValue('secondaryCodes').includes(value)
                ) {
                  return Promise.reject(
                    new Error('Primary and secondary export codes must be unique'),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="secondaryCodes" label="Secondary Export Codes">
          <Select mode="tags" allowClear />
        </Form.Item>
      </Form>
    </FormModal>
  );
}

import { Popconfirm } from 'antd';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { formatNumber } from 'shared/helpers';
import { Header } from 'components/header';
import { Card } from 'components/card';
import { DetailsCard } from 'components/details-card';
import { DetailsBlock } from 'components/details-block';
import { LoadingSkeleton } from 'components/loading-skeleton';
import { StatusLabel } from 'components/label/StatusLabel';
import { billStatusTitle, invoiceStatusTitle } from 'shared/title-maps';
import { billStatusType, invoiceStatusType } from 'shared/status-maps';
import { Button } from 'components/button';
import { DetailList } from 'components/detail-list/DetailList';
import { BillLines } from './bill-lines/BillLines';
import { useBillingDetailsPage } from './useBillingDetailsPage';
import { AccountName, PeriodHeader, PeriodRow, PeriodCol } from './styles';

export function BillingDetails() {
  const {
    models: { billingDetails, breadcrumbs, isLoading, canCloseBill, isClosingBill },
    operations: { closeBill },
  } = useBillingDetailsPage();

  const billingDescription = billingDetails
    ? `${billingDetails.accountName} - ${billingDetails.periodStart} to ${billingDetails.periodEnd}`
    : '';

  return (
    <Container>
      <Header
        links={breadcrumbs}
        title={billingDetails?.name}
        description={billingDescription}
        loading={isLoading}
        loadDescription
        backURL="/bills"
        actionContent={
          canCloseBill && (
            <Popconfirm
              placement="bottomRight"
              title="Are you sure you want to continue?"
              onConfirm={() => void closeBill()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={isClosingBill}>
                Close Bill
              </Button>
            </Popconfirm>
          )
        }
        extraContent={
          billingDetails?.status && (
            <StatusLabel
              status={billingDetails?.status}
              type={billStatusType}
              title={billStatusTitle}
            />
          )
        }
      />

      <DetailContainer>
        <MainContainer>
          <Card title="Charges" count={billingDetails?.billLines?.length ?? 0}>
            {isLoading && <LoadingSkeleton width="300px" />}
            {!isLoading && billingDetails?.billLines ? (
              <BillLines billLines={billingDetails.billLines} />
            ) : null}
          </Card>
        </MainContainer>
        <SideContainer>
          <DetailsCard title="Details">
            <DetailsBlock loading={isLoading}>
              <AccountName>{billingDetails?.accountName}</AccountName>
            </DetailsBlock>
            <DetailsBlock>
              <PeriodHeader>Period</PeriodHeader>
              <PeriodRow>
                <PeriodCol>
                  <div>From</div>
                  <div>{billingDetails?.periodStart}</div>
                </PeriodCol>
                <PeriodCol>
                  <div>To</div>
                  <div>{billingDetails?.periodEnd}</div>
                </PeriodCol>
              </PeriodRow>
            </DetailsBlock>
            <DetailsBlock>
              <PeriodHeader>Location</PeriodHeader>
              <div>{billingDetails?.locationName}</div>
            </DetailsBlock>
          </DetailsCard>

          {billingDetails?.invoice && (
            <DetailsCard title="Invoice">
              <DetailsBlock>
                <DetailList
                  items={[
                    {
                      label: 'Name',
                      value: billingDetails.invoice?.externalUrl ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={billingDetails.invoice?.externalUrl}
                        >
                          {billingDetails.invoiceName}
                        </a>
                      ) : (
                        billingDetails.invoiceName
                      ),
                    },
                    {
                      label: 'Total',
                      value: `$${formatNumber(billingDetails.invoice?.totalAmount || 0, 2)}`,
                    },
                    {
                      label: 'Status',
                      value: (
                        <StatusLabel
                          status={billingDetails.invoice?.status}
                          type={invoiceStatusType}
                          title={invoiceStatusTitle}
                        />
                      ),
                    },
                  ]}
                />
              </DetailsBlock>
            </DetailsCard>
          )}
        </SideContainer>
      </DetailContainer>
    </Container>
  );
}

import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { Selection } from 'react-aria-components';
import { FilterOption } from '../types';
import type { Props as FilterProps } from '../data-filter';
import {
  InputQueryFunction,
  useQueryWithInput,
} from '../../../shared/hooks/queries/useQueryWithInput';
import {
  MAX_AUTO_COMPLETE_RESULTS,
  SEARCH_DEBOUNCE_TIMEOUT_MS,
  TEN_SECONDS,
} from '../../../constants';
import { toFilterOptionArray } from '../utils/mappers';
import {
  getNextSelectedItemsForMultiSelect,
  getNextSelectedItemsForSingleSelect,
} from '../utils/selection-handlers';
import { getNextListItems } from '../utils/get-next-list-items';
import { useSelectedItemsCache } from './useSelectedItemsCache';

type Props<QueryResponse> = {
  /**
   * title of the filter, displayed in the filter button
   */
  title: string;
  /**
   * root key for the query, used to identify the query in the query cache, for example 'accounts'
   */
  queryKeyRoot: string;
  /**
   * function to fetch items for the filter from the backend
   * filter only works with backends that support the `?search=''&take=''` params
   */
  queryFn: InputQueryFunction<QueryResponse, { search: string; take: number }>;
  /**
   * function to map the query response to the filter options
   */
  queryResponseMapper: (data: QueryResponse) => FilterOption[];
  /**
   * whether the filter is multi-select or single-select
   */
  isMultiSelect: boolean;
  /**
   * selected items for the filter, displayed in the filter button and will appear in the dropdown
   * with a checkmark icon
   */
  selectedItems: FilterOption[];
  /**
   * callback to handle the selection of items for the filter
   */
  onChange: (selectedItems: FilterOption[]) => void;
  /**
   * whether to lazy load the items for the filter, if true, the items will not be fetched until
   * the user opens the filter
   */
  lazy?: boolean;
};

/**
 * This hook is used in conjunction with the DataFilter component to provide a filter that
 * is powered by a backend query.
 *
 * The hook returns a set of props that can be passed to the DataFilter component.
 */
export const useDataFilter = <QueryResponse>({
  title,
  queryKeyRoot,
  queryFn,
  queryResponseMapper,
  isMultiSelect,
  selectedItems: selectedItemsProp,
  onChange,
  lazy = true,
}: Props<QueryResponse>): FilterProps => {
  const [selectedItemsCache, setSelectedItemsCache] = useSelectedItemsCache(selectedItemsProp);

  const [shouldFetchItems, setShouldFetchItems] = useState(!lazy);

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((nextSearchTerm) => setSearchTerm(nextSearchTerm), SEARCH_DEBOUNCE_TIMEOUT_MS, {
        // for instant searching when user enters a single char searchTerm
        leading: true,
      }),
    [],
  );

  useEffect(
    () => () => {
      // clean up debounces on unmount
      debouncedSetSearchTerm.cancel();
    },
    [debouncedSetSearchTerm],
  );

  const { data: fetchedItems = [], isLoading: isLoadingItems } = useQueryWithInput({
    parentKey: queryKeyRoot,
    input: { search: searchTerm, take: MAX_AUTO_COMPLETE_RESULTS },
    query: queryFn,
    staleTime: TEN_SECONDS,
    enabled: shouldFetchItems,
    select: queryResponseMapper,
  });

  const selectedItems = toFilterOptionArray(selectedItemsCache);

  const items = getNextListItems(fetchedItems, selectedItemsCache, searchTerm);

  /**
   * Handle when user selects or de-selects an option from
   * the visible list items
   */
  const onSelectionChange = (selectedKeys: Selection) => {
    const nextSelectedItemsCache = isMultiSelect
      ? getNextSelectedItemsForMultiSelect(fetchedItems, selectedItemsCache, selectedKeys)
      : getNextSelectedItemsForSingleSelect(fetchedItems, selectedKeys);

    setSelectedItemsCache(nextSelectedItemsCache);
    onChange(toFilterOptionArray(nextSelectedItemsCache));
  };

  const onOpen = () => {
    if (!shouldFetchItems) {
      setShouldFetchItems(true);
    }
  };

  const onClose = () => {
    if (searchTerm !== '') {
      setSearchTerm('');
    }
  };

  return {
    title,
    items,
    selectedItems,
    isLoadingItems,
    isMultiSelect,
    onSelectionChange,
    onInputChange: debouncedSetSearchTerm,
    onOpen,
    onClose,
  };
};

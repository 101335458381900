import { LocationDTO, LocationType } from '@invenco/common-interface/accounts';
import { AddressDisplay } from '@/components-shad/address-display/address-display';
import { formatDateTime } from 'shared/helpers';
import { shippingMethodStatusType } from 'shared/status-maps';
import { Header } from '@/components-shad/header/Header';
import { Badge } from '@/components-shad/ui/badge';
import { ShippingMethodDTO } from '@invenco/common-interface/shipping';
import { PropsWithChildren } from 'react';
import { useLocationDetailsPage } from '../useLocationDetailsPage';
import { DetailMain, DetailSide, Page, PageContent } from '../../../../../components-shad/layout';
import { Card } from '../../../../../components-shad/ui/card';
import { DataTable } from '../../../../../components-shad/data-table';
import { Button } from '../../../../../components-shad/ui/button';
import { StatusBadge } from '../../../../../components-shad/StatusBadge';
import { DetailList } from '../../../../../components-shad/detail-list/DetailList';
import { Link } from '../../../../../components-shad/ui/link';
import { DialogOverlay, DialogTrigger } from '../../../../../components-shad/ui/dialog';
import { locationTypeTitle } from '../../../../../shared/title-maps';
import { ManagedShippingMethodDialog } from './managed-shipping-method-dialog/ManagedShippingMethodDialog';
import { WarehouseShippingMethodDialog } from './warehouse-shipping-method-dialog/WarehouseShippingMethodDialog';

function AddShippingMethodTrigger({
  isDisabled,
  children,
}: PropsWithChildren<{ isDisabled: boolean }>) {
  return (
    <DialogTrigger>
      <Button variant="outline" size="sm" isDisabled={isDisabled}>
        Add shipping method
      </Button>
      <DialogOverlay>{children}</DialogOverlay>
    </DialogTrigger>
  );
}

type AddShippingMethodButtonProps = {
  existingShippingMethods: ShippingMethodDTO[];
  isLoading: boolean;
  isWarehouse: boolean;
  location: Partial<LocationDTO>; // TODO can make this component simpler if Partial isn't used
};

function AddShippingMethodButton({
  existingShippingMethods,
  isLoading,
  isWarehouse,
  location,
}: AddShippingMethodButtonProps) {
  if (location.accountId && location.id && isWarehouse) {
    return (
      <AddShippingMethodTrigger isDisabled={isLoading}>
        <WarehouseShippingMethodDialog
          existingShippingMethods={existingShippingMethods}
          accountId={location.accountId}
          locationId={location.id}
        />
      </AddShippingMethodTrigger>
    );
  }

  if (location.accountId && location.id && location.warehouseLocationId) {
    return (
      <AddShippingMethodTrigger isDisabled={isLoading}>
        <ManagedShippingMethodDialog
          existingShippingMethods={existingShippingMethods}
          accountId={location.accountId}
          locationId={location.id}
          warehouseLocationId={location.warehouseLocationId}
        />
      </AddShippingMethodTrigger>
    );
  }

  /*
   * when loading is complete, because location is a Partial, TS thinks we
   * still may not have location data, in this seeminlgy impossible scenario,
   * don't render the button at all because there isn't enough data to show any modals
   */
  return null;
}

export function LocationDetails() {
  const {
    models: {
      isLoading: isLoadingLocation,
      isLoadingShippingMethods,
      isLoadingWarehouse,
      isLoadingManagedLocations,
      // isShippingMethodModalOpen,
      location,
      shippingMethods,
      warehouse,
      managedLocations,
      isLocationPlaceholderData,
      isWarehouse,
    },
    operations: {
      // openShippingMethodModal,
      // closeShippingMethodModal,
      refresh,
      // addWarehouseShippingMethod,
      // addManagedShippingMethod,
      onClickManagedLocation,
    },
  } = useLocationDetailsPage();

  // Rather than having parts of the page appear at different times, show the whole
  // page in a loading state until all the data is loaded. This is less jarring on the user
  // TODO LYNK-3470 - possibly move this logic elsewhere
  const isLoading =
    isLoadingLocation ||
    isLoadingShippingMethods ||
    isLoadingWarehouse ||
    isLoadingManagedLocations;

  // TODO LYNK-3470 - possibly move this logic elsewhere
  // Take advantage of cached location data when possible to avoid unecessary loading skeletons
  const showLoadingSkeletonForLocationData = !isLocationPlaceholderData && isLoadingLocation;

  return (
    <Page>
      <Header
        title={location.name}
        breadcrumbs={[{ url: '/locations', title: 'Locations' }]}
        description={
          location.type && <Badge variant="secondary">{locationTypeTitle[location.type]}</Badge>
        }
        loading={showLoadingSkeletonForLocationData}
        loadDescription={showLoadingSkeletonForLocationData}
        onRefresh={refresh}
        hasBackButton
      />
      <PageContent variant="split">
        <DetailMain>
          {isWarehouse && (
            <Card
              title="Managed locations"
              count={managedLocations?.length || 0 /* todo empty badge rather than 0 */}
            >
              <DataTable
                simple
                columns={[
                  { title: 'Account', key: 'accountName' },
                  { title: 'Name', key: 'name' },
                  {
                    title: 'Created',
                    key: 'createdAt',
                    render: (createdAt) => formatDateTime(createdAt),
                  },
                ]}
                rows={managedLocations}
                loading={isLoading}
                onClickRow={onClickManagedLocation}
              />
            </Card>
          )}
          <Card
            title="Shipping methods"
            count={shippingMethods.length}
            actions={
              <AddShippingMethodButton
                isLoading={isLoading}
                location={location}
                existingShippingMethods={shippingMethods}
                isWarehouse={isWarehouse}
              />
            }
          >
            <DataTable
              simple
              columns={[
                { title: 'Name', key: 'name' },
                { title: 'Carrier', key: 'carrierName' },
                { title: 'Service', key: 'carrierServiceName' },
                {
                  title: 'Status',
                  key: 'status',
                  render: (status) => (
                    <StatusBadge status={status} type={shippingMethodStatusType} />
                  ),
                },
              ]}
              rows={shippingMethods}
              loading={isLoading}
            />
          </Card>
        </DetailMain>

        <DetailSide>
          <Card title="Details">
            <DetailList
              isLoading={showLoadingSkeletonForLocationData}
              items={[
                {
                  label: 'Type',
                  value: location.type ? locationTypeTitle[location.type] : '',
                },
                {
                  label: 'Account',
                  value:
                    location.accountId && location.accountName ? (
                      <Link variant="link" size="inline" href={`/accounts/${location.accountId}`}>
                        {location.accountName}
                      </Link>
                    ) : null,
                },
                ...(location.type === LocationType.MANAGED
                  ? [
                      {
                        label: 'Warehouse location',
                        value:
                          location.warehouseLocationId && location.warehouseLocationName ? (
                            <Link
                              variant="link"
                              size="inline"
                              href={`/locations/${location.warehouseLocationId}`}
                            >
                              {location.warehouseLocationName}
                            </Link>
                          ) : null,
                      },
                    ]
                  : []),
              ]}
            />
          </Card>

          <Card title="Address">
            <AddressDisplay
              loading={showLoadingSkeletonForLocationData}
              address={location.address}
            />
          </Card>

          {isWarehouse && (
            <Card title="Warehouse">
              {/* TODO LYNK-3516 - Error handling */}
              {!isLoading && !warehouse ? (
                <div className="text-sm text-muted-foreground">No warehouse matched</div>
              ) : (
                <DetailList
                  isLoading={isLoading}
                  items={[
                    { label: 'Name', value: warehouse?.name },
                    { label: 'Code', value: warehouse?.code },
                    { label: 'WMS', value: warehouse?.wmsInstanceCode },
                    { label: 'Timezone', value: warehouse?.timezone },
                  ]}
                />
              )}
            </Card>
          )}
        </DetailSide>
      </PageContent>
    </Page>
  );
}

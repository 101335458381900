import { EllipsisVerticalIcon } from 'lucide-react';
import { ShipmentStatus } from '@invenco/common-interface/shipping';
import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import {
  DataTable,
  DataTableSearchPanel,
  DataTableHeader,
  DataTableTabs,
} from '@/components-shad/data-table';
import { StatusBadge } from '@/components-shad/StatusBadge';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { DialogOverlay, DialogTrigger } from '@/components-shad/ui/dialog';
import { Menu, MenuItem, MenuPopover, MenuTrigger } from '@/components-shad/ui/menu';
import { Button } from '@/components-shad/ui/button';
import { OrderCurrency } from '@invenco/common-interface/sales';
import { formatDate, formatMoney } from '@/shared/helpers';
import { shipmentStatusTitle } from '@/shared/title-maps';
import { shipmentStatusType } from '@/shared/status-maps';
import { useShipmentListPage, sortOptions, views } from '../useShipmentListPage';
import { SetCostDialog } from './dialogs/SetCostDialog';
import type { FilterOption } from '../../../../../components-shad/data-filter/types';
import { useStaticDataFilter } from '../../../../../components-shad/data-filter/hooks/useStaticDataFilter';

const statusOptions: FilterOption[] = [
  ShipmentStatus.DRAFT,
  ShipmentStatus.WAITING,
  ShipmentStatus.PROCESSING,
  ShipmentStatus.SHIPPED,
  ShipmentStatus.CANCELLED,
].map((status) => ({
  value: status,
  displayName: shipmentStatusTitle[status],
}));

export function ShipmentListShad() {
  const {
    models: {
      isLoading,
      isLoadingAccounts,
      hasError,
      accountsById,
      selectedShipmentForCost,
      rows,
      pagination,
      total,
      view,
      query,
      sort,
      filterValues,
    },
    operations: {
      search,
      navigate,
      changeSort,
      updateFilters,
      refresh,
      changeView,
      recalculateCost,
      setCost,
      openSetCostModal,
      closeSetCostModal,
    },
  } = useShipmentListPage();

  const shipmentStatusFilter = useStaticDataFilter({
    title: 'Status',
    isMultiSelect: true,
    selectedItems: (() => {
      // TODO: improve the interface between filterSelections and selectedItems to avoid having to
      // check for array and non-array cases
      if (filterValues?.statuses) {
        if (Array.isArray(filterValues.statuses)) {
          const mappedSelectedItems = filterValues.statuses.map(({ title, value }) => ({
            displayName: title,
            value,
          }));

          return mappedSelectedItems;
        }

        return [
          {
            displayName: filterValues.statuses.title,
            value: filterValues.statuses.value,
          },
        ];
      }

      return [];
    })(),
    // TODO: improve the interface between filterSelections and selectedItems to avoid having to
    // check for array and non-array cases
    onChange: (selectedItems) => {
      updateFilters({
        statuses:
          selectedItems.length > 0
            ? selectedItems.map(({ value, displayName }) => ({
                value,
                title: displayName,
              }))
            : undefined,
      });
    },
    items: statusOptions,
  });

  return (
    <Page>
      <Header title="Shipments" />

      <PageContent variant="column">
        <DataTableHeader>
          <DataTableTabs views={views} current={view ?? ''} onChange={changeView} />
          <DataTableSearchPanel
            loading={isLoading}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            pagination={pagination}
            total={total}
            defaultSearchValue={query}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
            filters={[shipmentStatusFilter]}
          />
        </DataTableHeader>
        <DataTable
          rows={rows}
          columns={[
            { title: 'Name', key: 'name' },
            {
              title: 'Status',
              key: 'status',
              render: (status: ShipmentStatus) => (
                <StatusBadge
                  status={status}
                  title={shipmentStatusTitle}
                  type={shipmentStatusType}
                />
              ),
            },
            {
              title: 'Shipped',
              key: 'shippedAt',
              render: (shippedAt: string) => shippedAt && formatDate(shippedAt),
            },
            {
              title: 'Cost',
              key: 'cost',
              align: 'right',
              render: (cost, { currency }) =>
                cost !== undefined && formatMoney(cost, (currency ?? 'AUD') as OrderCurrency),
            },
            { title: 'Carrier service code', key: 'carrierServiceCode' },
            { title: 'Carrier service name', key: 'carrierServiceName' },
            {
              title: 'Account',
              key: 'accountId',
              render: (accountId: string) =>
                isLoadingAccounts ? (
                  <Skeleton className="h-4 w-full" />
                ) : (
                  accountsById?.get(accountId)?.name
                ),
            },
            {
              key: 'id',
              title: '',
              align: 'right',
              render: (id: string, shipment) => (
                <MenuTrigger>
                  <Button
                    variant="ghost"
                    icon={<EllipsisVerticalIcon />}
                    aria-label="Further actions"
                    size="sm"
                  />
                  <MenuPopover placement="bottom right">
                    <Menu>
                      {shipment.shippedAt && (
                        <MenuItem id="recalculate_cost" onAction={() => void recalculateCost(id)}>
                          Recalculate cost
                        </MenuItem>
                      )}
                      <MenuItem id="set_cost" onAction={() => void openSetCostModal(shipment)}>
                        Set cost
                      </MenuItem>
                    </Menu>
                  </MenuPopover>
                </MenuTrigger>
              ),
            },
          ]}
          hasError={hasError}
          loading={isLoading}
          sort={sort}
          sortOptions={sortOptions}
          onChangeSort={changeSort}
        />
      </PageContent>
      <DialogTrigger
        isOpen={!!selectedShipmentForCost}
        onOpenChange={(value) => {
          if (!value) {
            closeSetCostModal();
          }
        }}
      >
        <DialogOverlay>
          <SetCostDialog shipment={selectedShipmentForCost} onSubmit={setCost} />
        </DialogOverlay>
      </DialogTrigger>
    </Page>
  );
}

import {
  CarrierServiceCategory,
  CarrierServiceRateMatchMethod,
  ShipmentClassification,
} from '@invenco/common-interface/shipping';
import { IncoTerms } from '@invenco/common-interface/sales';

import { Input, Select } from 'antd';
import { AddressType } from '@invenco/common-interface/shared';
import { Button } from 'components/button';
import { useCarrierServiceDetailsPage } from './useCarrierServiceDetailsPage';
import {
  Container,
  DetailContainer,
  MainContainer,
  SideContainer,
} from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { formatDateTime, toTitleCase } from '../../../../shared/helpers';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_ZONE } from '../../../../constants';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { StyledCheckboxGroup } from './styles';
import { EditableList } from '../../../../components/editable-list';
import {
  carrierServiceCategoryTitle,
  carrierServiceRateMatchMethodTitle,
} from '../../../../shared/title-maps';

export function CarrierServiceDetails() {
  const {
    models: {
      carrierService,
      isLoading,
      isLoadingCarriers,
      isSaving,
      isNew,
      canSave,
      carriers,
      breadcrumbs,
    },
    operations: { refresh, save, updateCarrierService, addExternalCode, removeExternalCode },
  } = useCarrierServiceDetailsPage();

  return (
    <Container>
      <Header
        title={carrierService.name}
        documentTitle={isNew ? 'New Carrier Service' : carrierService.name}
        links={breadcrumbs}
        loading={isLoading}
        loadDescription
        description={
          carrierService.createdAt &&
          formatDateTime(carrierService.createdAt, DEFAULT_TIME_ZONE, DEFAULT_DATETIME_FORMAT)
        }
        backURL={
          carrierService.carrierId
            ? `/shipping/carriers/${carrierService.carrierId}`
            : '/shipping/carrier_services'
        }
        onRefresh={!isNew ? refresh : undefined}
        actionContent={
          <Button type="primary" disabled={!canSave} loading={isSaving} onClick={() => void save()}>
            Save
          </Button>
        }
        editable
        editInitially={isNew}
        editPlaceholder="Enter carrier service name..."
        editAriaLabel="Carrier service name"
        onSave={(name) => updateCarrierService({ name })}
      />

      <DetailContainer>
        <MainContainer>
          <Card title="External codes" count={carrierService.externalCodes?.length ?? 0}>
            <EditableList
              loading={isLoading}
              items={carrierService.externalCodes ?? []}
              onAdd={addExternalCode}
              onRemove={removeExternalCode}
              descriptor="external code"
            />
          </Card>
        </MainContainer>

        <SideContainer>
          <Card title="Details">
            <Form layout="vertical">
              <Form.Item label="Carrier">
                <Select
                  aria-label="Carrier"
                  value={carrierService.carrierId}
                  loading={isLoadingCarriers}
                  onChange={(carrierId) => updateCarrierService({ carrierId })}
                  disabled={isSaving || isLoading || isLoadingCarriers || !isNew}
                >
                  {carriers.map((carrier) => (
                    <Select.Option key={carrier.id} value={carrier.id}>
                      {carrier.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Code">
                <Input
                  aria-label="Code"
                  value={carrierService.code}
                  // this is not ideal as it sends an update every character, but good enough for now
                  onChange={(e) => updateCarrierService({ code: e.target.value })}
                  disabled={isSaving || isLoading}
                />
              </Form.Item>

              <Form.Item label="Category">
                <Select
                  aria-label="Category"
                  value={carrierService.category}
                  loading={isLoading}
                  onChange={(category) => updateCarrierService({ category })}
                  disabled={isSaving || isLoading}
                >
                  {Object.values(CarrierServiceCategory).map((category) => (
                    <Select.Option key={category} value={category}>
                      {carrierServiceCategoryTitle[category]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Shipment tracker gateway code">
                <Input
                  aria-label="shipmentTrackerGatewayCode"
                  value={carrierService.shipmentTrackerGatewayCode ?? undefined}
                  // this is not ideal as it sends an update every character, but good enough for now
                  onChange={(e) =>
                    updateCarrierService({ shipmentTrackerGatewayCode: e.target.value })
                  }
                  disabled={isSaving || isLoading}
                />
              </Form.Item>
            </Form>
          </Card>

          <Card title="Match settings">
            <Form layout="vertical">
              <Form.Item label="Rate Match Method">
                <Select
                  aria-label="Rate Match Method"
                  value={carrierService.rateMatchMethod}
                  loading={isLoading}
                  onChange={(rateMatchMethod) => updateCarrierService({ rateMatchMethod })}
                  disabled={isSaving || isLoading}
                >
                  {Object.values(CarrierServiceRateMatchMethod).map((rateMatchMethod) => (
                    <Select.Option key={rateMatchMethod} value={rateMatchMethod}>
                      {carrierServiceRateMatchMethodTitle[rateMatchMethod]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Allowable Address Types">
                <StyledCheckboxGroup
                  options={Object.values(AddressType).map((value) => ({
                    value,
                    label: toTitleCase(value),
                  }))}
                  value={carrierService.allowableAddressTypes}
                  onChange={(types) =>
                    updateCarrierService({
                      allowableAddressTypes: types as AddressType[],
                    })
                  }
                  disabled={isSaving || isLoading}
                />
              </Form.Item>

              <Form.Item label="Allowable Shipment Classification">
                <StyledCheckboxGroup
                  options={Object.values(ShipmentClassification).map((value) => ({
                    value,
                    label: toTitleCase(value),
                  }))}
                  value={carrierService.allowableShipmentClassifications}
                  onChange={(types) =>
                    updateCarrierService({
                      allowableShipmentClassifications: types as ShipmentClassification[],
                    })
                  }
                  disabled={isSaving || isLoading}
                />
              </Form.Item>

              <Form.Item label="Allowable Terms of Trade">
                <StyledCheckboxGroup
                  options={Object.values(IncoTerms)}
                  value={carrierService.allowableIncoTerms}
                  onChange={(types) =>
                    updateCarrierService({ allowableIncoTerms: types as IncoTerms[] })
                  }
                  disabled={isSaving || isLoading}
                />
              </Form.Item>
            </Form>
          </Card>
        </SideContainer>
      </DetailContainer>
    </Container>
  );
}

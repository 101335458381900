import { ChangeEvent, MouseEventHandler, useMemo, useState } from 'react';
import {
  FilterOutlined,
  LeftOutlined,
  ReloadOutlined,
  RightOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { formatNumber } from 'shared/helpers';
import { Button, Dropdown, MenuProps } from 'antd';
import { SearchInput } from 'components/search-input';
import { Page, Pagination } from 'shared/types';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_TIMEOUT_MS } from '../../../constants';
import {
  ControlsDivider,
  FilterBadge,
  FilterButton,
  PaginationControls,
  RightControls,
  SearchPanelContainer,
  SearchSegment,
  TotalCount,
  Wrapper,
} from './styles';
import { FilterPanel, FilterPanelProps } from '../filter-panel/FilterPanel';
import { SortSelection, SortOption } from '../types';
import { useLoadedValue } from '../../../shared/hooks/useLoadedValue';

export type SearchPanelProps<FilterKeyT extends string> = {
  /** Whether data is currently being loaded. */
  loading?: boolean;

  /** Placeholder text for the search input */
  searchPlaceholderText?: string;

  /** Default search input value */
  defaultSearchValue?: string;

  /** Total number of items for the current request */
  total?: number;

  /** Whether to hide the total count */
  hideTotal?: boolean;

  /** Pagination info with prev and/or next pages */
  pagination?: Pagination;

  /** Debounced callback when search input changes. */
  onSearch: (text: string) => void;

  /** Callback when navigating to a different page. */
  onNavigate: (page: Page) => void;

  /** Callback when requesting to reload the table. */
  onReload: MouseEventHandler<HTMLButtonElement>;

  /**
   * Optional filter definitions. Each filter must have a key and title. Options can be static, or change
   * according to onSearch and onRequestMore (if infinitely scrolling) callbacks.
   *
   * If provided, the FilterPanel will be included and a button to toggle its display will be shown.
   */
  filters?: FilterPanelProps<FilterKeyT>['filters'];

  /** Current filter selections. */
  filterSelections?: FilterPanelProps<FilterKeyT>['selections'];

  /** Called when a filter value is chosen, updated, or removed. This should be used to update `filterSelections`. */
  onChangeFilterSelections?: FilterPanelProps<FilterKeyT>['onChange'];

  /**
   * Sorting options available. If specified, a sort button will be shown and the options will be displayed as a
   * dropdown menu under the sort.
   */
  sortOptions?: SortOption[];

  /** Current sort selection. */
  sort?: SortSelection;

  /** Called when a sort option is chosen. */
  onChangeSort?: (sortSelection: SortSelection) => void;
};

export function SearchPanel<FilterKeyT extends string = string>({
  loading,
  defaultSearchValue,
  searchPlaceholderText = 'Search...',
  total: currentTotal,
  hideTotal,
  pagination,
  onReload,
  onSearch,
  onNavigate,

  filters,
  filterSelections,
  onChangeFilterSelections,

  sortOptions,
  sort,
  onChangeSort,
}: SearchPanelProps<FilterKeyT>) {
  const hasFilters = !!filters?.length && onChangeFilterSelections !== undefined;
  const activeFilterCount = Object.keys(filterSelections || {}).length;

  const [activeFilter, setActiveFilter] = useState<FilterKeyT | 'new'>();
  const [isShowingFilterPanel, setIsShowingFilterPanel] = useState(activeFilterCount > 0);
  const total = useLoadedValue(currentTotal, loading);

  const handleSearch = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        onSearch(event.target.value);
      }, SEARCH_DEBOUNCE_TIMEOUT_MS),
    [onSearch],
  );

  const handleNavigate = (page: Page | null | undefined) => {
    if (page) {
      onNavigate(page);
    }
  };

  const toggleFilterPanel = () => {
    const newVal = !isShowingFilterPanel;
    setIsShowingFilterPanel(newVal);
    if (!newVal) {
      setActiveFilter(undefined);
    } else if (!activeFilterCount) {
      setActiveFilter('new');
    }
  };

  const onSelectSort: MenuProps['onClick'] = ({ key }) => {
    const newDirection = sort?.key === key && sort.direction === 'asc' ? 'desc' : 'asc';
    onChangeSort?.({ key, direction: newDirection });
  };

  return (
    <Wrapper>
      <SearchPanelContainer>
        <SearchSegment>
          <SearchInput
            onChange={handleSearch}
            prefixIcon
            placeholder={searchPlaceholderText || 'Search...'}
            defaultValue={defaultSearchValue}
          />

          {hasFilters && (
            <FilterBadge count={!isShowingFilterPanel ? activeFilterCount : undefined} size="small">
              <FilterButton
                $open={isShowingFilterPanel}
                icon={<FilterOutlined />}
                onClick={toggleFilterPanel}
                aria-label={isShowingFilterPanel ? 'Hide filters' : 'Show filters'}
              />
            </FilterBadge>
          )}

          {sortOptions && (
            <Dropdown
              menu={{
                items: sortOptions.map((opt) => ({
                  key: opt.key,
                  label: opt.title,
                  icon:
                    opt.key === sort?.key &&
                    (sort.direction === 'desc' ? (
                      <SortDescendingOutlined aria-hidden="true" />
                    ) : (
                      <SortAscendingOutlined aria-hidden="true" />
                    )),
                })),
                onClick: onSelectSort,
              }}
              trigger={['click']}
            >
              <Button
                icon={
                  sort?.direction === 'desc' ? (
                    <SortDescendingOutlined />
                  ) : (
                    <SortAscendingOutlined />
                  )
                }
                aria-label="Sort"
              />
            </Dropdown>
          )}
        </SearchSegment>

        <ControlsDivider />

        <RightControls>
          <PaginationControls>
            <Button
              icon={<LeftOutlined />}
              disabled={!pagination?.prev}
              onClick={() => handleNavigate(pagination?.prev)}
              aria-label="Previous page"
            />
            <Button
              icon={<RightOutlined />}
              disabled={!pagination?.next}
              onClick={() => handleNavigate(pagination?.next)}
              aria-label="Next page"
            />
          </PaginationControls>

          {!hideTotal && (
            <TotalCount $loading={loading}>
              <span>Total</span>
              <span>{formatNumber(total || 0, 0)}</span>
            </TotalCount>
          )}

          <Button icon={<ReloadOutlined />} onClick={onReload} aria-label="Reload table" />
        </RightControls>
      </SearchPanelContainer>

      {isShowingFilterPanel && hasFilters && (
        <FilterPanel
          filters={filters}
          selections={filterSelections}
          onChange={onChangeFilterSelections}
          activeFilter={activeFilter}
          onChangeActiveFilter={setActiveFilter}
        />
      )}
    </Wrapper>
  );
}

SearchPanel.displayName = 'DataTable.SearchPanel';

import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '@/components-shad/form';
import { Button } from '@/components-shad/ui/button';

const schema = z.object({ value: z.string().nonempty() });
type FormData = z.infer<typeof schema>;

type Props = {
  onSave: (value: string) => void;
  onCancel: () => void;
  descriptor?: string;
  placeholder?: string;
};

export function NewItemForm({ onSave, onCancel, descriptor, placeholder }: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const label = placeholder || (descriptor ? `Enter a new ${descriptor}` : 'Enter a new item');

  return (
    <form onSubmit={handleSubmit(({ value }) => onSave(value))} className="flex items-start gap-4">
      <FormTextField
        aria-label={label}
        name="value"
        control={control}
        placeholder={placeholder}
        autoFocus
        className="flex-1"
      />
      <div className="flex items-center gap-2">
        <Button variant="secondary" onPress={onCancel}>
          Cancel
        </Button>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

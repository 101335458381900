import { CarrierServiceCategory } from '@invenco/common-interface/shipping';
import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Link } from '@/components-shad/ui/link';
import { DataTable, DataTableHeader, DataTableSearchPanel } from '@/components-shad/data-table';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { formatDateTime } from 'shared/helpers';
import { carrierServiceCategoryTitle } from '@/shared/title-maps';
import { useCarrierServiceListPage } from './useCarrierServiceListPage';

export function CarrierServiceListShad() {
  const {
    models: {
      isLoading,
      hasError,
      rows,
      pagination,
      total,
      query,
      carriersById,
      isLoadingCarriers,
    },
    operations: { search, navigate, refresh, onClickRow },
  } = useCarrierServiceListPage();

  return (
    <Page>
      <Header
        title="Carrier services"
        actions={
          <Link variant="default" href="/shipping/carrier_services/new">
            Create carrier service
          </Link>
        }
      />

      <PageContent variant="column">
        <DataTableHeader>
          <DataTableSearchPanel
            loading={isLoading}
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
          />
        </DataTableHeader>

        <DataTable
          rows={rows}
          columns={[
            { title: 'Name', key: 'name' },
            { title: 'Code', key: 'code' },
            {
              title: 'Carrier',
              key: 'carrierId',
              render: (carrierId: string) =>
                isLoadingCarriers ? (
                  <Skeleton className="h-4 w-full" />
                ) : (
                  carriersById?.[carrierId]?.name
                ),
            },
            {
              title: 'Category',
              key: 'category',
              render: (category: CarrierServiceCategory) => carrierServiceCategoryTitle[category],
            },
            {
              title: 'External codes',
              key: 'externalCodes',
              render: (codes: string[]) => codes.join(', '),
            },
            {
              title: 'Created',
              key: 'createdAt',
              render: (createdAt: string) => formatDateTime(createdAt),
            },
          ]}
          loading={isLoading}
          hasError={hasError}
          onClickRow={onClickRow}
        />
      </PageContent>
    </Page>
  );
}

import {
  ChannelDTO,
  OrderAction,
  OrderCurrency,
  OrderDTO,
  OrderLineDTO,
  OrderStatus,
} from '@invenco/common-interface/sales';
import { createId } from '@paralleldrive/cuid2';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useGateways } from 'gateways/GatewayProvider';
import { ComponentData } from 'shared/types';
import { BreadCrumb } from '../../../../../components/header';
import { ORDER_REFRESH_MAXIMUM_CALLS, ORDER_REFRESH_PERIOD_MS } from '../../../../../constants';
import { Result } from '../../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../../shared/hooks/queries';
import { usePeriodicCall } from '../../../../../shared/hooks/usePeriodicCall';
import { useMessages } from '../../../../../shared/providers/MessagesProvider';
import {
  getOrderActionMethod,
  orderActionsToRefresh,
  orderActionSuccessMessage,
} from './actionDetails';
import { CustomerStep } from './customer-modal/useCustomerModalComponent';
import { getCreateOrderLineDTO, getOrderCreateDTO, getOrderUpdateDTO } from './utils';
import { getEnv } from '../../../../../env';

const DEFAULT_DATA: Partial<OrderDTO> = {
  allowPartialFulfillments: false,
  subtotalShipping: 0,
  channelName: 'Default',
  currency: OrderCurrency.AUD,
};

type Models = {
  isLoading: boolean;
  isLoadingChannels: boolean;
  isSaving: boolean;
  isNew: boolean;
  isEditable: boolean;
  isSaveEnabled: boolean;
  hasCustomerInfo: boolean;
  hasManagedActions: boolean;
  isCustomerModalSequential: boolean;
  isCustomerModalOpen: boolean;
  customerModalStep: CustomerStep;
  isShippingModalOpen: boolean;
  isNotesModalOpen: boolean;
  isReferenceModalOpen: boolean;
  isInstructionModalOpen: boolean;
  assignSkuLine?: Partial<OrderLineDTO>;
  isAssignSkuModalOpen: boolean;
  preloadShippingProfiles: boolean;
  orderDetails: Partial<OrderDTO>;
  orderLines: Partial<OrderLineDTO>[];
  selectedChannel?: Partial<ChannelDTO>;
  breadcrumbs: BreadCrumb[];
  isChannelSelectionEnabled: boolean;
  channels: ChannelDTO[];
};

type Operations = {
  refresh: () => Promise<void>;
  save: () => Promise<Result>;
  updateDetails: (details: Partial<OrderDTO>) => Promise<Result>;
  addOrderLine: (orderLine: Partial<OrderLineDTO>) => Promise<Result>;
  updateOrderLine: (lineId: string, orderLine: Partial<OrderLineDTO>) => Promise<Result>;
  deleteOrderLine: (lineId: string) => Promise<void>;
  performAction: (action: OrderAction) => Promise<Result>;
  cancel: () => void;
  openNewCustomerCreation: () => void;
  changeCustomerModalStep: (step: CustomerStep) => void;
  closeCustomerModal: () => void;
  openShippingModal: () => void;
  closeShippingModal: () => void;
  openNotesModal: () => void;
  closeNotesModal: () => void;
  openReferenceModal: () => void;
  closeReferenceModal: () => void;
  openInstructionModal: () => void;
  closeInstructionModal: () => void;
  openAssignSkuModal: (line: Partial<OrderLineDTO>) => void;
  closeAssignSkuModal: () => void;
};

export function useOrderDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';
  const { salesGateway } = useGateways();
  const navigate = useNavigate();
  const messages = useMessages();
  const queryClient = useQueryClient();

  const stage = getEnv();
  const channelSupportedEnv = ['LOCAL', 'DEV', 'STAGE'];

  const [orderDetails, setOrderDetails] = useState<Partial<OrderDTO>>({
    ...DEFAULT_DATA,
  });
  const [orderLines, setOrderLines] = useState<Partial<OrderLineDTO>[]>([]);

  // step is not used as an indication of the modal being open, since when closing all content would disappear
  const [customerModalStep, setCustomerModalStep] = useState<CustomerStep>(CustomerStep.CUSTOMER);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [isCustomerModalSequential, setIsCustomerModalSequential] = useState(false);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
  const [assignSkuLine, setAssignSkuLine] = useState<Partial<OrderLineDTO>>();
  const [isAssignSkuModalOpen, setIsAssignSkuModalOpen] = useState(false);

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'orders',
    id,
    query: (fetchId, { signal }) => salesGateway.getOrder(fetchId, { signal }),
    isNew,
  });
  const isEditable = isNew || data?.status === OrderStatus.DRAFT;

  const { data: channelData, isLoading: isLoadingChannels } = useQuery({
    queryKey: ['channels'],
    queryFn: ({ signal }) => salesGateway.getChannels(undefined, { signal }),
    enabled: isNew || isEditable,
  });

  const channelsFromOrder = data?.channel ? [data.channel] : [];
  const channels = channelData?.items ?? channelsFromOrder;

  const hasCustomerInfo = !isNew || !!orderDetails.customerName;

  const isSaveEnabled = Boolean(
    !isLoading &&
      !isLoadingChannels &&
      orderLines.length &&
      orderDetails.customerName &&
      orderDetails.email &&
      orderDetails.phone &&
      orderDetails.shippingAddress?.contactName &&
      orderDetails.shippingProfileName,
  );

  const hasManagedActions = useMemo(
    () =>
      !!orderDetails.managedActions?.length ||
      orderLines.some((line) => line.managedActions?.length),
    [orderDetails, orderLines],
  );

  const preloadShippingProfiles =
    isNew || (isEditable && !isLoading && !orderDetails.shippingProfileName);

  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '', title: 'Sales' },
      { url: '/sales/orders', title: 'Orders' },
      { url: `/sales/orders/${id}`, title: isNew ? 'New' : orderDetails?.name, loading: isLoading },
    ],
    [id, isNew, orderDetails, isLoading],
  );

  const [triggerPeriodicRefresh, stopRefresh] = usePeriodicCall(
    refetch,
    { maximumCalls: ORDER_REFRESH_MAXIMUM_CALLS, period: ORDER_REFRESH_PERIOD_MS },
    [id, salesGateway],
  );

  useEffect(() => stopRefresh, [id, stopRefresh]);

  useEffect(() => {
    if (data) {
      const { orderLines: newLines, ...details } = data;
      setOrderLines(newLines ?? []);
      setOrderDetails(details);
    } else {
      setOrderLines([]);
      setOrderDetails({ ...DEFAULT_DATA });
    }
  }, [data]);

  const selectedChannel = useMemo(
    () => channels.find((channel) => channel.name === orderDetails.channelName),
    [channels, orderDetails.channelName],
  );

  const refresh = async () => {
    await refetch();
  };

  const { mutate: save, isPending: isCreating } = useGatewayMutation({
    mutationFn: () => salesGateway.createOrder(getOrderCreateDTO(orderDetails, orderLines)),
    onSuccess: (order) => {
      messages.success('Created new draft order', { name: order.name });
      void navigate(`/sales/orders/${order.id}`);
      // order creation may create channels - need to make sure channel selection is updated for draft page
      void queryClient.invalidateQueries({ queryKey: ['channels'] });
    },
    linkedQuery: ['orders', id],
  });

  const { mutate: updateExistingOrder, isPending: isUpdating } = useGatewayMutation({
    mutationFn: async (order: Partial<OrderDTO>) =>
      salesGateway.updateOrder(id!, getOrderUpdateDTO({ ...orderDetails, ...order })),
    successMessage: 'Order updated',
    linkedQuery: ['orders', id],
  });

  const updateDetails = async (newData: Partial<OrderDTO>) => {
    const order = { ...orderDetails, ...newData };
    setOrderDetails(order);
    return isNew ? Result.ok() : updateExistingOrder(order);
  };

  const { mutate: addOrderLineToExisting } = useGatewayMutation({
    mutationFn: (orderLine: Partial<OrderLineDTO>) =>
      salesGateway.addOrderLine(id!, getCreateOrderLineDTO(orderLine)),
    onSuccess: (newLine) => setOrderLines([...orderLines, newLine]),
    successMessage: 'Added line to order',
    linkedQuery: ['orders', id],
    linkResponseToQuery: false,
  });

  const addOrderLine = async (orderLine: Partial<OrderLineDTO>) => {
    if (isNew) {
      setOrderLines([...orderLines, { id: createId(), ...orderLine }]);
      return Result.ok();
    }
    return addOrderLineToExisting(orderLine);
  };

  const { mutate: updateExistingOrderLine } = useGatewayMutation({
    mutationFn: (orderLine: Partial<OrderLineDTO>) => salesGateway.updateOrderLine(id!, orderLine),
    // for new orders, this performs the state update
    // for existing, reflect state while waiting for main query to update
    onSuccess: (updatedLine) =>
      setOrderLines(orderLines.map((line) => (line.id === updatedLine.id ? updatedLine : line))),
    successMessage: 'Updated line',
    linkedQuery: ['orders', id],
    linkResponseToQuery: false,
  });

  const updateOrderLine = async (lineId: string, orderLine: Partial<OrderLineDTO>) => {
    if (isNew) {
      setOrderLines(
        orderLines.map((line) => (lineId === line.id ? { ...line, ...orderLine } : line)),
      );
      return Result.ok();
    }
    return updateExistingOrderLine({ ...orderLine, id: lineId });
  };

  const deleteOrderLine = async (lineId: string) => {
    if (!isNew) return; // not supported
    setOrderLines(orderLines.filter((line) => line.id !== lineId));
  };

  const { mutate: performAction } = useGatewayMutation({
    onMutate: stopRefresh,
    mutationFn: (action: OrderAction) => getOrderActionMethod(salesGateway, action)(id!),
    onSuccess: (_, action) => {
      messages.success(orderActionSuccessMessage[action]);
      if (orderActionsToRefresh.includes(action)) {
        triggerPeriodicRefresh();
      }
    },
    linkedQuery: ['orders', id],
  });

  const changeCustomerModalStep = (step: CustomerStep) => {
    setCustomerModalStep(step);
    setIsCustomerModalOpen(true);
  };

  const openNewCustomerCreation = () => {
    setCustomerModalStep(CustomerStep.CUSTOMER);
    setIsCustomerModalSequential(true);
    setIsCustomerModalOpen(true);
  };

  const closeCustomerModal = () => {
    setIsCustomerModalOpen(false);
    setIsCustomerModalSequential(false);
  };

  const openAssignSkuModal = (line: Partial<OrderLineDTO>) => {
    setAssignSkuLine(line);
    setIsAssignSkuModalOpen(true);
  };

  const cancel = () => navigate(`/sales/orders/`);

  return {
    models: {
      isLoading,
      isSaving: isCreating || isUpdating,
      isLoadingChannels,
      isNew,
      isEditable,
      isSaveEnabled,
      hasCustomerInfo,
      hasManagedActions,
      isCustomerModalOpen,
      isCustomerModalSequential,
      customerModalStep,
      isShippingModalOpen,
      isNotesModalOpen,
      isReferenceModalOpen,
      isInstructionModalOpen,
      isAssignSkuModalOpen,
      assignSkuLine,
      preloadShippingProfiles,
      orderDetails,
      orderLines,
      breadcrumbs,
      selectedChannel,
      isChannelSelectionEnabled: isEditable && channelSupportedEnv.includes(stage),
      channels,
    },
    operations: {
      refresh,
      save,
      updateDetails,
      addOrderLine,
      updateOrderLine,
      deleteOrderLine,
      performAction,
      openNewCustomerCreation,
      changeCustomerModalStep,
      closeCustomerModal,
      openNotesModal: () => setIsNotesModalOpen(true),
      closeNotesModal: () => setIsNotesModalOpen(false),
      openReferenceModal: () => setIsReferenceModalOpen(true),
      closeReferenceModal: () => setIsReferenceModalOpen(false),
      openInstructionModal: () => setIsInstructionModalOpen(true),
      closeInstructionModal: () => setIsInstructionModalOpen(false),
      openShippingModal: () => setIsShippingModalOpen(true),
      closeShippingModal: () => setIsShippingModalOpen(false),
      openAssignSkuModal,
      closeAssignSkuModal: () => setIsAssignSkuModalOpen(false),
      cancel,
    },
  };
}

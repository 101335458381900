import { useMemo } from 'react';
import { useParams } from 'react-router';
import { BillDTO, BillAction, BillStatus } from '@invenco/common-interface/accounts';
import { ComponentData } from 'shared/types';
import { BreadCrumb } from 'components/header';
import { useEntityDetailsQuery, useGatewayMutation } from 'shared/hooks/queries';
import { useGateways } from 'gateways/GatewayProvider';
import { Result } from 'shared/helpers/Result';

type Models = {
  billingDetails: BillDTO | undefined;
  breadcrumbs: BreadCrumb[];
  isLoading: boolean;
  canCloseBill: boolean;
  isClosingBill: boolean;
};

type Operations = {
  closeBill: () => Promise<Result>;
};

export function useBillingDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const { accountsGateway } = useGateways();

  const { data: billingDetails, isLoading } = useEntityDetailsQuery({
    parentKey: 'bills',
    id,
    query: (fetchId, { signal }) => accountsGateway.getBill(fetchId, { signal }),
  });

  const canCloseBill = Boolean(
    billingDetails &&
      billingDetails?.status !== BillStatus.CLOSED &&
      billingDetails?.allowableActions?.includes(BillAction.CLOSE),
  );

  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/bills', title: 'Bills' },
      {
        url: `/bills/${id}`,
        title: billingDetails ? billingDetails.name : '',
        loading: isLoading,
      },
    ],
    [id, billingDetails, isLoading],
  );

  const { mutate: closeBill, isPending: isClosingBill } = useGatewayMutation({
    mutationFn: () => accountsGateway.closeBill(id!),
    linkedQuery: ['bills', id],
    linkResponseToQuery: false,
    successMessage: 'Bill closed successfully',
  });

  return {
    models: {
      billingDetails,
      breadcrumbs,
      isLoading,
      canCloseBill,
      isClosingBill,
    },
    operations: { closeBill },
  };
}

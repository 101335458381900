import { useState } from 'react';
import { useFilter as ariaUseFilter, type Selection } from 'react-aria-components';
import { FilterOption } from '../types';
import type { Props as FilterProps } from '../data-filter';
import {
  getNextSelectedItemsForMultiSelect,
  getNextSelectedItemsForSingleSelect,
} from '../utils/selection-handlers';
import { toFilterOptionArray } from '../utils/mappers';
import { getNextListItems } from '../utils/get-next-list-items';
import { useSelectedItemsCache } from './useSelectedItemsCache';

type Props = {
  /**
   * title of the filter, displayed in the filter button
   */
  title: string;
  /**
   * items for the filter, displayed in the dropdown
   */
  items: FilterOption[];
  /**
   * selected items for the filter, displayed in the filter button and appear in the dropdown
   * with a checkmark icon
   */
  selectedItems: FilterOption[];
  /**
   * whether the filter is multi-select or single-select
   */
  isMultiSelect: boolean;
  /**
   * callback to handle the selection of items for the filter
   */
  onChange: (selectedItems: FilterOption[]) => void;
};

/**
 * This hook is used in conjunction with the DataFilter component to provide a filter that
 * is powered by a static list of items.
 *
 * The hook returns a set of props that can be passed to the DataFilter component.
 */
export const useStaticDataFilter = ({
  title,
  items: itemsProp,
  isMultiSelect,
  selectedItems: selectedItemsProp,
  onChange,
}: Props): FilterProps => {
  const [selectedItemsCache, setSelectedItemsCache] = useSelectedItemsCache(selectedItemsProp);

  const [searchTerm, setSearchTerm] = useState('');

  const [matchedItems, setMatchedItems] = useState(itemsProp);

  const { contains } = ariaUseFilter({ sensitivity: 'base' });

  const selectedItems = toFilterOptionArray(selectedItemsCache);

  const items = getNextListItems(matchedItems, selectedItemsCache, searchTerm);

  /**
   * filters the items based on the searchTerm and refresh the state
   */
  const onInputChange = (nextSearchTerm: string) => {
    const nextItems =
      nextSearchTerm === ''
        ? itemsProp
        : itemsProp.filter((item) => contains(item.displayName, nextSearchTerm));

    setMatchedItems(nextItems);
    setSearchTerm(nextSearchTerm);
  };

  /**
   * Handle when user selects or de-selects an option from
   * the visible list items
   */
  const onSelectionChange = (selectedKeys: Selection) => {
    const nextSelectedItemsCache = isMultiSelect
      ? getNextSelectedItemsForMultiSelect(matchedItems, selectedItemsCache, selectedKeys)
      : getNextSelectedItemsForSingleSelect(matchedItems, selectedKeys);

    setSelectedItemsCache(nextSelectedItemsCache);
    onChange(toFilterOptionArray(nextSelectedItemsCache));
  };

  const onClose = () => {
    if (searchTerm !== '') {
      setSearchTerm('');
    }
  };

  return {
    title,
    items,
    selectedItems,
    isLoadingItems: false,
    isMultiSelect,
    onSelectionChange,
    onInputChange,
    onOpen: undefined,
    onClose,
  };
};

import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '@/components-shad/form';
import { Result } from '@/shared/helpers/Result';

const schema = z.object({
  email: z.string().email(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  onSubmit: (email: string) => Promise<Result>;
};

export function AddUserDialog({ onSubmit }: Props) {
  const { handleSubmit, control } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={(data) => onSubmit(data.email)}
      title="Invite user"
      submitLabel="Invite"
    >
      <FormTextField name="email" label="User email" control={control} />
    </FormDialog>
  );
}

import { Popover, PopoverTrigger } from '@/components-shad/ui/popover';
import { useCallback } from 'react';
import type { Selection } from 'react-aria-components';
import { FilterButton } from './components/filter-button/filter-button';
import { FilterDropdown } from './components/filter-dropdown/filter-dropdown';
import { FilterOption } from './types';

export type Props = {
  title;
  items: FilterOption[];
  selectedItems: FilterOption[];
  isLoadingItems: boolean;
  isMultiSelect: boolean;
  onSelectionChange: (selectedKeys: Selection) => void;
  onInputChange: (nextSearchTerm) => void;
  onClose: () => void;
  onOpen?: () => void;
};

export function DataFilter({
  title,
  items,
  selectedItems,
  isLoadingItems,
  isMultiSelect,
  onSelectionChange,
  onInputChange,
  onOpen,
  onClose,
}: Props) {
  const onOpenChange = useCallback(
    (isOpen) => {
      if (isOpen && onOpen) {
        onOpen();
      } else if (!isOpen && onClose) {
        onClose();
      }
    },
    [onOpen, onClose],
  );

  return (
    <PopoverTrigger onOpenChange={onOpenChange}>
      <FilterButton filterName={title} selectedItems={selectedItems} />
      <Popover placement="bottom left" className="flex max-h-96 min-w-64 max-w-96 flex-col">
        <FilterDropdown
          items={items}
          selectedItems={selectedItems}
          isLoadingItems={isLoadingItems}
          isMultiSelect={isMultiSelect}
          onInputChange={onInputChange}
          onSelectionChange={onSelectionChange}
        />
      </Popover>
    </PopoverTrigger>
  );
}

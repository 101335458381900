import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import { Button } from '@/components-shad/ui/button';
import { PlusIcon } from 'lucide-react';
import { formatMoney } from '@/shared/helpers';
import { usePriceBands } from '../../price-bands/usePriceBands';
import { BandItem, LoadingBandItem } from './BandItem';

type Props = {
  isLoading: boolean;
  priceBands: ShippingPriceBandDTO[];
  addBand: (band: ShippingPriceBandDTO) => void;
  updateBand: (index: number, band: Partial<ShippingPriceBandDTO>) => void;
  removeBand: (index: number) => void;
};

export function PriceBandsShad({ isLoading, priceBands, addBand, updateBand, removeBand }: Props) {
  const {
    models: {
      editingIndex,
      isAddingBand,
      examplePrice,
      exampleMarkups,
      exampleTotalMarkup,
      numberOfLastBands,
    },
    operations: { openAddForm, cancelAdding, saveNewBand, editBand, saveBand, cancelEditing },
  } = usePriceBands({ priceBands, addBand, updateBand });

  const showList = priceBands.length > 0 || isAddingBand || isLoading;

  return (
    <div className="flex flex-col gap-4">
      {showList && (
        <ul className="grid grid-cols-[auto_1fr] gap-2 sm:grid-cols-[repeat(6,auto)_1fr]">
          {isLoading ? (
            <>
              <LoadingBandItem />
              <LoadingBandItem />
            </>
          ) : (
            priceBands.map((band, index) => (
              <BandItem
                key={
                  // unfortunately these have nothing guaranteed unique other than the id
                  // eslint-disable-next-line react/no-array-index-key
                  index
                }
                priceBand={band}
                previousBand={priceBands[index - 1]}
                exampleMarkup={exampleMarkups[index]}
                isEditing={editingIndex === index}
                onSave={(updatedBand) => saveBand(index, updatedBand)}
                onCancel={cancelEditing}
                onEdit={() => editBand(index)}
                onDelete={() => removeBand(index)}
              />
            ))
          )}

          {isAddingBand && (
            <BandItem
              priceBand={{ markup: 0 }}
              previousBand={priceBands[priceBands.length - 1]}
              isEditing
              onCancel={cancelAdding}
              onSave={saveNewBand}
            />
          )}
        </ul>
      )}

      <div className="flex items-center justify-between">
        <Button
          variant="outline"
          size="sm"
          icon={<PlusIcon />}
          onPress={openAddForm}
          isDisabled={isAddingBand}
        >
          Add new band
        </Button>
        {priceBands.length > 0 && (
          <div className="mr-20 hidden text-xs text-muted-foreground md:block">
            For {formatMoney(examplePrice)}, the total markup = {formatMoney(exampleTotalMarkup)}
          </div>
        )}
      </div>

      {numberOfLastBands === 0 && (
        <div className="text-xs text-destructive">Missing a final band with no maximum cost.</div>
      )}
      {numberOfLastBands !== undefined && numberOfLastBands > 1 && (
        <div className="text-xs text-destructive">
          There a multiple bands with no maximum cost. There should only be one.
        </div>
      )}
    </div>
  );
}

import { CarrierDTO } from '@invenco/common-interface/shipping';
import { useDataTable, useRowNavigation } from 'components/data-table';
import { useQueryWithInput } from 'shared/hooks/queries';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';

export type CarrierRow = CarrierDTO;

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: CarrierRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  refresh: () => Promise<void>;
  onClickRow: (row: CarrierRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useCarrierListPage(): ComponentData<Models, Operations> {
  const { shippingGateway } = useGateways();

  const { onClickRow } = useRowNavigation<CarrierRow>({ baseUrl: '/shipping/carriers' });
  const [tableState, setTableState] = useDataTable();

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'workflows',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      include: { carrierServices: true },
      q: tableState.query,
    },
    query: (input, { signal }) => shippingGateway.getCarriers(input, { signal }),
  });

  return {
    models: {
      isLoading,
      hasError: isError,
      rows: data?.items as CarrierRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      refresh: async () => {
        await refetch();
      },
      onClickRow,
    },
  };
}

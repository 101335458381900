import { Tab, TabList, Tabs } from '@/components-shad/ui/tabs';
import { ViewTab } from './types';

type Props = {
  views: ViewTab[];
  current: string;
  onChange: (key: string) => void;
};

export function DataTableTabs({ views, current, onChange }: Props) {
  return (
    <Tabs
      className="w-full sm:w-fit"
      selectedKey={current}
      onSelectionChange={(key) => {
        if (typeof key === 'string') {
          onChange(key);
        }
      }}
    >
      <TabList items={views}>{({ key, title }) => <Tab id={key}>{title}</Tab>}</TabList>
    </Tabs>
  );
}

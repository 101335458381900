import React from 'react';
import { CalendarIcon } from 'lucide-react';
import {
  composeRenderProps,
  DatePicker as AriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  DateRangePicker as AriaDateRangePicker,
  DateRangePickerProps as AriaDateRangePickerProps,
  DateValue as AriaDateValue,
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
  PopoverProps as AriaPopoverProps,
  ValidationResult as AriaValidationResult,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';
import { Popover } from '@/components-shad/ui/popover';

import { Button } from './button';
import {
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarHeading,
  RangeCalendar,
} from './calendar';
import { DateInput } from './datefield';
import { FieldDescription, FieldError, FieldGroup, Label } from './field';

const BaseDatePicker = AriaDatePicker;

const BaseDateRangePicker = AriaDateRangePicker;

function DatePickerContent({
  className,
  popoverClassName,
  ...props
}: AriaDialogProps & { popoverClassName?: AriaPopoverProps['className'] }) {
  return (
    <Popover
      className={composeRenderProps(popoverClassName, (composedClassName) =>
        cn('w-auto p-3', composedClassName),
      )}
    >
      <AriaDialog
        className={cn(
          'flex w-full flex-col space-y-4 outline-none sm:flex-row sm:space-x-4 sm:space-y-0',
          className,
        )}
        {...props}
      />
    </Popover>
  );
}

interface DatePickerProps<T extends AriaDateValue> extends AriaDatePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

// TODO: remove this once upgraded to React 19
type DatePickerRefT = React.Ref<React.ElementRef<typeof FieldGroup>>;
const DatePicker = React.forwardRef(
  <T extends AriaDateValue>(
    { label, description, errorMessage, className, ...props }: DatePickerProps<T>,
    ref: DatePickerRefT,
  ) => (
    <BaseDatePicker
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      <Label>{label}</Label>
      <FieldGroup ref={ref}>
        <DateInput className="flex-1" variant="ghost" />
        <Button
          variant="ghost"
          icon={<CalendarIcon aria-hidden />}
          className="mr-1 size-6 data-[focus-visible]:ring-offset-0"
          aria-label="Open date picker"
        />
      </FieldGroup>
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
      <DatePickerContent>
        <Calendar>
          <CalendarHeading />
          <CalendarGrid>
            <CalendarGridHeader>
              {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
            </CalendarGridHeader>
            <CalendarGridBody>{(date) => <CalendarCell date={date} />}</CalendarGridBody>
          </CalendarGrid>
        </Calendar>
      </DatePickerContent>
    </BaseDatePicker>
  ),
) as <T extends AriaDateValue>(
  props: DatePickerProps<T> & { ref?: DatePickerRefT },
) => React.ReactElement;

interface DateRangePickerProps<T extends AriaDateValue> extends AriaDateRangePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

function DateRangePicker<T extends AriaDateValue>({
  label,
  description,
  errorMessage,
  className,
  ...props
}: DateRangePickerProps<T>) {
  return (
    <BaseDateRangePicker
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      <Label>{label}</Label>
      <FieldGroup>
        <DateInput variant="ghost" slot="start" />
        <span aria-hidden className="px-2 text-sm text-muted-foreground">
          -
        </span>
        <DateInput className="flex-1" variant="ghost" slot="end" />

        <Button
          variant="ghost"
          icon={<CalendarIcon aria-hidden />}
          className="mr-1 size-6 data-[focus-visible]:ring-offset-0"
          aria-label="Open date range picker"
        />
      </FieldGroup>
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
      <DatePickerContent>
        <RangeCalendar>
          <CalendarHeading />
          <CalendarGrid>
            <CalendarGridHeader>
              {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
            </CalendarGridHeader>
            <CalendarGridBody>{(date) => <CalendarCell date={date} />}</CalendarGridBody>
          </CalendarGrid>
        </RangeCalendar>
      </DatePickerContent>
    </BaseDateRangePicker>
  );
}

export { BaseDatePicker, DatePickerContent, BaseDateRangePicker, DatePicker, DateRangePicker };
export type { DatePickerProps, DateRangePickerProps };

import { PlusCircleIcon } from 'lucide-react';
import { Badge } from '@/components-shad/ui/badge';
import { Separator } from '@/components-shad/ui/separator';
import { Button } from '@/components-shad/ui/button';
import { FilterOption } from '../../types';

type Props = {
  filterName: string;
  selectedItems: FilterOption[];
};

export function FilterButton({ filterName, selectedItems }: Props) {
  const selectedItemCount = selectedItems.length;

  return (
    <Button size="sm" variant="outline" className="border-dashed" icon={<PlusCircleIcon />}>
      {filterName}
      <span className="sr-only">filter</span>

      {selectedItemCount > 0 && (
        <>
          <Separator orientation="vertical" className="mx-1.5 h-4" />

          {/** mobile screen */}
          <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
            {selectedItemCount} <span className="sr-only">selected</span>
          </Badge>

          {/** large screen */}
          <div className="hidden space-x-1 lg:flex">
            {selectedItemCount > 2 ? (
              <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                {selectedItemCount} selected
              </Badge>
            ) : (
              // show full item names when selectedItems === 2
              <>
                {selectedItems.map((item, index) => {
                  const hasMore = index < selectedItems.length - 1;

                  return (
                    <Badge
                      key={item.value}
                      variant="secondary"
                      className="rounded-sm px-1 font-normal"
                    >
                      {hasMore && <span className="sr-only">and</span>}
                      {item.displayName}
                    </Badge>
                  );
                })}
                <span className="sr-only">selected</span>
              </>
            )}
          </div>
        </>
      )}
    </Button>
  );
}

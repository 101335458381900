import React, { ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn, toTitleCase } from '@/shared/helpers';
import { badgeVariants } from '@/components-shad/ui/badge';

const statusBadgeVariants = cva('border-transparent text-xs tracking-tight shadow-sm', {
  variants: {
    type: {
      success: 'bg-state-success text-state-success-foreground',
      progress: 'bg-state-progress text-state-progress-foreground',
      observe: 'bg-state-observe text-state-observe-foreground',
      alert: 'bg-state-alert text-state-alert-foreground',
      inactive: 'bg-state-inactive text-state-inactive-foreground',
    },
  },
});

export type StatusType = VariantProps<typeof statusBadgeVariants>['type'];

export interface StatusBadgeProps extends Omit<React.HTMLAttributes<'div'>, 'title'> {
  status?: string;
  type?: StatusType | Record<string, StatusType>;
  title?: string | Record<string, string>;
}

export function StatusBadge({
  status,
  type: providedType,
  title,
  className,
  children,
}: StatusBadgeProps) {
  let type: StatusType = 'inactive';
  if (typeof providedType === 'string') {
    type = providedType;
  } else if (providedType && status) {
    type = providedType[status];
  }

  let content: ReactNode;
  if (React.Children.count(children)) {
    content = children;
  } else if (typeof title === 'string') {
    content = title;
  } else if (title && status) {
    content = title[status];
  } else if (status) {
    content = toTitleCase(status.replace('_', ' '));
  }

  return (
    <div
      className={cn(
        badgeVariants({ variant: 'outline' }),
        statusBadgeVariants({ type }),
        className,
      )}
    >
      {content}
    </div>
  );
}

import { cva, type VariantProps } from 'class-variance-authority';
import {
  ToggleButton as AriaToggleButton,
  ToggleButtonGroup as AriaToggleButtonGroup,
  composeRenderProps,
  type ToggleButtonGroupProps as AriaToggleButtonGroupProps,
  type ToggleButtonProps as AriaToggleButtonProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

const toggleVariants = cva(
  [
    'inline-flex items-center justify-center gap-2 rounded-md text-sm font-medium transition-colors',
    /* Disabled */
    'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
    /* Hover */
    'data-[hovered]:bg-muted data-[hovered]:text-muted-foreground',
    /* Selected */
    'data-[selected]:bg-accent data-[selected]:text-accent-foreground',
    /* Focus Visible */
    'data-[focus-visible]:outline-none data-[focus-visible]:ring-1 data-[focus-visible]:ring-ring',
    /* Resets */
    'focus-visible:outline-none',
    /* Icons */
    '[&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  ],
  {
    variants: {
      variant: {
        default: 'bg-transparent',
        outline:
          'border border-input bg-transparent shadow-sm data-[hovered]:bg-accent data-[hovered]:text-accent-foreground',
      },
      size: {
        default: 'h-9 min-w-9 px-2',
        sm: 'h-8 min-w-8 px-1.5',
        lg: 'h-10 min-w-10 px-2.5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

interface ToggleProps extends AriaToggleButtonProps, VariantProps<typeof toggleVariants> {}

function Toggle({ className, variant, size, ...props }: ToggleProps) {
  return (
    <AriaToggleButton
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'group-data-[orientation=vertical]/togglegroup:w-full',
          toggleVariants({
            variant,
            size,
            className: composedClassName,
          }),
        ),
      )}
      {...props}
    />
  );
}

function ToggleButtonGroup({ children, className, ...props }: AriaToggleButtonGroupProps) {
  return (
    <AriaToggleButtonGroup
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'group/togglegroup flex items-center justify-center gap-1 data-[orientation=vertical]:flex-col',
          composedClassName,
        ),
      )}
      {...props}
    >
      {children}
    </AriaToggleButtonGroup>
  );
}

export { Toggle, toggleVariants, ToggleButtonGroup };
export type { ToggleProps };

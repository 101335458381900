import { CarrierDTO, CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDataTable, useRowNavigation } from 'components/data-table';
import { useQueryWithInput } from 'shared/hooks/queries';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';

export type CarrierServiceRow = CarrierServiceDTO;

type Models = {
  carriersById?: Record<string, CarrierDTO>;
  isLoading: boolean;
  isLoadingCarriers: boolean;
  hasError: boolean;
  rows?: CarrierServiceRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  refresh: () => Promise<void>;
  onClickRow: (row: CarrierServiceRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useCarrierServiceListPage(): ComponentData<Models, Operations> {
  const { shippingGateway } = useGateways();

  const { onClickRow } = useRowNavigation<CarrierServiceRow>({
    baseUrl: '/shipping/carrier_services',
  });
  const [tableState, setTableState] = useDataTable();

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'carrierServices',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      q: tableState.query,
    },
    query: (input, { signal }) => shippingGateway.getCarrierServices(input, { signal }),
  });

  const { data: carriersData, isLoading: isLoadingCarriers } = useQuery({
    queryKey: ['carriers'],
    queryFn: ({ signal }) => shippingGateway.getCarriers(undefined, { signal }),
  });

  const carriersById = useMemo(
    () =>
      carriersData?.items.reduce(
        (acc, carrier) => ({ ...acc, [carrier.id!]: carrier }),
        {},
      ) as Record<string, CarrierDTO>,
    [carriersData],
  );

  return {
    models: {
      carriersById,
      isLoading,
      hasError: isError,
      rows: data?.items as CarrierServiceRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      isLoadingCarriers,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      refresh: async () => {
        await refetch();
      },
      onClickRow,
    },
  };
}

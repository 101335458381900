import { OrderAction, OrderManagedAction } from '@invenco/common-interface/sales';
import { ComponentProps } from 'react';
import { Button } from 'components/button';
import { ContextMenu } from 'components/context-menu';
import { ModalConfirm } from 'components/modal-confirm';
import { useOrderActionsPanelComponent } from './useOrderActionsPanelComponent';
import { orderActionTitle } from '../../../../../../shared/title-maps';
import { Result } from '../../../../../../shared/helpers/Result';

type Props = {
  availableActions: OrderAction[];
  managedActions: OrderManagedAction[];
  onAction: (action: OrderAction) => Promise<Result>;
};

const CONFIRM_ACTIONS: OrderAction[] = [OrderAction.CANCEL, OrderAction.CLOSE];

export function OrderActionsPanel({ availableActions, managedActions, onAction }: Props) {
  const {
    models: { performingAction, actions, modalAction },
    operations: { performAction, isActionDisabled, submitModalConfirm, closeModal, openModal },
  } = useOrderActionsPanelComponent({ availableActions, managedActions, onAction });

  const contextMenuItems = actions.filter((action) => CONFIRM_ACTIONS.includes(action));
  const actionButtons = actions.filter((action) => !CONFIRM_ACTIONS.includes(action));

  return (
    <>
      {contextMenuItems.length > 0 && (
        <ContextMenu
          onSelect={({ key }) => openModal(OrderAction[key])}
          items={contextMenuItems.map((action) => ({
            key: action,
            label: orderActionTitle[action],
          }))}
          buttonLabel="Further actions"
        />
      )}
      {actionButtons.map((action, index) => {
        const commonProps: Partial<ComponentProps<typeof Button>> = {
          loading: performingAction === action,
          disabled: isActionDisabled(action),
          type: index === actionButtons.length - 1 ? 'primary' : 'default',
        };

        return (
          <Button key={action} onClick={() => performAction(action)} {...commonProps}>
            {orderActionTitle[action]}
          </Button>
        );
      })}
      <ModalConfirm
        title={`Are you sure you want to ${modalAction?.toLowerCase()} the order?`}
        description="This action cannot be undone, and the order will not be processed"
        open={modalAction !== undefined}
        onOk={submitModalConfirm}
        onCancel={closeModal}
        maskClosable={false}
        isSubmitting={!!performingAction}
        forceRender
      />
    </>
  );
}

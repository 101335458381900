import type { ShippingProfileCarrierServiceDTO } from '@invenco/common-interface/sales';
import type { CarrierDTO, CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { Avatar, Checkbox, Collapse, CollapseProps } from 'antd';
import { DownOutlined, GlobalOutlined } from '@ant-design/icons';
import { HorizontallySpaced } from '../../../../../styles/layout';
import { CarrierCollapse, CarrierListTitle, CarrierServiceLine } from './styles';
import { useCarrierListComponent, SelectionState } from './useCarrierListComponent';

type Props = {
  carriersById: Record<string, CarrierDTO>;
  servicesByCarrierId: Record<string, CarrierServiceDTO[]>;
  selectedCarrierServices?: ShippingProfileCarrierServiceDTO[];
  title?: string;
  selectable?: boolean;
  onSelected?: (carrierServiceIds: string[]) => void;
  onDeselected?: (carrierServiceIds: string[]) => void;
};

export const renderCollapseIcon: CollapseProps['expandIcon'] = ({ isActive }) => (
  <DownOutlined rotate={isActive ? 180 : 0} aria-hidden="true" />
);

export function CarrierList({
  carriersById,
  servicesByCarrierId,
  title,
  selectedCarrierServices,
  selectable,
  onSelected,
  onDeselected,
}: Props) {
  const {
    models: { allSelectionState, carrierSelectionState, selectedIdSet },
    operations: { changeSelection, changeCarrierSelection, changeAllSelection },
  } = useCarrierListComponent({
    servicesByCarrierId,
    selectedCarrierServices,
    onSelected,
    onDeselected,
  });
  return (
    <div>
      {title && (
        <CarrierListTitle>
          {selectable ? (
            <Checkbox
              indeterminate={allSelectionState === SelectionState.PARTIAL}
              checked={allSelectionState === SelectionState.ALL}
              onChange={({ target: { checked } }) => changeAllSelection(checked)}
            >
              {title}
            </Checkbox>
          ) : (
            title
          )}
        </CarrierListTitle>
      )}
      <CarrierCollapse
        bordered={false}
        expandIconPosition="end"
        expandIcon={renderCollapseIcon}
        $selectable={selectable}
      >
        {Object.entries(servicesByCarrierId)
          .sort(([idA], [idB]) => carriersById[idA].name.localeCompare(carriersById[idB].name))
          .map(([carrierId, services]) => {
            const carrierName = carriersById[carrierId].name;
            return (
              <Collapse.Panel
                key={carrierId}
                header={
                  <HorizontallySpaced
                    aria-label={title ? `${title} services for ${carrierName}` : carrierName}
                  >
                    {selectable && (
                      <Checkbox
                        indeterminate={carrierSelectionState[carrierId] === SelectionState.PARTIAL}
                        checked={carrierSelectionState[carrierId] === SelectionState.ALL}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => changeCarrierSelection(carrierId, e.target.checked)}
                        aria-label={
                          title
                            ? `Select ${title} services for ${carrierName}`
                            : `Select ${carrierName}`
                        }
                      />
                    )}
                    <Avatar shape="square" icon={<GlobalOutlined aria-hidden="true" />} />
                    <span>{carrierName}</span>
                  </HorizontallySpaced>
                }
              >
                {services
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(
                    (service) =>
                      service.id && (
                        <CarrierServiceLine key={service.id}>
                          {selectable ? (
                            <Checkbox
                              checked={selectedIdSet.has(service.id!)}
                              onChange={({ target: { checked } }) =>
                                changeSelection(service.id!, checked)
                              }
                            >
                              {service.name}
                            </Checkbox>
                          ) : (
                            service.name
                          )}
                        </CarrierServiceLine>
                      ),
                  )}
              </Collapse.Panel>
            );
          })}
      </CarrierCollapse>
    </div>
  );
}

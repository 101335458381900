import { PropsWithChildren } from 'react';
import { cn } from '@/shared/helpers';

export function Page({ children }: PropsWithChildren) {
  return <div className="flex h-full w-full flex-1 flex-col gap-6">{children}</div>;
}

type ContentContainerProps = PropsWithChildren<{
  variant: 'column' | 'split';
  className?: string;
  /**
   * TODO:
   * decide if we expose a new `scroll` boolean prop here that, when enabled, will contain the
   * content to its current size and add a scroll bar to handle overflow. Note - It might be feasible
   * to introduce the shadcn <ScrollArea> component to control scrolling https://ui.shadcn.com/docs/components/scroll-area
   */
  // scroll?: boolean;
}>;

export function PageContent({ variant, className, children }: ContentContainerProps) {
  const style = cn(
    'flex min-h-0 flex-1 flex-col gap-6',
    {
      'flex-col': variant === 'column',
      'flex-col-reverse justify-end md:flex-row md:justify-start': variant === 'split',
    },
    className,
  );
  return variant === 'column' ? (
    <main className={style}>{children}</main>
  ) : (
    <div className={style}>{children}</div>
  );
}

export function DetailMain({ children }: PropsWithChildren) {
  return <main className="flex min-w-0 flex-col gap-6 md:flex-1">{children}</main>;
}

export function DetailSide({ children }: PropsWithChildren) {
  return <aside className="flex w-full flex-col gap-6 md:w-80 xl:w-96">{children}</aside>;
}

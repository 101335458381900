import type { FilterOption, FilterOptionMap } from '../types';

export const toFilterOptionArray = (
  map: Map<FilterOption['value'], FilterOption['displayName']>,
): FilterOption[] =>
  Array.from(map).map(([key, value]) => ({
    displayName: value,
    value: key,
  }));

export const toFilterOptionMap = (options: FilterOption[]): FilterOptionMap =>
  new Map(options.map(({ displayName, value }) => [value, displayName]));

import { Route, Routes } from 'react-router';
import { commonRoutes, redirectRoute } from 'shared/helpers/routing';
import { AccountListShad } from '@/pages/platform/accounts/account-list/AccountListShad';
import { AccountDetailsShad } from '@/pages/platform/accounts/account-details/shad/AccountDetailsShad';
import { CarrierServiceDetailsShad } from '@/pages/platform/carriers/carrier-service-details/shad/CarrierServiceDetailsShad';
import { CarrierDetailsShad } from '@/pages/platform/carriers/carrier-details/shad/CarrierDetailsShad';
import { ShippingPriceListShad } from '@/pages/platform/shipping-prices/shipping-price-list/ShippingPriceListShad';
import { ShippingPriceDetailsShad } from '@/pages/platform/shipping-prices/shipping-price-details/shad/ShippingPriceDetailsShad';
import { useNewUiFeatureFlag } from '@/shared/hooks/useNewUiFeatureFlag';
import { AccountList } from './accounts/account-list/AccountList';
import { AccountDetails } from './accounts/account-details/AccountDetails';
import { LocationList } from './locations/location-list/LocationList';
import { LocationListShad } from './locations/location-list/LocationListShad';
import { LocationDetails } from './locations/location-details/LocationDetails';
import { CarrierList } from './carriers/carrier-list/CarrierList';
import { CarrierListShad } from './carriers/carrier-list/CarrierListShad';
import { CarrierDetails } from './carriers/carrier-details/CarrierDetails';
import { CarrierServiceList } from './carriers/carrier-service-list/CarrierServiceList';
import { CarrierServiceDetails } from './carriers/carrier-service-details/CarrierServiceDetails';
import { ShippingPriceList } from './shipping-prices/shipping-price-list/ShippingPriceList';
import { ShippingPriceDetails } from './shipping-prices/shipping-price-details/ShippingPriceDetails';
import { ShipmentList } from './shipments/shipment-list/ShipmentList';
import { ShipmentListShad } from './shipments/shipment-list/shad/ShipmentListShad';
import { BillList } from './billings/bill-list/BillList';
import { BillingDetails } from './billings/billing-details/BillingDetails';
import { CarrierServiceListShad } from './carriers/carrier-service-list/CarrierServiceListShad';
import { RequestQuote } from './quotes/RequestQuote';
import { useAuthToken } from '../../shared/hooks/useAuthToken';
import { LocationDetails as LocationDetailsShad } from './locations/location-details/shad/LocationDetails';

export function PlatformRoutes() {
  const { isInvencoUser } = useAuthToken();

  // TODO LYNK-3372 delete this variable
  const isNewUi = useNewUiFeatureFlag();

  return (
    <Routes>
      {redirectRoute('/', '/dashboard')}
      {redirectRoute('/dashboard', '/accounts')}

      <Route path="/accounts">
        {/* TODO LYNK-3372 delete isNewUI check, always return AccountListShad */}
        <Route index element={isNewUi ? <AccountListShad /> : <AccountList />} />
        <Route path=":id" element={isNewUi ? <AccountDetailsShad /> : <AccountDetails />} />
      </Route>

      <Route path="/locations">
        <Route index element={isNewUi ? <LocationListShad /> : <LocationList />} />
        {/* TODO LYNK-3372 delete isNewUI check, rename LocationDetailsShad to LocationDetails 
        and remove the original LocationDetails */}
        <Route path=":id" element={isNewUi ? <LocationDetailsShad /> : <LocationDetails />} />
      </Route>

      <Route path="/shipping">
        {redirectRoute('', 'carriers')}
        <Route path="carriers">
          <Route index element={isNewUi ? <CarrierListShad /> : <CarrierList />} />
          <Route path=":id" element={isNewUi ? <CarrierDetailsShad /> : <CarrierDetails />} />
        </Route>

        <Route path="carrier_services">
          <Route index element={isNewUi ? <CarrierServiceListShad /> : <CarrierServiceList />} />
          <Route
            path=":id"
            element={isNewUi ? <CarrierServiceDetailsShad /> : <CarrierServiceDetails />}
          />
        </Route>

        <Route path="prices">
          <Route index element={isNewUi ? <ShippingPriceListShad /> : <ShippingPriceList />} />
          <Route
            path=":id"
            element={isNewUi ? <ShippingPriceDetailsShad /> : <ShippingPriceDetails />}
          />
        </Route>

        <Route path="shipments" element={isNewUi ? <ShipmentListShad /> : <ShipmentList />} />
        <Route path="quotes" element={<RequestQuote />} />
      </Route>

      <Route path="/bills">
        <Route index element={<BillList />} />
        <Route path=":id" element={<BillingDetails />} />
      </Route>

      {commonRoutes(isInvencoUser)}
    </Routes>
  );
}

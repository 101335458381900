import { useState } from 'react';
import isEqual from 'lodash/isEqual';

import { FilterOption, FilterOptionMap } from '../types';
import { toFilterOptionMap } from '../utils/mappers';

/**
 * stores a filter's selected items as a Map in React state so selected values are remembered
 * across different renders that use varying search terms to filter the list, which may hide
 * previously selected items
 *
 * Note:
 * This hook is used internally by useDataFilter / useStaticDataFilter only, not for public
 * consumption!
 */
export const useSelectedItemsCache = (
  selectedItemsProp: FilterOption[],
): [
  /** the current selected items cache, stored as a Map */
  FilterOptionMap,
  /** a fn to update the cache with a new map - typically called in an onChange handler */
  React.Dispatch<React.SetStateAction<FilterOptionMap>>,
] => {
  const [prevSelectedItemsProp, setPrevSelectedItemsProp] = useState(selectedItemsProp);

  const [selectedItemsCache, setSelectedItemsCache] = useState(
    toFilterOptionMap(selectedItemsProp),
  );

  // this check is needed in case selectedItemsProp is changed by components other than the filter,
  // such as when the user switches tabs to filter a data-table. When this happens we sync
  // the cache back up with the passed in selectedItemsProp
  if (!isEqual(prevSelectedItemsProp, selectedItemsProp)) {
    setPrevSelectedItemsProp(selectedItemsProp);
    setSelectedItemsCache(toFilterOptionMap(selectedItemsProp));
  }

  return [selectedItemsCache, setSelectedItemsCache];
};

import { zodResolver } from '@hookform/resolvers/zod';
import { CarrierDTO } from '@invenco/common-interface/shipping';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { FormTextField, getFormDefaults } from '@/components-shad/form';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';

const schema = z.object({
  name: z.string().nonempty(),
  code: z.string().nonempty(),
  baseTrackingUrl: z
    .string()
    .regex(
      /<tracking_number>/,
      'The URL must contain <tracking_number> in it, which will be substituted for the actual tracking number',
    )
    .nullish(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  carrier: Partial<CarrierDTO>;
  onSubmit: (data: Partial<CarrierDTO>) => void;
};

export function CarrierDetailDialog({ carrier, onSubmit }: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getFormDefaults(schema, carrier),
  });

  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={onSubmit}
      title={carrier.name ? 'Edit carrier' : 'Add carrier'}
    >
      <FormTextField control={control} name="name" label="Carrier name" />
      <FormTextField control={control} name="code" label="Carrier code" />
      <FormTextField
        control={control}
        name="baseTrackingUrl"
        label="Base tracking URL"
        placeholder="https://example.com/tracking/<tracking_number>"
      />
    </FormDialog>
  );
}

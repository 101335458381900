import { FilterOption, FilterOptionMap } from '../types';
import { toFilterOptionArray } from './mappers';

/**
 * Ensure any selected items always exists in the items list, when the list is not
 * being filtered by a search term.
 *
 * It is possible to get in such a state when, for example, the backend is powering the
 * search term filtering and has only loaded the first 50 options into the default (empty search
 * term) list, the user then filters the list with a search term that yields items after the first
 * 50 and selects them.
 *
 * Without this util to merge the selection into the default list, the component will break,
 * evident by the Esc key no longer working.
 *
 * Note - selected items appear pinned at the top of the list, which is nice for seeing
 * what is currently selected, but bad when selecting items from the default list because
 * they instantly bounce to the top
 */
export const getNextListItems = (
  items: FilterOption[],
  selectedItems: FilterOptionMap,
  searchTerm: string,
): FilterOption[] => {
  if (searchTerm !== '') {
    return items;
  }

  const unselectedItems = items.filter((item) => !selectedItems.has(item.value));

  const nextItems = [...toFilterOptionArray(selectedItems), ...unselectedItems];

  return nextItems;
};

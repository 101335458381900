import { useFeatureFlags } from '@/shared/providers/FeatureFlagProvider';
import { useLocation } from 'react-router';
import { AccessContext } from '@invenco/common-interface/auth';
import { match } from 'path-to-regexp';
import { useAuthToken } from './useAuthToken';

// Note changing this list during local dev will cause HMR to do crazy thing (you'll see errors in
// the console about missing nodes when removing child).
// TODO: After you change the list, reload the page!
const EnabledRoutes = [
  // Platform Context
  '/dashboard', // to prevent redirect flicker
  '/accounts',
  '/accounts/:id',
  '/locations',
  '/locations/:id',
  '/shipping/carriers',
  '/shipping/carriers/:id',
  '/shipping/carrier_services',
  '/shipping/carrier_services/:id',
  '/shipping/prices',
  '/shipping/prices/:id',
  '/shipping/shipments',
  // Dev Tools
  '/dev-config',
  '/features',
];

export function useNewUiFeatureFlag() {
  const { getFeatureFlag } = useFeatureFlags();
  const { accessContext } = useAuthToken();
  const location = useLocation();

  const isFlagEnabled = getFeatureFlag('new-ui');

  // we also include the root path to prevent redirection flicker
  const isIncludedPath =
    location.pathname === '/' ||
    EnabledRoutes.some((path) => {
      const matcher = match(path, { decode: decodeURIComponent });
      return matcher(location.pathname);
    });

  // We can't determine this if auth0 is still loading the context.
  // Doing it this way means no flicker a new UI context upon refresh, but a flicker an old UI context.
  // Also: isLoading is not reliable for accessContext, so we just check if it's undefined.
  const isExcludedContext = accessContext !== undefined && accessContext !== AccessContext.PLATFORM;

  return isFlagEnabled && isIncludedPath && !isExcludedContext;
}

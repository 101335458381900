import * as z from 'zod';
import { CreateWarehouseTenantDTO } from '@/gateways/types';
import { Result } from '@/shared/helpers/Result';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSelect, FormTextField } from '@/components-shad/form';
import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@/gateways/GatewayProvider';
import { ComboboxItem } from '@/components-shad/ui/combobox';

const schema = z.object({
  warehouseId: z.string(),
  tenantName: z.string().nonempty(),
  tenantCode: z.string().nonempty(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  accountId: string;
  addToWarehouse: (data: {
    warehouseId: string;
    tenant: CreateWarehouseTenantDTO;
  }) => Promise<Result>;
};

export function AddTenantDialog({ accountId, addToWarehouse }: Props) {
  const { warehousingGateway } = useGateways();
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { data: warehouses, isLoading } = useQuery({
    queryKey: ['warehouses'],
    queryFn: ({ signal }) => warehousingGateway.getWarehouses(undefined, { signal }),
    select: (data) => data.items,
  });

  const onSubmit = (data: FormData) =>
    addToWarehouse({
      warehouseId: data.warehouseId,
      tenant: {
        tenantAccountId: accountId,
        tenantName: data.tenantName,
        tenantCode: data.tenantCode,
        warehouseTenantOptions: {},
      },
    });

  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={onSubmit}
      title="Invite to warehouse"
      submitLabel="Invite"
    >
      <FormSelect
        control={control}
        name="warehouseId"
        label="Warehouse"
        items={warehouses}
        isLoading={isLoading}
      >
        {(warehouse) => <ComboboxItem>{warehouse.name}</ComboboxItem>}
      </FormSelect>
      <FormTextField control={control} name="tenantName" label="Tenant name" />
      <FormTextField control={control} name="tenantCode" label="Tenant code" />
    </FormDialog>
  );
}

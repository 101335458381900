import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { ShippingMethodDTO } from '@invenco/common-interface/shipping';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormDialog } from '../../../../../../components-shad/form-dialog/FormDialog';
import { FormSelect } from '../../../../../../components-shad/form';
import { SelectItem } from '../../../../../../components-shad/ui/select';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import { useGatewayMutation, useQueryWithInput } from '../../../../../../shared/hooks/queries';
import { Result } from '../../../../../../shared/helpers/Result';
import { Alert, AlertDescription, AlertTitle } from '../../../../../../components-shad/ui/alert';

const formSchema = z.object({
  warehouseShippingMethodId: z.string().nonempty(),
});

type ShippingMethodFormData = z.infer<typeof formSchema>;

type Props = {
  /**
   * Shipping methods that are already in use at the managed location
   */
  existingShippingMethods: ShippingMethodDTO[];
  /**
   * Location's Account ID
   */
  accountId: string;
  /**
   * ID of the managed location
   */
  locationId: string;
  /**
   * ID of the Warehouse that contains this location
   */
  warehouseLocationId: string;
};

export function ManagedShippingMethodDialog({
  existingShippingMethods,
  accountId,
  locationId,
  warehouseLocationId,
}: Props) {
  const { shippingGateway } = useGateways();

  /**
   * Get all possible shipping methods that belong to the containing Warehouse
   */
  const { data: allWarehouseShippingMethods, isLoading: isLoadingWarehouseShippingMethods } =
    useQueryWithInput({
      parentKey: 'shippingMethods',
      input: { locationId: warehouseLocationId },
      query: (input, { signal }) => shippingGateway.getShippingMethods(input, { signal }),
    });

  /**
   * Shipping methods offered by the warehouse, that may be added as a
   * managed location shipping method. These are the warehouse shipping
   * methods that are not yet in useby the managed location
   */
  const selectableShippingMethods = useMemo(() => {
    const existingIds = new Set(
      existingShippingMethods.map(({ warehouseShippingMethodId }) => warehouseShippingMethodId),
    );

    return (
      allWarehouseShippingMethods?.items.filter(
        (method) => !method.id || !existingIds.has(method.id),
      ) ?? []
    );
  }, [allWarehouseShippingMethods, existingShippingMethods]);

  const { mutate: addManagedShippingMethod } = useGatewayMutation({
    mutationFn: async (warehouseShippingMethod: ShippingMethodDTO) => {
      // sanity check
      if (warehouseShippingMethod.locationId !== warehouseLocationId) {
        throw new Error('Invalid warehouse shipping method provided, location ids do not match.');
      }
      return shippingGateway.createShippingMethod({
        name: warehouseShippingMethod.name,
        accountId,
        locationId,
        warehouseShippingMethodId: warehouseShippingMethod.id,
        carrierServiceId: warehouseShippingMethod.carrierServiceId,
        bookingCarrierAccountId: warehouseShippingMethod.bookingCarrierAccountId,
        ratesCarrierAccountId: warehouseShippingMethod.ratesCarrierAccountId,
      });
    },
    linkedQuery: ['shippingMethods', { locationId }],
    linkResponseToQuery: false,
    successMessage: 'Managed shipping method added',
  });

  const { handleSubmit, control } = useForm<ShippingMethodFormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (form: ShippingMethodFormData): Promise<Result<ShippingMethodDTO>> => {
    const selectedShippingMethod = selectableShippingMethods.find(
      (selectableShippingMethod) => form.warehouseShippingMethodId === selectableShippingMethod.id,
    );

    if (!selectedShippingMethod) {
      // This is mostly to satisfy TS.
      // It should be impossible that the selected ID does not
      // exist in the FormSelect items
      throw new Error(
        `Could not find warehouse shipping method with id ${form.warehouseShippingMethodId}`,
      );
    }

    return addManagedShippingMethod(selectedShippingMethod);
  };

  const areAllShippingMethodsAdded =
    selectableShippingMethods.length === 0 && !isLoadingWarehouseShippingMethods;

  return (
    <FormDialog
      title="Add managed shipping method"
      description="Make the warehouse's shipping method(s) available to this location"
      form={{ handleSubmit }}
      onSubmit={onSubmit}
      isSubmitDisabled={areAllShippingMethodsAdded}
    >
      {areAllShippingMethodsAdded && (
        <Alert>
          <AlertTitle>All shipping methods have been added</AlertTitle>
          <AlertDescription>
            This location is already using every available warehouse shipping method.
          </AlertDescription>
        </Alert>
      )}

      <FormSelect
        label="Warehouse Shipping Method"
        control={control}
        name="warehouseShippingMethodId"
        items={selectableShippingMethods}
        isLoading={isLoadingWarehouseShippingMethods}
      >
        {(item) => <SelectItem>{item.name}</SelectItem>}
      </FormSelect>
    </FormDialog>
  );
}

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { COMMIT_SHA1, DATADOG_APP_ID, DATADOG_CLIENT_TOKEN, DEPLOYMENT_STAGE } from 'env';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@/shared/providers/ThemeProvider';
import { Toaster } from '@/components-shad/ui/sonner';
import { GatewayProvider } from './gateways/GatewayProvider';
import { Home } from './pages/home';
import { getLogger } from './shared/logger/Logger';
import { Auth0Provider } from './shared/providers/Auth0Provider';
import { MessagesProvider } from './shared/providers/MessagesProvider';
import { FeatureFlagProvider } from './shared/providers/FeatureFlagProvider';
import { LocalStorageFeatureFlagGateway } from './gateways/impl/LocalStorageFeatureFlagGateway';
import { FeatureFlagConfigProvider } from './shared/providers/FeatureFlagConfigProvider';
import { RouterProvider } from './shared/providers/RouterProvider';

import './styles/index.css';
import './styles/ant/index.less'; // TODO LYNK-3372 - remove this once Ant is removed

const logger = getLogger();

if (DATADOG_CLIENT_TOKEN && DATADOG_APP_ID && DEPLOYMENT_STAGE) {
  const version = `${COMMIT_SHA1}-${DEPLOYMENT_STAGE.toLowerCase()}`;

  datadogRum.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    applicationId: DATADOG_APP_ID,
    site: 'us3.datadoghq.com',
    version,
    service: 'lynk-frontend',
    env: DEPLOYMENT_STAGE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    allowedTracingUrls: [/https:\/\/app\..*lynksupplychain\.com/],
  });

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'lynk-frontend',
    env: DEPLOYMENT_STAGE,
    version,
    forwardConsoleLogs: ['warn', 'error'],
    forwardReports: 'all',
  });
  logger.info(`Datadog RUM and Logs initialized for version ${version}`);
} else {
  logger.warn('Datadog RUM not initialized');
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const localStorageFeatureFlagGateway = new LocalStorageFeatureFlagGateway({
  storage: window.localStorage,
});

function App() {
  // careful with provider order - some depend on others
  // Auth0Provider depends on Router
  // GatewayProvider depends on Auth0Provider
  return (
    <FeatureFlagConfigProvider featureFlagGateway={localStorageFeatureFlagGateway}>
      <FeatureFlagProvider featureFlagGateway={localStorageFeatureFlagGateway}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider>
            <Auth0Provider>
              <ThemeProvider>
                <MessagesProvider>
                  <GatewayProvider>
                    <Toaster expand />
                    <Home />
                  </GatewayProvider>
                </MessagesProvider>
              </ThemeProvider>
            </Auth0Provider>
          </RouterProvider>
        </QueryClientProvider>
      </FeatureFlagProvider>
    </FeatureFlagConfigProvider>
  );
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(<App />);

import {
  ShippingMethodStatus,
  ShippingMethodType,
} from '@invenco/common-domain/enums/ShippingMethod';
import {
  CarrierDTO,
  CarrierServiceDTO,
  ShipmentDTO,
  ShippingMethodDTO,
  ShippingPriceDTO,
  CarrierAccountDTO,
  ShipmentStatus,
  ShippingQuoteRequestDTO,
  QuoteDTO,
} from '@invenco/common-interface/shipping';
import snakeCase from 'lodash/snakeCase';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse, SortDirection } from '../types';

export type ShipmentSortBy = 'createdAt' | 'shippedAt';

export class AxiosShippingGateway extends BaseAxiosGateway {
  name: GatewayName = 'shipping';

  async getShipments(
    query?: {
      take?: number;
      cursor?: string;
      accountId?: string;
      search?: string;
      statuses?: ShipmentStatus[];
      shippableIds?: string[];
      sortBy?: ShipmentSortBy;
      sortDirection?: SortDirection;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<ShipmentDTO>> {
    const { data } = await this.httpGet('/v1/shipments', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        q: query?.search,
        statuses: query?.statuses?.join(','),
        shippableIds: query?.shippableIds?.join(','),
        sortBy: query?.sortBy ? snakeCase(query.sortBy) : undefined,
        sortDirection: query?.sortDirection,
      },
      ...options,
    });
    return { ...data, items: data.shipments };
  }

  async getCarriers(
    query?: {
      take?: number;
      cursor?: string;
      include?: { carrierServices?: boolean };
      q?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<CarrierDTO>> {
    const { data } = await this.httpGet('/v1/carriers', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        includes: query?.include?.carrierServices ? 'carrier_services' : undefined,
        q: query?.q,
      },
      ...options,
    });
    return { ...data, items: data.carriers };
  }

  async getAvailableCarrierServicesForAccount(
    options?: QueryRequestOptions,
  ): Promise<CarrierServiceDTO[]> {
    const { data } = await this.httpGet('/v1/carrier_services/available', options);
    return data.carrierServices;
  }

  async getCarrierServices(
    query?: {
      take?: number;
      cursor?: string;
      codes?: string[];
      q?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<CarrierServiceDTO>> {
    const { data } = await this.httpGet('/v1/carrier_services', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        codes: query?.codes?.join(','),
        q: query?.q,
      },
      ...options,
    });
    return { ...data, items: data.carrierServices };
  }

  async createCarrier(carrier: Partial<CarrierDTO>): Promise<CarrierDTO> {
    const { data } = await this.httpPost('/v1/carriers', { data: { carrier } });
    return data.carrier;
  }

  async createCarrierService(
    carrierId: string,
    carrierService: Partial<CarrierServiceDTO>,
  ): Promise<CarrierServiceDTO> {
    const { data } = await this.httpPost(`/v1/carriers/${carrierId}/carrier_services`, {
      data: { carrierService },
    });
    return data.carrierService;
  }

  async updateCarrierService(
    id: string,
    carrierService: Partial<CarrierServiceDTO>,
  ): Promise<CarrierServiceDTO> {
    const { data } = await this.httpPatch(`/v1/carrier_services/${id}`, {
      data: { carrierService },
    });
    return data.carrierService;
  }

  async getShippingMethod(id: string): Promise<ShippingMethodDTO> {
    const { data } = await this.httpGet(`/v1/shipping_methods/${id}`);
    return data.shippingMethod;
  }

  async getShippingMethods(
    query?: {
      take?: number;
      cursor?: string;
      accountId?: string;
      locationId?: string;
      carrierServiceId?: string;
      warehouseShippingMethodId?: string;
      type?: ShippingMethodType;
      status?: ShippingMethodStatus;
      searchText?: string;
      carrierAccountId?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<ShippingMethodDTO>> {
    const { data } = await this.httpGet('/v1/shipping_methods', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        accountId: query?.accountId,
        locationId: query?.locationId,
        carrierAccountId: query?.carrierAccountId,
        carrierServiceId: query?.carrierServiceId,
        warehouseShippingMethodId: query?.warehouseShippingMethodId,
        type: query?.type,
        status: query?.status,
        q: query?.searchText,
      },
      ...options,
    });
    return { ...data, items: data.shippingMethods };
  }

  async createShippingMethod(
    shippingMethod: Partial<ShippingMethodDTO>,
  ): Promise<ShippingMethodDTO> {
    const { data } = await this.httpPost('/v1/shipping_methods', { data: { shippingMethod } });
    return data.shippingMethod;
  }

  async getShippingPrice(id: string, options?: QueryRequestOptions): Promise<ShippingPriceDTO> {
    const { data } = await this.httpGet(`/v1/shipping_prices/${id}`, options);
    return data.shippingPrice;
  }

  async getShippingPrices(
    query?: {
      take?: number;
      cursor?: string;
      accountId?: string;
      search?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<ShippingPriceDTO>> {
    const { data } = await this.httpGet('/v1/shipping_prices', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        accountId: query?.accountId,
        q: query?.search,
      },
      ...options,
    });
    return { ...data, items: data.shippingPrices };
  }

  async createShippingPrice(shippingPrice: Partial<ShippingPriceDTO>): Promise<ShippingPriceDTO> {
    const { data } = await this.httpPost('/v1/shipping_prices', { data: { shippingPrice } });
    return data.shippingPrice;
  }

  async updateShippingPrice(
    id: string,
    shippingPrice: Partial<ShippingPriceDTO>,
  ): Promise<ShippingPriceDTO> {
    const { data } = await this.httpPatch(`/v1/shipping_prices/${id}`, { data: { shippingPrice } });
    return data.shippingPrice;
  }

  async recalculateShipmentCost(shipmentId: string): Promise<ShipmentDTO> {
    const { data } = await this.httpPost(`/v1/shipments/${shipmentId}/recalculate_costs`);
    return data.shipment;
  }

  async setShipmentCost(shipment: Partial<ShipmentDTO>): Promise<ShipmentDTO> {
    const shipmentCost = { cost: shipment.cost, currency: shipment.currency };
    const { data } = await this.httpPost(`/v1/shipments/${shipment.id}/set_cost`, {
      data: { shipmentCost },
    });
    return data.shipment;
  }

  async getCarrierAccounts(
    query?: {
      accountId?: string;
      carrierIds?: string[];
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<CarrierAccountDTO>> {
    const { data } = await this.httpGet('/v1/carrier_accounts', {
      params: {
        accountId: query?.accountId,
        carrierIds: query?.carrierIds?.join(','),
      },
      ...options,
    });
    return { ...data, items: data.carrierAccounts };
  }

  async getShippingQuote(request: ShippingQuoteRequestDTO): Promise<QuoteDTO> {
    const { data } = await this.httpPost<{ shippingQuote: QuoteDTO }>('/v1/shipments/quote', {
      data: request,
    });
    return data.shippingQuote;
  }
}

import { ShippingProfileDTO } from '@invenco/common-interface/sales';
import { useQuery } from '@tanstack/react-query';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData } from '../../../../shared/types';

type Models = {
  shippingProfiles: ShippingProfileDTO[];
  isLoading: boolean;
  hasError: boolean;
};

export function useShippingSettingsComponent(): ComponentData<Models> {
  const { salesGateway } = useGateways();
  const {
    data,
    isLoading,
    isError: hasError,
  } = useQuery({
    queryKey: ['shippingProfiles'],
    queryFn: () => salesGateway.getShippingProfiles(),
  });
  return {
    models: { shippingProfiles: data?.items ?? [], isLoading, hasError },
  };
}

import type { Selection } from 'react-aria-components';
import type { FilterOption, FilterOptionMap } from '../types';
import { toFilterOptionMap } from './mappers';

/**
 * Take the currently visible items, a list of *all* selected items (visible or not)
 * and the items that were selected from the most recent change and sync them by adding
 * or deleting selections
 */
export const getNextSelectedItemsForMultiSelect = (
  /**
   * items that are visible in the list
   */
  items: FilterOption[],
  /**
   * all currently selected items
   * - if `items` is filtered by search term, `currentSelectedItems` may
   *   contain entries that are *not* in `items`
   */
  currentSelectedItems: FilterOptionMap,
  /**
   * keys of `items` that have become selected in the most
   * recent change event
   */
  nextSelectedKeys: Selection,
): FilterOptionMap => {
  const nextSelectedItems = new Map(currentSelectedItems);

  items.forEach((item) => {
    const isSelected = nextSelectedKeys === 'all' || nextSelectedKeys.has(item.value);

    const wasPreviouslySelected = currentSelectedItems.has(item.value);

    if (wasPreviouslySelected && !isSelected) {
      nextSelectedItems.delete(item.value);
    }

    if (isSelected) {
      nextSelectedItems.set(item.value, item.displayName);
    }
  });

  return nextSelectedItems;
};

/**
 * Take the currently visible items, and the items that were selected from the most recent change
 * and and return the newly selected item or an empty map when an item was de-selected
 */
export const getNextSelectedItemsForSingleSelect = (
  /**
   * items that are visible in the list
   */
  items: FilterOption[],
  /**
   * keys of `items` that have become selected in the most
   * recent change event - should only be 0 or 1
   */
  nextSelectedKeys: Selection,
): FilterOptionMap => {
  if (nextSelectedKeys === 'all') {
    // eslint-disable-next-line no-console
    console.error('selected keys returned "all" when in single select mode');
    return new Map(); // TODO: Error handling
  }

  const nextSelectedKeysArray = Array.from(nextSelectedKeys);

  if (nextSelectedKeysArray.length === 0) {
    return new Map(); // item was de-selected
  }

  return toFilterOptionMap(items.filter((item) => item.value === nextSelectedKeysArray[0]));
};

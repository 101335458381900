import type { CarrierDTO, CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { CarrierServiceCategory } from '@invenco/common-interface/shipping';
import type {
  ShippingProfileCarrierServiceDTO,
  ShippingProfileDTO,
} from '@invenco/common-interface/sales';
import { FulfilmentServiceType, IncoTerms } from '@invenco/common-interface/sales';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useGateways } from 'gateways/GatewayProvider';
import { cleanTextDescription } from 'shared/helpers';
import { ComponentData } from '../../../../shared/types';
import { BreadCrumb } from '../../../../components/header';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';
import { Result } from '../../../../shared/helpers/Result';

type Models = {
  id?: string;
  isNew: boolean;
  isSaving: boolean;
  isLoading: boolean;
  isLoadingServices: boolean;
  canSave: boolean;
  shippingProfile: Partial<ShippingProfileDTO>;
  carrierServiceCategory: string;
  carriersById: Record<string, CarrierDTO>;
  availableCarrierServicesByCategoryAndCarrierId: Partial<
    Record<CarrierServiceCategory, Record<string, CarrierServiceDTO[]>>
  >;
  selectedCarrierServicesByCarrierId: Record<string, CarrierServiceDTO[]>;
  availableCategories: CarrierServiceCategory[];
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  refresh: () => Promise<void>;
  updateProfile: (data: Partial<ShippingProfileDTO>) => void;
  save: () => Promise<Result>;
  cancel: () => void;
  deleteProfile: () => Promise<void>;
  addShippingDescription: (description: string) => void;
  removeShippingDescription: (index: number) => void;
  changeCarrierServiceCategory: (category: CarrierServiceCategory) => void;
  selectCarrierServices: (serviceIds: string[]) => void;
  deselectCarrierServices: (serviceIds: string[]) => void;
};

type CategorySelection = keyof typeof CarrierServiceCategory | 'custom';

const DEFAULT_NEW_CATEGORY = CarrierServiceCategory.STANDARD;
const DEFAULT_NEW_DATA: Partial<ShippingProfileDTO> = {
  fulfillmentServiceType: FulfilmentServiceType.STANDARD,
  signatureRequired: false,
  incoTerms: IncoTerms.DDU,
};

export const orderedCategories: CarrierServiceCategory[] = [
  CarrierServiceCategory.STANDARD,
  CarrierServiceCategory.EXPRESS,
  CarrierServiceCategory.SAME_DAY,
  CarrierServiceCategory.PICK_UP,
];

const serviceToProfileService = ({
  id,
  code,
  name,
}: CarrierServiceDTO): ShippingProfileCarrierServiceDTO => ({
  id: id ?? '',
  code,
  name,
});

export function useShippingProfilePage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const isNew = id === 'new';

  const { salesGateway, shippingGateway } = useGateways();
  const navigate = useNavigate();
  const [hasChanges, setHasChanges] = useState(false);
  const [shippingProfile, setShippingProfile] = useState<Partial<ShippingProfileDTO>>({
    ...DEFAULT_NEW_DATA,
  });
  const [carrierServiceCategory, setCarrierServiceCategory] =
    useState<CategorySelection>(DEFAULT_NEW_CATEGORY);
  const [customCarrierServices, setCustomCarrierServices] = useState<
    ShippingProfileCarrierServiceDTO[] | null
  >(null);

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'shippingProfiles',
    id,
    isNew,
    query: (fetchId, { signal }) => salesGateway.getShippingProfile(fetchId, { signal }),
  });

  const {
    data: carriersById,
    isLoading: isLoadingCarriers,
    refetch: refetchCarriers,
  } = useQuery({
    queryKey: ['carriers'],
    queryFn: ({ signal }) => shippingGateway.getCarriers(undefined, { signal }),
    select: ({ items }) =>
      items.reduce(
        (acc, carrier) => {
          if (carrier.id) acc[carrier.id] = carrier;
          return acc;
        },
        {} as Record<string, CarrierDTO>,
      ),
  });

  const {
    data: availableCarrierServicesById,
    isLoading: isLoadingCarrierServices,
    refetch: refetchCarrierServices,
  } = useQuery({
    queryKey: ['availableCarrierServices'],
    queryFn: ({ signal }) => shippingGateway.getAvailableCarrierServicesForAccount({ signal }),
    select: (carrierServices) =>
      carrierServices.reduce(
        (acc, service) => {
          if (service.id) acc[service.id] = service;
          return acc;
        },
        {} as Record<string, CarrierServiceDTO>,
      ),
  });

  const availableCarrierServicesByCategoryAndCarrierId = useMemo(() => {
    const obj: Partial<Record<CarrierServiceCategory, Record<string, CarrierServiceDTO[]>>> = {};

    if (availableCarrierServicesById) {
      Object.values(availableCarrierServicesById).forEach((service) => {
        if (!obj[service.category]) obj[service.category] = {};
        const carrierServicesById = obj[service.category]!;
        if (!carrierServicesById[service.carrierId]) {
          carrierServicesById[service.carrierId] = [];
        }
        carrierServicesById[service.carrierId].push(service);
      });
    }

    return obj;
  }, [availableCarrierServicesById]);

  const availableCategories = useMemo(
    () =>
      orderedCategories.filter(
        (category) => availableCarrierServicesByCategoryAndCarrierId[category],
      ),
    [availableCarrierServicesByCategoryAndCarrierId],
  );

  const selectedCarrierServicesByCarrierId = useMemo(() => {
    const obj: Record<string, CarrierServiceDTO[]> = {};
    if (carrierServiceCategory === 'custom') return obj;

    const carrierServicesByCarrierId =
      availableCarrierServicesByCategoryAndCarrierId[carrierServiceCategory];

    if (carrierServicesByCarrierId) {
      Object.entries(carrierServicesByCarrierId).forEach(([carrierId, services]) => {
        const filtered = services.filter((service) => !service.shippingProfileCustomOnly);
        if (filtered.length) obj[carrierId] = filtered;
      });
    }
    return obj;
  }, [carrierServiceCategory, availableCarrierServicesByCategoryAndCarrierId]);

  const existingName = data?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/settings', title: 'Settings' },
      { url: '/settings/shipping', title: 'Shipping' },
      { url: '/settings/shipping', title: 'Profiles' },
      {
        url: `/settings/shipping/profile/${id}`,
        title: isNew ? 'New' : existingName,
        loading: isLoading,
      },
    ],
    [id, isNew, isLoading, existingName],
  );

  useEffect(() => {
    setShippingProfile(data ?? { ...DEFAULT_NEW_DATA });
    setHasChanges(false);
  }, [data]);

  useEffect(() => {
    if (isNew && availableCarrierServicesById && !isLoadingCarriers) {
      // find the first category which has available services
      const newCategory = availableCategories[0];
      const carrierServices = Object.values(availableCarrierServicesById)
        .filter((service) => service.category === newCategory)
        .map(serviceToProfileService);

      // default to custom if we really don't have any available categories
      setCarrierServiceCategory(newCategory ?? 'custom');
      // don't want shippingProfile in the deps list, so using a callback
      setShippingProfile((current) => ({ ...current, carrierServices }));
    }
  }, [isNew, availableCategories, availableCarrierServicesById, isLoadingCarriers]);

  // when loading a new shipping profile, try to determine if a category selection might apply to it
  useEffect(() => {
    if (data && availableCarrierServicesByCategoryAndCarrierId && !isLoadingCarriers) {
      const selectedServiceIds = new Set(data.carrierServices?.map((service) => service.id) ?? []);

      /* eslint-disable-next-line no-restricted-syntax */
      for (const [category, servicesByCarrierId] of Object.entries(
        availableCarrierServicesByCategoryAndCarrierId,
      )) {
        // if this profile has the same set of services that this category does, the category should be selected
        const categoryServices = Object.values(servicesByCarrierId).flat();
        if (
          selectedServiceIds.size === categoryServices.length &&
          categoryServices.every((service) => service.id && selectedServiceIds.has(service.id))
        ) {
          setCarrierServiceCategory(CarrierServiceCategory[category]);
          return;
        }
      }

      // if no match was found, it is a custom selection
      setCarrierServiceCategory('custom');
      setCustomCarrierServices(data.carrierServices ?? []);
    }
  }, [data, availableCarrierServicesByCategoryAndCarrierId, isLoadingCarriers]);

  const getRequestDTO = () => ({
    id: isNew ? undefined : id,
    name: shippingProfile?.name,
    shippingDescriptions: shippingProfile?.shippingDescriptions,
    carrierServices: shippingProfile?.carrierServices?.filter(
      (cs) => availableCarrierServicesById?.[cs.id],
    ),
    fulfillmentServiceType: shippingProfile?.fulfillmentServiceType,
    signatureRequired: shippingProfile?.signatureRequired,
    incoTerms: shippingProfile?.incoTerms,
  });

  const canSave = Boolean((isNew || hasChanges) && shippingProfile.name);

  const refresh = async () => {
    await Promise.all([refetch(), refetchCarriers(), refetchCarrierServices()]);
  };

  const updateProfile = (updateData: Partial<ShippingProfileDTO>) => {
    setShippingProfile({ ...shippingProfile, ...updateData });
    setHasChanges(true);
  };

  const addShippingDescription = (description: string) => {
    updateProfile({
      shippingDescriptions: [
        ...(shippingProfile.shippingDescriptions ?? []),
        cleanTextDescription(description),
      ],
    });
  };

  const removeShippingDescription = (index: number) => {
    const newDescriptions = [...(shippingProfile.shippingDescriptions ?? [])];
    newDescriptions.splice(index, 1);
    updateProfile({ shippingDescriptions: newDescriptions });
  };

  const changeCarrierServiceCategory = (category: CategorySelection) => {
    setCarrierServiceCategory(category);
    if (category !== 'custom') {
      const newCarrierServices = Object.values(availableCarrierServicesById ?? {})
        .filter((service) => category === service.category && !service.shippingProfileCustomOnly)
        .map(serviceToProfileService);
      updateProfile({ carrierServices: newCarrierServices });
    } else if (customCarrierServices) {
      updateProfile({ carrierServices: customCarrierServices });
    }
  };

  const selectCarrierServices = (serviceIds: string[]) => {
    if (!availableCarrierServicesById) return;
    const existingIds = new Set(shippingProfile.carrierServices?.map((service) => service.id));
    const newCarrierServices = [
      ...(shippingProfile.carrierServices ?? []),
      ...serviceIds
        .filter((serviceId) => !existingIds.has(serviceId))
        .map((serviceId) => serviceToProfileService(availableCarrierServicesById[serviceId])),
    ];
    updateProfile({ carrierServices: newCarrierServices });
    setCustomCarrierServices(newCarrierServices);
  };

  const deselectCarrierServices = (serviceIds: string[]) => {
    const newCarrierServices = (shippingProfile.carrierServices ?? []).filter(
      (service) => !serviceIds.includes(service.id),
    );
    updateProfile({ carrierServices: newCarrierServices });
    setCustomCarrierServices(newCarrierServices);
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () => {
      const dto = getRequestDTO();
      return isNew
        ? salesGateway.createShippingProfile(dto)
        : salesGateway.updateShippingProfile(dto);
    },
    onSuccess: (dto) => {
      setHasChanges(false);
      if (isNew) {
        void navigate(`/settings/shipping/profile/${dto.id}`);
      }
    },
    linkedQuery: ['shippingProfiles', id],
    successMessage: `Shipping profile ${isNew ? 'created' : 'saved'}`,
  });

  /** Delete current shipping profile */
  const deleteProfile = async () => {
    if (id) {
      await salesGateway.deleteShippingProfile(id);
      void navigate(`/settings/shipping`);
    }
  };

  const cancel = () => navigate(`/settings/shipping`);

  return {
    models: {
      id,
      isNew,
      isSaving,
      isLoading,
      canSave,
      shippingProfile,
      breadcrumbs,
      carrierServiceCategory,
      carriersById: carriersById ?? {},
      availableCarrierServicesByCategoryAndCarrierId,
      selectedCarrierServicesByCarrierId,
      availableCategories,
      isLoadingServices: isLoadingCarriers || isLoadingCarrierServices,
    },
    operations: {
      refresh,
      updateProfile,
      save,
      cancel,
      deleteProfile,
      addShippingDescription,
      removeShippingDescription,
      changeCarrierServiceCategory,
      selectCarrierServices,
      deselectCarrierServices,
    },
  };
}

import { DetailMain, DetailSide, Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Button } from '@/components-shad/ui/button';
import { Card } from '@/components-shad/ui/card';
import { PlusIcon } from 'lucide-react';
import { DialogOverlay, DialogTrigger } from '@/components-shad/ui/dialog';
import { DetailList } from '@/components-shad/detail-list/DetailList';
import { Link } from '@/components-shad/ui/link';
import { formatDate } from '@/shared/helpers';
import { PriceBandsShad } from '@/pages/platform/shipping-prices/shipping-price-details/shad/price-bands/PriceBandsShad';
import { useShippingPriceDetailsPage } from '../useShippingPriceDetailsPage';
import { ShippingPriceDetailDialog } from './dialogs/ShippingPriceDetailDialog';
import { CarrierSelectionDialog } from './dialogs/CarrierSelectionDialog';

export function ShippingPriceDetailsShad() {
  const {
    models: {
      isNew,
      isSaving,
      isLoading,
      isLoadingAccounts,
      isLoadingCarriers,
      hasChanges,
      canSave,
      shippingPrice,
      accounts,
      account,
      location,
      carrierOptions,
      existingName,
    },
    operations: {
      refresh,
      updateDetails,
      save,
      addNewGroup,
      removeGroup,
      addBand,
      addGroupBand,
      updateBand,
      updateGroupBand,
      removeBand,
      removeGroupBand,
    },
  } = useShippingPriceDetailsPage();

  return (
    <Page>
      <Header
        title={shippingPrice.name || (isNew ? 'New shipping price' : undefined)}
        pageTitle={isNew ? 'New shipping price' : existingName}
        breadcrumbs={[{ title: 'Shipping prices', url: '/shipping/prices' }]}
        loading={isLoading}
        hasBackButton
        onRefresh={!isNew ? refresh : undefined}
        actions={
          <Button
            onPress={() => void save()}
            isDisabled={!canSave || !hasChanges}
            isPending={isSaving}
          >
            Save
          </Button>
        }
      />

      <PageContent variant="split">
        <DetailMain>
          <Card
            title="Price bands"
            count={shippingPrice.priceBands?.length ?? 0}
            description={
              "Each band's markup is applied in sequence as an additional percentage of the shipping cost. " +
              "The markup is applied to the portion of the cost between the previous band's (if any) maximum " +
              "and this band's maximum."
            }
          >
            <PriceBandsShad
              isLoading={isLoading}
              priceBands={shippingPrice.priceBands ?? []}
              addBand={addBand}
              updateBand={updateBand}
              removeBand={removeBand}
            />
          </Card>

          {shippingPrice.carrierPrices?.map((carrierPrice, groupIndex) => (
            <Card
              key={carrierPrice.carrierId}
              title={`Price bands for ${carrierPrice.carrierName}`}
              count={carrierPrice.priceBands?.length ?? 0}
              actions={
                <Button
                  size="sm"
                  variant="destructive"
                  onPress={() => removeGroup(groupIndex)}
                  aria-label={`Delete price band group for ${carrierPrice.carrierName}`}
                >
                  Delete group
                </Button>
              }
            >
              <PriceBandsShad
                isLoading={isLoading}
                priceBands={carrierPrice.priceBands ?? []}
                addBand={(band) => addGroupBand(groupIndex, band)}
                updateBand={(bandIndex, band) => updateGroupBand(groupIndex, bandIndex, band)}
                removeBand={(bandIndex) => removeGroupBand(groupIndex, bandIndex)}
              />
            </Card>
          ))}

          <div>
            <DialogTrigger>
              <Button size="sm" variant="outline" icon={<PlusIcon />}>
                Add new price band group
              </Button>
              <DialogOverlay>
                <CarrierSelectionDialog
                  isLoadingCarriers={isLoadingCarriers}
                  carriers={carrierOptions}
                  onSubmit={addNewGroup}
                />
              </DialogOverlay>
            </DialogTrigger>
          </div>
        </DetailMain>

        <DetailSide>
          <Card
            title="Details"
            actions={
              <DialogTrigger>
                <Button variant="outline" size="sm">
                  Edit
                </Button>
                <DialogOverlay>
                  <ShippingPriceDetailDialog
                    isNew={isNew}
                    shippingPrice={shippingPrice}
                    onSubmit={updateDetails}
                    accounts={accounts}
                    isLoadingAccounts={isLoadingAccounts}
                  />
                </DialogOverlay>
              </DialogTrigger>
            }
          >
            <DetailList
              isLoading={isLoading}
              items={[
                {
                  label: 'Account',
                  value: account && (
                    <Link size="inline" variant="link" href={`/accounts/${account.id}`}>
                      {account.name}
                    </Link>
                  ),
                  isLoading: isLoadingAccounts,
                },
                {
                  label: 'Location',
                  value: location && (
                    <Link size="inline" variant="link" href={`/locations/${location.id}`}>
                      {location.name}
                    </Link>
                  ),
                  isLoading: isLoadingAccounts,
                },
                {
                  label: 'Valid from',
                  value: shippingPrice.validFrom && formatDate(shippingPrice.validFrom),
                },
                {
                  label: 'Valid to',
                  value: shippingPrice.validTo && formatDate(shippingPrice.validTo),
                },
              ]}
            />
          </Card>
        </DetailSide>
      </PageContent>
    </Page>
  );
}

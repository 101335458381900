import { Form, FormInstance } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { EditableCell } from './EditableCell';
import { EditableColumn } from './types';
import { BaseRow, TableColumn } from '../list-table/types';
import { ComponentData } from '../../shared/types';

type Props<T extends BaseRow> = {
  columns: EditableColumn<T>[];
  rows: T[];
  rowKey: string;
  selectedRowKey?: string;
  onSaveRow?: (record: T, data: Record<string, any>) => Promise<any> | any;
  onCloseRow?: (record: T) => any;
  onDeleteRow?: (record: T) => Promise<any> | any;
  isRowEditable?: (record: T) => boolean;
};

type Models<T extends BaseRow> = {
  isSaving: boolean;
  isDeleting: boolean;
  processedColumns: TableColumn<T>[];
  initialValues: Record<string, any>;
  form: FormInstance;
};

type Operations = {
  save: (data: Record<string, any>) => Promise<void>;
  deleteRow: () => Promise<void>;
};

export function useEditableTableComponent<T extends BaseRow>({
  columns,
  rows,
  rowKey,
  selectedRowKey,
  onSaveRow,
  onCloseRow,
  onDeleteRow,
  isRowEditable,
}: Props<T>): ComponentData<Models<T>, Operations> {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [form] = Form.useForm();

  const selectedRow = useMemo(
    () => rows.find((row) => row[rowKey] === selectedRowKey),
    [rows, rowKey, selectedRowKey],
  );

  const processedColumns = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        render: (content: any, record: T, index: number) => (
          <EditableCell
            column={column}
            editable={column.editable !== false && (!isRowEditable || isRowEditable(record))}
            selected={selectedRowKey !== undefined && record[rowKey] === selectedRowKey}
            content={content}
            record={record}
            recordIndex={index}
          />
        ),
      })),
    [columns, rowKey, selectedRowKey, isRowEditable],
  );

  const initialValues = useMemo(
    () =>
      selectedRow
        ? Object.fromEntries(
            columns
              .filter((col) => col.editable !== false)
              .map(({ key, dataIndex, inputValue }) => [
                key,
                inputValue ? inputValue(selectedRow) : selectedRow[dataIndex || key],
              ]),
          )
        : {},
    [selectedRow, columns],
  );

  useEffect(() => {
    form.resetFields();
  }, [form, selectedRowKey]);

  const save = async (data: Record<string, any>) => {
    if (isSaving || isDeleting) return; // double save often happens otherwise
    if (!selectedRow) throw new Error(`No selected row available`);
    setIsSaving(true);
    let result;
    if (onSaveRow) result = await onSaveRow(selectedRow, data);
    setIsSaving(false);

    // only close if there is an onCloseRow callback and the result is not a failure
    if (onCloseRow && !result?.isFailure) {
      onCloseRow(selectedRow);
    }
  };

  const deleteRow = async () => {
    if (isSaving || isDeleting) return;
    if (!selectedRow) throw new Error(`No selected row available`);
    setIsDeleting(true);
    let result;
    if (onDeleteRow) result = await onDeleteRow(selectedRow);
    setIsDeleting(false);

    // only close if there is an onCloseRow callback and the result is not a failure
    if (onCloseRow && !result?.isFailure) {
      onCloseRow(selectedRow);
    }
  };

  return {
    models: { isSaving, isDeleting, processedColumns, form, initialValues },
    operations: { save, deleteRow },
  };
}

const ISO_DATE_REGEXP = /^(\d{4,})-(\d{2})-(\d{2})$/;

export type CalendarDateArithmeticInput = {
  days?: number;
  months?: number;
  years?: number;
  weeks?: number;
};

const buildLocalDate = (
  year: number | string,
  month: number | string,
  date: number | string,
): Date =>
  new Date(
    typeof year === 'string' ? parseInt(year, 10) : year,
    (typeof month === 'string' ? parseInt(month, 10) : month) - 1,
    typeof date === 'string' ? parseInt(date, 10) : date,
  );

export class LocalCalendarDate {
  public static fromISODate(isoDate: string): LocalCalendarDate {
    const dateMatch = isoDate.match(ISO_DATE_REGEXP);
    if (!dateMatch) {
      return new LocalCalendarDate(null, false, `Invalid ISO date format, should be YYYY-MM-DD`);
    }
    const [, yearString, monthString, dateString] = dateMatch;
    const localDate = buildLocalDate(yearString, monthString, dateString);
    if (!Number.isFinite(localDate.getTime())) {
      return new LocalCalendarDate(
        null,
        false,
        `Invalid date: ${localDate.toISOString()} is not a valid date string`,
      );
    }
    return new LocalCalendarDate(localDate);
  }

  public static fromJSDate(jsDate: Date): LocalCalendarDate {
    if (!Number.isFinite(jsDate.getTime())) {
      return new LocalCalendarDate(null, false, `Invalid JS date: ${jsDate}`);
    }
    return new LocalCalendarDate(jsDate);
  }

  public static create(year: number, month: number, day: number): LocalCalendarDate {
    if (!Number.isSafeInteger(year)) {
      return new LocalCalendarDate(null, false, `Year is not a safe integer: ${year}`);
    }
    if (!Number.isSafeInteger(month)) {
      return new LocalCalendarDate(null, false, `Month is not a safe integer: ${month}`);
    }
    if (!Number.isSafeInteger(day)) {
      return new LocalCalendarDate(null, false, `Day is not a safe integer: ${day}`);
    }
    const localDate = buildLocalDate(year, month, day);
    if (!Number.isFinite(localDate.getTime())) {
      return new LocalCalendarDate(
        null,
        false,
        `Invalid date: ${year}-${month}-${day} is not a valid date`,
      );
    }
    return new LocalCalendarDate(localDate);
  }

  private constructor(
    private readonly localDate: Date | null,
    readonly isValid = true,
    readonly invalidReason?: string,
  ) {
    Object.freeze(this);
  }

  public get year(): number {
    return this.localDate?.getFullYear() ?? NaN;
  }

  public get month(): number {
    return this.localDate ? this.localDate.getMonth() + 1 : NaN;
  }

  public get day(): number {
    return this.localDate?.getDate() ?? NaN;
  }

  private performComparisonOp(op: 'gt' | 'gte' | 'lt' | 'lte', other: LocalCalendarDate): boolean {
    if (!this.isValid) throw new Error(`Invalid date: ${this.invalidReason}`);
    if (!other.isValid) throw new Error(`Invalid date: ${other.invalidReason}`);

    const thisTime = this.localDate?.getTime()!;
    const otherTime = other.localDate?.getTime()!;

    switch (op) {
      case 'gt':
        return thisTime > otherTime;
      case 'gte':
        return thisTime >= otherTime;
      case 'lt':
        return thisTime < otherTime;
      case 'lte':
        return thisTime <= otherTime;
      default:
        throw new Error(`Invalid operation: ${op}`);
    }
  }

  public gt(other: LocalCalendarDate): boolean {
    return this.performComparisonOp('gt', other);
  }

  public gte(other: LocalCalendarDate): boolean {
    return this.performComparisonOp('gte', other);
  }

  public lt(other: LocalCalendarDate): boolean {
    return this.performComparisonOp('lt', other);
  }

  public lte(other: LocalCalendarDate): boolean {
    return this.performComparisonOp('lte', other);
  }

  public toJSDate(): Date {
    if (!this.isValid) return new Date(NaN);
    return this.localDate!;
  }

  public toISODate(): string {
    if (!this.isValid) return 'Invalid Date';
    return `${this.year.toString().padStart(4, '0')}-${this.month.toString().padStart(2, '0')}-${this.day
      .toString()
      .padStart(2, '0')}`;
  }

  public toString(): string {
    return this.toISODate();
  }

  public equals(other: LocalCalendarDate): boolean {
    if (!this.isValid) return false;
    if (!other.isValid) return false;
    return this.localDate?.getTime() === other.localDate?.getTime();
  }
}

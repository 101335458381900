import type { ShippingProfileCarrierServiceDTO } from '@invenco/common-interface/sales';
import type { CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { useMemo } from 'react';
import { ComponentData } from '../../../../../shared/types';

export enum SelectionState {
  NONE,
  PARTIAL,
  ALL,
}

type Props = {
  servicesByCarrierId: Record<string, CarrierServiceDTO[]>;
  selectedCarrierServices?: ShippingProfileCarrierServiceDTO[];
  onSelected?: (carrierServiceIds: string[]) => void;
  onDeselected?: (carrierServiceIds: string[]) => void;
};

type Models = {
  allSelectionState: SelectionState;
  carrierSelectionState: Record<string, SelectionState>;
  selectedIdSet: Set<string>;
};

type Operations = {
  changeSelection: (serviceId: string, value: boolean) => void;
  changeCarrierSelection: (carrierId: string, value: boolean) => void;
  changeAllSelection: (value: boolean) => void;
};

const getSelectionState = (serviceIds: string[], selectedIdSet: Set<string>): SelectionState => {
  const numSelected = serviceIds.filter((id) => selectedIdSet.has(id)).length;
  if (numSelected === serviceIds.length) return SelectionState.ALL;
  return numSelected > 0 ? SelectionState.PARTIAL : SelectionState.NONE;
};

export function useCarrierListComponent({
  servicesByCarrierId,
  selectedCarrierServices,
  onSelected,
  onDeselected,
}: Props): ComponentData<Models, Operations> {
  const allServiceIds: string[] = useMemo(
    () =>
      Object.values(servicesByCarrierId ?? {})
        .flat()
        .map(({ id }) => id)
        .filter((id): id is string => !!id), // id is optional in this DTO
    [servicesByCarrierId],
  );
  const serviceIdsByCarrierId: Record<string, string[]> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(servicesByCarrierId ?? {}).map(([carrierId, services]) => [
          carrierId,
          services.map(({ id }) => id).filter((id): id is string => !!id),
        ]),
      ),
    [servicesByCarrierId],
  );

  const selectedIdSet = useMemo(
    () => new Set(selectedCarrierServices?.map(({ id }) => id)),
    [selectedCarrierServices],
  );
  const carrierSelectionState = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(serviceIdsByCarrierId).map(([carrierId, serviceIds]) => [
          carrierId,
          getSelectionState(serviceIds, selectedIdSet),
        ]),
      ),
    [selectedIdSet, serviceIdsByCarrierId],
  );

  const changeSelection = (serviceId: string, value: boolean) => {
    const callback = value ? onSelected : onDeselected;
    if (callback) callback([serviceId]);
  };

  const changeCarrierSelection = (carrierId: string, value: boolean) => {
    const callback = value ? onSelected : onDeselected;
    if (callback) callback(serviceIdsByCarrierId[carrierId]);
  };

  const changeAllSelection = (value: boolean) => {
    const callback = value ? onSelected : onDeselected;
    if (callback) callback(allServiceIds);
  };

  return {
    models: {
      allSelectionState: getSelectionState(allServiceIds, selectedIdSet),
      carrierSelectionState,
      selectedIdSet,
    },
    operations: {
      changeSelection,
      changeCarrierSelection,
      changeAllSelection,
    },
  };
}

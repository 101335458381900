import * as z from 'zod';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField, getFormDefaults } from '@/components-shad/form';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';

const schema = z.object({
  name: z.string().nonempty(),
  code: z.string().nonempty(),
  primaryName: z.string().nonempty(),
  primaryEmail: z.string().nonempty().email(),
  primaryPhone: z.string().nonempty(),
  externalId: z.string().nullish(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  isNew: boolean;
  account: Partial<AccountDTO>;
  onSubmit: (data: Partial<AccountDTO>) => void;
};

export function AccountDetailDialog({ isNew, account, onSubmit }: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getFormDefaults(schema, account),
  });

  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={(data) => onSubmit({ ...data, externalId: data.externalId || null })}
      title={isNew ? 'Add account' : 'Edit account'}
    >
      <FormTextField control={control} name="name" label="Account name" />
      <FormTextField
        control={control}
        name="code"
        label="Account code"
        isDisabled={!isNew}
        description={!isNew ? 'Not editable after account creation' : undefined}
      />
      <FormTextField control={control} name="primaryName" label="Primary name" />
      <FormTextField control={control} name="primaryEmail" label="Primary email" />
      <FormTextField control={control} name="primaryPhone" label="Primary phone" />
      <FormTextField control={control} name="externalId" label="Billing Id / External Id" />
    </FormDialog>
  );
}

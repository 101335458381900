import { PropsWithChildren, ReactNode, useState } from 'react';
import { FocusScope } from 'react-aria';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components-shad/ui/dialog';
import { Button } from '@/components-shad/ui/button';
import { cn } from '@/shared/helpers';
import { Result } from '@/shared/helpers/Result';

// This doesn't handle transformed values types. Adding this in seems to cause a bunch of typing havoc.
// We may not need transformed values, so leaving it out for now.
type Props<TFieldValues extends FieldValues = FieldValues> = PropsWithChildren<{
  /** Provide the `handleSubmit` function returned by `useForm` here. */
  form: Pick<UseFormReturn<TFieldValues>, 'handleSubmit'>;

  /**
   * Called upon successful form submission with the validated form data. If the form's operation is asynchronous,
   * return a `Promise` here and the dialog will stay open in a pending state until the promise resolves.
   *
   * If you return a `Result` from the function, the dialog will not close if it is a failure result, preventing users
   * from having to refill the form if the mutation fails.
   */
  onSubmit: (data: TFieldValues) => void | Promise<Result> | Promise<void>;

  /** The title of the dialog. */
  title: ReactNode;

  /** An optional description for the dialog to display under the title. */
  description?: ReactNode;

  /** An optional label override for the dialog's cancel button. Defaults to "Cancel". */
  cancelLabel?: ReactNode;

  /** An optional label override for the dialog's submit button. Defaults to "Save". */
  submitLabel?: ReactNode;

  /** Whether the submit button should be disabled. */
  isSubmitDisabled?: boolean;

  /**
   * Whether to immediately find and focus on the first element in the form upon opening the dialog.
   * Defaults to `true`.
   */
  autoFocus?: boolean;

  /** An optional class name to apply to the dialog. */
  className?: string;
}>;

export function FormDialog<TFieldValues extends FieldValues = FieldValues>({
  form: { handleSubmit },
  onSubmit,
  title,
  description,
  cancelLabel = 'Cancel',
  submitLabel = 'Save',
  isSubmitDisabled,
  className,
  children,
  autoFocus = true,
}: Props<TFieldValues>) {
  const [isPending, setIsPending] = useState(false);

  return (
    <DialogContent className={cn('sm:max-w-[480px]', className)}>
      {({ close }) => (
        <form
          className="flex h-full flex-col gap-8"
          onSubmit={handleSubmit(async (data) => {
            setIsPending(true);
            try {
              const result = await onSubmit(data);
              if (!result || result.isSuccess) close();
            } finally {
              setIsPending(false);
            }
          })}
        >
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            {description && <DialogDescription>{description}</DialogDescription>}
          </DialogHeader>

          <div className="flex flex-col gap-6">
            <FocusScope autoFocus={autoFocus}>{children}</FocusScope>
          </div>

          <DialogFooter>
            <Button type="button" variant="secondary" onPress={close}>
              {cancelLabel}
            </Button>
            <Button type="submit" isPending={isPending} isDisabled={isSubmitDisabled}>
              {submitLabel}
            </Button>
          </DialogFooter>
        </form>
      )}
    </DialogContent>
  );
}

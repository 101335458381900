import * as z from 'zod';
import {
  CarrierDTO,
  CarrierServiceCategory,
  CarrierServiceDTO,
} from '@invenco/common-interface/shipping';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { FormCombobox, FormSelect, FormTextField, getFormDefaults } from '@/components-shad/form';
import { ComboboxItem } from '@/components-shad/ui/combobox';
import { carrierServiceCategoryTitle } from '@/shared/title-maps';

const schema = z.object({
  carrierId: z.string().nonempty(),
  name: z.string().nonempty(),
  code: z.string().nonempty(),
  category: z.nativeEnum(CarrierServiceCategory),
  shipmentTrackerGatewayCode: z.string().nullish(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  isLoadingCarriers?: boolean;
  carriers?: CarrierDTO[];
  carrierService: Partial<CarrierServiceDTO>;
  onSubmit: (data: Partial<CarrierServiceDTO>) => void;
};

export function CarrierServiceDetailDialog({
  isLoadingCarriers,
  carriers,
  carrierService,
  onSubmit,
}: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getFormDefaults(schema, carrierService),
  });

  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={onSubmit}
      title="Edit carrier service"
      submitLabel="Add"
    >
      <FormTextField control={control} name="name" label="Name" />
      <FormTextField control={control} name="code" label="Code" />
      <FormCombobox
        control={control}
        name="carrierId"
        label="Carrier"
        defaultItems={carriers}
        isLoading={isLoadingCarriers}
      >
        {(carrier) => <ComboboxItem>{carrier.name}</ComboboxItem>}
      </FormCombobox>
      <FormSelect control={control} name="category" label="Category">
        {Object.values(CarrierServiceCategory).map((category) => (
          <ComboboxItem key={category} id={category}>
            {carrierServiceCategoryTitle[category]}
          </ComboboxItem>
        ))}
      </FormSelect>
      <FormTextField
        control={control}
        name="shipmentTrackerGatewayCode"
        label="Shipment tracker gateway code"
      />
    </FormDialog>
  );
}

import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

// TODO: this may not be needed in Ant 5, as it has the border radius
export const Alert = styled(AntAlert)`
  border-radius: 8px;

  &.ant-alert-with-description {
    .ant-alert-message {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 500;
    }
    .ant-alert-description {
      font-size: 12px;
    }
  }
`;

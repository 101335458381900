import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import {
  Input as AriaInput,
  InputProps as AriaInputProps,
  NumberField as AriaNumberField,
  NumberFieldProps as AriaNumberFieldProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
  Text,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { Button, ButtonProps } from './button';
import { FieldError, FieldGroup, Label } from './field';

const BaseNumberField = AriaNumberField;

const NumberFieldInput = React.forwardRef<React.ElementRef<typeof AriaInput>, AriaInputProps>(
  ({ className, ...props }, ref) => (
    <AriaInput
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'w-fit min-w-0 flex-1 border-r border-transparent bg-background pr-2 outline outline-0 placeholder:text-muted-foreground [&::-webkit-search-cancel-button]:hidden',
          composedClassName,
        ),
      )}
      {...props}
    />
  ),
);

function NumberFieldStepper({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={composeRenderProps(className, (composedClassName) =>
        cn('w-auto grow rounded-none px-0.5 py-0 text-muted-foreground', composedClassName),
      )}
      variant="ghost"
      {...props}
    />
  );
}

function NumberFieldSteppers({ className, ...props }: React.ComponentProps<'div'>) {
  return (
    <div className={cn('absolute right-0 flex h-full flex-col border-l', className)} {...props}>
      <NumberFieldStepper slot="increment" icon={<ChevronUpIcon aria-hidden />} />
      <div className="border-b" />
      <NumberFieldStepper slot="decrement" icon={<ChevronDownIcon aria-hidden />} />
    </div>
  );
}

interface NumberFieldProps extends AriaNumberFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  placeholder?: string;
}

const NumberField = React.forwardRef<React.ElementRef<typeof NumberFieldInput>, NumberFieldProps>(
  ({ label, description, errorMessage, className, placeholder, ...props }, ref) => (
    <BaseNumberField
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      {label && <Label>{label}</Label>}
      <FieldGroup>
        <NumberFieldInput ref={ref} placeholder={placeholder} />
        <NumberFieldSteppers />
      </FieldGroup>
      {description && (
        <Text className="text-sm text-muted-foreground" slot="description">
          {description}
        </Text>
      )}
      <FieldError>{errorMessage}</FieldError>
    </BaseNumberField>
  ),
);

export { BaseNumberField, NumberFieldInput, NumberFieldSteppers, NumberFieldStepper, NumberField };

export type { NumberFieldProps };

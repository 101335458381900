import { ListBox, ListBoxItem } from '@/components-shad/ui/list-box';
import { Selection } from 'react-aria-components';
import { useMemo } from 'react';
import { FilterOption } from '../../../../types';
import { cn } from '../../../../../../shared/helpers';

type Props = {
  items: FilterOption[];
  selectedItems: FilterOption[];
  isMultiSelect: boolean;
  isLoadingItems: boolean;
  onSelectionChange: (selectedKeys: Selection) => void;
};

export function FilterList({
  items: itemsProp,
  selectedItems,
  isMultiSelect,
  isLoadingItems,
  onSelectionChange,
}: Props) {
  const selectedKeys = useMemo(
    () => (isLoadingItems ? [] : selectedItems.map(({ value }) => value)),
    [isLoadingItems, selectedItems],
  );

  const items = useMemo(() => (isLoadingItems ? [] : itemsProp), [isLoadingItems, itemsProp]);

  return (
    <ListBox
      items={items}
      renderEmptyState={() => (isLoadingItems ? 'Loading ' : 'No results')}
      selectionMode={isMultiSelect ? 'multiple' : 'single'}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      className={cn(
        'max-h-[inherit] overflow-auto p-1 outline-none',
        /* Empty */
        'data-[empty]:p-6 data-[empty]:text-center data-[empty]:text-sm',
        /* Kill all the borders always */
        'border-none shadow-none md:border-none md:shadow-none',
      )}
      disallowEmptySelection={false}
    >
      {(item) => <ListBoxItem id={item.value}>{item.displayName}</ListBoxItem>}
    </ListBox>
  );
}

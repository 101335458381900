import React from 'react';
import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { TextArea } from '@/components-shad/ui/text-area';
import { Input } from '@/components-shad/ui/input';
import { FieldDescription, FieldError, Label } from './field';

export const BaseTextField = AriaTextField;

export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  placeholder?: string;
}

export const TextField = React.forwardRef<React.ElementRef<typeof Input>, TextFieldProps>(
  ({ label, description, errorMessage, placeholder, className, ...props }, ref) => (
    <BaseTextField
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      {label && <Label>{label}</Label>}
      <Input ref={ref} placeholder={placeholder} />
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
    </BaseTextField>
  ),
);

export const TextAreaField = React.forwardRef<React.ElementRef<typeof TextArea>, TextFieldProps>(
  ({ label, description, errorMessage, placeholder, className, ...props }, ref) => (
    <BaseTextField
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      {label && <Label>{label}</Label>}
      <TextArea ref={ref} placeholder={placeholder} />
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
    </BaseTextField>
  ),
);

import { Button } from '@/components-shad/ui/button';
import { XIcon } from 'lucide-react';
import { Toolbar } from '@/components-shad/ui/toolbar';
import { useCallback } from 'react';
import { DataFilter, type Props as FilterProps } from '../../data-filter/data-filter';

export type FilterPanelProps = {
  filters: FilterProps[];
};

export function FilterPanel({ filters }) {
  const isFiltering = filters.some(({ selectedItems }) => selectedItems.length > 0);

  const onClear = useCallback(
    () => filters.forEach((filter) => filter.onSelectionChange(new Set([]))),
    [filters],
  );

  return (
    <div className="flex flex-wrap gap-2">
      <Toolbar className="contents">
        {filters.map((props) => (
          <DataFilter {...props} key={props.title} />
        ))}
      </Toolbar>

      {isFiltering && (
        <Button variant="ghost" onPress={onClear} icon={<XIcon />} size="sm">
          Clear
        </Button>
      )}
    </div>
  );
}

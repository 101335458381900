import {
  UNSTABLE_Autocomplete as AriaAutocomplete,
  AutocompleteProps,
  type Selection,
} from 'react-aria-components';
import { SearchFieldBase, SearchFieldInputGroup } from '@/components-shad/ui/searchfield';
import { useState } from 'react';
import { Button } from '@/components-shad/ui/button';
import { FocusScope } from 'react-aria';
import { FilterList } from './components/filter-list/filter-list';
import { FilterOption } from '../../types';
import { cn } from '../../../../shared/helpers';

type Props = {
  items: FilterOption[];
  selectedItems: FilterOption[];
  onInputChange: (searchTerm: string) => void;
  isMultiSelect: boolean;
  isLoadingItems: boolean;
  onSelectionChange: (selectedKeys: Selection) => void;
};

/**
 * Renders an autocomplete component (textbox and list of options) to allow the user to select
 * filter criteria.
 *
 * Known Issues:
 * - escape key, when search term is empty will clear all selections, pressed again will close
 *   the popup
 * - Known bug when using keyboard to control filtering will sometimes select the item above/below
 *   the currently active item, when search term is empty (i.e. viewing all items). Seems to happen
 *   more after you have performed a few search terms, selected/de-selected things and eventually
 *   clear the search term to go back to the full list
 */
export function FilterDropdown({
  items,
  selectedItems,
  onInputChange: onInputChangeProp,
  isMultiSelect,
  isLoadingItems,
  onSelectionChange,
}: Props) {
  const [input, setInput] = useState('');

  const onInputChange: AutocompleteProps['onInputChange'] = (inputValue) => {
    setInput(inputValue);
    onInputChangeProp(inputValue);
  };

  return (
    <FocusScope autoFocus contain>
      <AriaAutocomplete inputValue={input} onInputChange={onInputChange}>
        <SearchFieldBase aria-label="Search options">
          <SearchFieldInputGroup aria-label="Search options" />
        </SearchFieldBase>
        <FilterList
          items={items}
          selectedItems={selectedItems}
          isMultiSelect={isMultiSelect}
          isLoadingItems={isLoadingItems}
          onSelectionChange={onSelectionChange}
        />
      </AriaAutocomplete>
      {
        /**
         * -- Clear button --
         * show clear button any time except when
         * loading or showing the empty state
         * Not worried about show/hide this button when there
         * are selectedOptions or not, if people want to click it
         * they can, it just won't do anything
         */
        !isLoadingItems && selectedItems.length > 0 && (
          <Button
            className={cn('rounded-none border-0 border-t-2')}
            variant="ghost"
            onPress={() => {
              onSelectionChange(new Set([]));
            }}
          >
            Clear selection
          </Button>
        )
      }
    </FocusScope>
  );
}

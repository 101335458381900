import { Fragment, ReactNode, useId } from 'react';
import { cn } from '@/shared/helpers';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { NA_TEXT } from '@/constants';

type DetailListItem = {
  label: string;

  /** Note that passing null or undefined will result in value "N/A" being rendered. */
  value: ReactNode;

  /** Whether this particular value is loading. If true, a skeleton will be displayed instead of the value. */
  isLoading?: boolean;
};

type Props = {
  /** The items to display, each with a label and value. */
  items: DetailListItem[];

  /** An optional title for the list, which will be displayed above it. */
  title?: string;

  /**
   * Whether the list items are loading. If true, each value will be replaced with a skeleton, while the labels
   * will still be displayed.
   */
  isLoading?: boolean;

  /** An optional class name to apply to the list. */
  className?: string;
};

export function DetailList({ items, title, isLoading, className }: Props) {
  const titleId = useId();
  return (
    <div className="space-y-2">
      {title && (
        <span id={titleId} className="text-sm font-semibold">
          {title}
        </span>
      )}
      <dl
        className={cn('grid max-w-full grid-cols-[30%_1fr] gap-2 text-sm', className)}
        aria-labelledby={title ? titleId : undefined}
        aria-busy={isLoading}
      >
        {items.map((item) => (
          <Fragment key={item.label}>
            <dt className="content-center text-muted-foreground">{item.label}</dt>
            <dd className="content-center break-all text-right">
              {isLoading || item.isLoading ? (
                <Skeleton className="h-4 w-full" aria-label="Loading value" />
              ) : (
                (item.value ?? <span className="text-muted-foreground">{NA_TEXT}</span>)
              )}
            </dd>
          </Fragment>
        ))}
      </dl>
    </div>
  );
}

import { XIcon, SearchIcon, LoaderCircleIcon } from 'lucide-react';
import {
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
  Input as AriaInput,
  InputProps as AriaInputProps,
  SearchField as AriaSearchField,
  SearchFieldProps as AriaSearchFieldProps,
  ValidationResult as AriaValidationResult,
  GroupProps as AriaGroupProps,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { FieldDescription, FieldError, FieldGroup, Label } from './field';

function SearchFieldBase({ className, ...props }: AriaSearchFieldProps) {
  return (
    <AriaSearchField
      className={composeRenderProps(className, (composedClassName) =>
        cn('group', composedClassName),
      )}
      {...props}
    />
  );
}

function SearchFieldInput({ className, ...props }: AriaInputProps) {
  return (
    <AriaInput
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'min-w-0 flex-1 bg-background px-2 py-1.5 outline outline-0 placeholder:text-muted-foreground [&::-webkit-search-cancel-button]:hidden',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

function SearchFieldClear({ className, ...props }: AriaButtonProps) {
  return (
    <AriaButton
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'rounded-sm opacity-70 ring-offset-background transition-opacity',
          /* Hover */
          'data-[hovered]:opacity-100',
          /* Disabled */
          'data-[disabled]:pointer-events-none',
          /* Empty */
          'group-data-[empty]:invisible',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

interface SearchFieldInputGroupProps extends AriaGroupProps {
  isLoading?: boolean;
  placeholder?: string;
}

function SearchFieldInputGroup({
  placeholder = 'Search...',
  isLoading,
  ...props
}: SearchFieldInputGroupProps) {
  return (
    <FieldGroup {...props}>
      <SearchIcon aria-hidden className="size-4 text-muted-foreground" />
      <SearchFieldInput
        placeholder={placeholder}
        size={1} // needed to make input shrink properly
      />
      {isLoading ? (
        // TODO: LYNK-3438 replace with accessible loader
        <LoaderCircleIcon aria-hidden className="size-4 animate-spin opacity-50" />
      ) : (
        <SearchFieldClear>
          <XIcon aria-hidden className="size-4" />
        </SearchFieldClear>
      )}
    </FieldGroup>
  );
}

interface SearchFieldProps
  extends AriaSearchFieldProps,
    Pick<SearchFieldInputGroupProps, 'isLoading' | 'placeholder'> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

function SearchField({
  label,
  description,
  className,
  errorMessage,
  placeholder,
  isLoading,
  ...props
}: SearchFieldProps) {
  return (
    <SearchFieldBase
      className={composeRenderProps(className, (composedClassName) =>
        cn('flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      {label && <Label>{label}</Label>}
      <SearchFieldInputGroup placeholder={placeholder} isLoading={isLoading} />
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
    </SearchFieldBase>
  );
}

export { SearchFieldBase, SearchFieldInput, SearchFieldClear, SearchFieldInputGroup, SearchField };
export type { SearchFieldProps };

import type {
  ChannelDTO,
  CreateOrderDTO,
  CustomerDTO,
  ListingDTO,
  OrderDTO,
  OrderLineDTO,
  TagDTO,
  TagType,
  ShippingProfileDTO,
  ListingStatus,
  ListingStrategy,
} from '@invenco/common-interface/sales';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse } from '../types';

export class AxiosSalesGateway extends BaseAxiosGateway {
  name: GatewayName = 'sales';

  async getOrder(id: string, options?: QueryRequestOptions): Promise<OrderDTO> {
    const { data } = await this.httpGet(`/v1/orders/${id}`, {
      ...options,
    });
    return data.order;
  }

  async getOrders(
    query?: {
      take?: number;
      cursor?: string;
      status?: string;
      search?: string;
      holds?: string[];
      skuNames?: string[];
      tagNames?: string[];
      channelNames?: string[];
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<OrderDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      status: query?.status,
      q: query?.search,
      holds: query?.holds?.join(','),
      skuNames: query?.skuNames?.join(','),
      tagNames: query?.tagNames?.join(','),
      channelNames: query?.channelNames?.join(','),
    };
    const { data } = await this.httpGet('/v1/orders', { params, ...options });
    return { ...data, items: data.orders };
  }

  async createOrder(order: CreateOrderDTO): Promise<OrderDTO> {
    const { data } = await this.httpPost<{ order: OrderDTO }>('/v1/orders', {
      data: { order },
    });
    return data.order;
  }

  async updateOrder(id: string, order: Partial<OrderDTO>): Promise<OrderDTO> {
    const { data } = await this.httpPatch<{ order: OrderDTO }>(`/v1/orders/${id}`, {
      data: { order },
    });
    return data.order;
  }

  async releaseOrder(id: string) {
    return this.requestOrderAction(id, 'release');
  }

  async cancelOrder(id: string) {
    return this.requestOrderAction(id, 'cancel');
  }

  async closeOrder(id: string) {
    return this.requestOrderAction(id, 'close');
  }

  async applyManualHold(id: string) {
    return this.requestOrderAction(id, 'apply_manual_hold');
  }

  async releaseManualHold(id: string) {
    return this.requestOrderAction(id, 'release_manual_hold');
  }

  async revertToDraft(id: string) {
    return this.requestOrderAction(id, 'revert_to_draft');
  }

  private async requestOrderAction(id: string, actionUri: string): Promise<OrderDTO> {
    const { data } = await this.httpPost<{ order: OrderDTO }>(`/v1/orders/${id}/${actionUri}`, {});
    return data.order;
  }

  async updateOrderLine(orderId: string, orderLine: Partial<OrderLineDTO>): Promise<OrderLineDTO> {
    const { id, ...updateProps } = orderLine;
    const { data } = await this.httpPatch<{ orderLine: OrderLineDTO }>(
      `/v1/orders/${orderId}/order_lines/${id}`,
      {
        data: { orderLine: updateProps },
      },
    );
    return data.orderLine;
  }

  async addOrderLine(
    orderId: string,
    orderLine: Partial<OrderLineDTO>, // CreateOrderLineDTO isn't correct
  ): Promise<OrderLineDTO> {
    const { data } = await this.httpPost<{ orderLine: OrderLineDTO }>(
      `/v1/orders/${orderId}/order_lines`,
      {
        data: { orderLine },
      },
    );
    return data.orderLine;
  }

  async getChannel(id: string, options?: QueryRequestOptions): Promise<ChannelDTO> {
    const { data } = await this.httpGet(`/v1/channels/${id}`, {
      ...options,
    });
    return data.channel;
  }

  async getChannels(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<ChannelDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
    };
    const { data } = await this.httpGet('/v1/channels', { params, ...options });
    return { ...data, items: data.channels };
  }

  async getCustomers(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<CustomerDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
    };
    const { data } = await this.httpGet('/v1/customers', { params, ...options });
    return { ...data, items: data.customers };
  }

  async getShippingProfile(id: string, options?: QueryRequestOptions): Promise<ShippingProfileDTO> {
    const { data } = await this.httpGet(`/v1/shipping_profiles/${id}`, {
      ...options,
    });
    return data.shippingProfile;
  }

  async getShippingProfiles(
    options?: QueryRequestOptions,
  ): Promise<{ items: ShippingProfileDTO[] }> {
    const { data } = await this.httpGet('/v1/shipping_profiles', {
      ...options,
    });
    return { ...data, items: data.shippingProfiles };
  }

  async createShippingProfile(
    shippingProfile: Partial<ShippingProfileDTO>,
  ): Promise<ShippingProfileDTO> {
    const { data } = await this.httpPost<{ shippingProfile: ShippingProfileDTO }>(
      '/v1/shipping_profiles',
      {
        data: { shippingProfile },
      },
    );
    return data.shippingProfile;
  }

  async updateShippingProfile(
    shippingProfile: Partial<ShippingProfileDTO>,
  ): Promise<ShippingProfileDTO> {
    const { id, ...updateData } = shippingProfile;
    const { data } = await this.httpPatch<{ shippingProfile: ShippingProfileDTO }>(
      `/v1/shipping_profiles/${id}`,
      {
        data: { shippingProfile: updateData },
      },
    );
    return data.shippingProfile;
  }

  async deleteShippingProfile(id: string) {
    await this.httpDelete(`/v1/shipping_profiles/${id}`);
  }

  async getListings(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      statuses?: ListingStatus[];
      channelIds?: string[];
      channelNames?: string[];
      skuIds?: string[];
      strategies?: ListingStrategy[];
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<ListingDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
      statuses: query?.statuses?.join(','),
      skuIds: query?.skuIds?.join(','),
      channelIds: query?.channelIds?.join(','),
      channelNames: query?.channelNames?.join(','),
      strategies: query?.strategies?.join(','),
    };
    const { data } = await this.httpGet('/v1/listings', { params, ...options });
    return { ...data, items: data.listings };
  }

  async createListing(listing: Partial<ListingDTO>): Promise<ListingDTO> {
    const { data } = await this.httpPost<{ listing: ListingDTO }>('/v1/listings', {
      data: { listing },
    });
    return data.listing;
  }

  async updateListing(listing: Partial<ListingDTO>): Promise<ListingDTO> {
    const { id, ...updateData } = listing;
    const { data } = await this.httpPatch<{ listing: ListingDTO }>(`/v1/listings/${id}`, {
      data: { listing: updateData },
    });
    return data.listing;
  }

  async refreshListing(listingId: string): Promise<ListingDTO> {
    const { data } = await this.httpPost<{ listing: ListingDTO }>(
      `/v1/listings/${listingId}/refresh`,
    );
    return data.listing;
  }

  async getTags(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      type?: TagType;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<TagDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
      type: query?.type,
    };
    const { data } = await this.httpGet('/v1/tags', { params, ...options });
    return { ...data, items: data.tags };
  }
}

import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Link } from '@/components-shad/ui/link';
import { DataTable, DataTableHeader, DataTableSearchPanel } from '@/components-shad/data-table';
import { formatDate, formatMoney, truncateNumber } from '@/shared/helpers';
import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import { sortBands } from '@/pages/platform/shipping-prices/sortBands';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { useShippingPriceListPage } from './useShippingPriceListPage';
import { useDataFilter } from '../../../../components-shad/data-filter/hooks/useDataFilter';
import { useGateways } from '../../../../gateways/GatewayProvider';

function getShortPriceBandList(priceBands: ShippingPriceBandDTO[]) {
  return (
    sortBands(priceBands)
      .slice(0, 4)
      .map(({ markup }, index) => {
        const startingAt = index > 0 ? priceBands[index - 1].maxCost : undefined;
        return `${truncateNumber(markup * 100, 1)}%${
          startingAt === 0 || startingAt ? ` at ${formatMoney(startingAt)}` : ''
        }`;
      })
      .join(', ') + (priceBands.length > 4 ? ', ...' : '')
  );
}

export function ShippingPriceListShad() {
  const {
    models: {
      isLoading,
      isLoadingAccounts,
      hasError,
      rows,
      pagination,
      total,
      query,
      accountsById,
      filterSelections,
    },
    operations: { search, navigate, refresh, onClickRow, updateFilters },
  } = useShippingPriceListPage();

  const { accountsGateway } = useGateways();

  const accountsFilter = useDataFilter({
    title: 'Account',
    queryKeyRoot: 'accounts',
    queryFn: (input, { signal }) => accountsGateway.getAccounts(input, { signal }),
    queryResponseMapper: (getAccountsData) =>
      getAccountsData.items.map(({ id, name }) => ({ displayName: name, value: id })),
    isMultiSelect: false,
    selectedItems: (() => {
      // TODO: improve the interface between filterSelections and selectedItems to avoid having to
      // check for array and non-array cases
      if (filterSelections?.accountId) {
        if (Array.isArray(filterSelections.accountId)) {
          return [
            {
              displayName: filterSelections.accountId[0].title,
              value: filterSelections.accountId[0].value,
            },
          ];
        }

        return [
          {
            displayName: filterSelections.accountId.title,
            value: filterSelections.accountId.value,
          },
        ];
      }

      return [];
    })(),
    onChange: (selectedItems) => {
      const item = selectedItems?.[0];

      updateFilters({
        accountId: item ? { value: item.value, title: item.displayName } : undefined,
      });
    },
  });

  return (
    <Page>
      <Header
        title="Shipping prices"
        actions={<Link href="/shipping/prices/new">Create shipping price</Link>}
      />

      <PageContent variant="column">
        <DataTableHeader>
          <DataTableSearchPanel
            loading={isLoading}
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={[accountsFilter]}
          />
        </DataTableHeader>

        <DataTable
          rows={rows}
          loading={isLoading}
          hasError={hasError}
          onClickRow={onClickRow}
          columns={[
            { title: 'Name', key: 'name' },
            {
              title: 'Account',
              key: 'accountId',
              render: (accountId: string) =>
                isLoadingAccounts ? (
                  <Skeleton className="h-4 w-full" />
                ) : (
                  accountsById?.[accountId]?.name
                ),
            },
            {
              title: 'Location',
              key: 'locationName',
              render: (locationName: string) => locationName,
            },
            {
              title: 'Valid from',
              key: 'validFrom',
              render: (validFrom: string) => formatDate(validFrom),
            },
            { title: 'Until', key: 'validTo', render: (validTo: string) => formatDate(validTo) },
            { title: 'Price bands', key: 'priceBands', render: getShortPriceBandList },
          ]}
        />
      </PageContent>
    </Page>
  );
}

import React from 'react';
import { CheckIcon, MinusIcon } from 'lucide-react';
import {
  Checkbox as AriaCheckbox,
  CheckboxGroup as AriaCheckboxGroup,
  CheckboxGroupProps as AriaCheckboxGroupProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
  type CheckboxProps as AriaCheckboxProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { FieldDescription, FieldError, Label } from './field';

const BaseCheckboxGroup = AriaCheckboxGroup;

const Checkbox = React.forwardRef<React.ElementRef<typeof AriaCheckbox>, AriaCheckboxProps>(
  ({ className, children, ...props }, ref) => (
    <AriaCheckbox
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'group/checkbox flex items-center gap-x-3 text-sm leading-none',
          /* Disabled */
          'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-70',
          composedClassName,
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (composedChildren, renderProps) => (
        <>
          <div
            className={cn(
              'flex size-4 shrink-0 items-center justify-center rounded-sm border border-primary text-current shadow transition-[background-color] duration-100',
              /* Focus Visible */
              'group-data-[focus-visible]/checkbox:outline-none group-data-[focus-visible]/checkbox:ring-1 group-data-[focus-visible]/checkbox:ring-ring',
              /* Selected */
              'group-data-[indeterminate]/checkbox:bg-primary group-data-[selected]/checkbox:bg-primary group-data-[indeterminate]/checkbox:text-primary-foreground group-data-[selected]/checkbox:text-primary-foreground',
              /* Disabled */
              'group-data-[disabled]/checkbox:cursor-not-allowed group-data-[disabled]/checkbox:opacity-50',
              /* Invalid */
              'group-data-[invalid]/checkbox:border-destructive group-data-[invalid]/checkbox:group-data-[selected]/checkbox:bg-destructive group-data-[invalid]/checkbox:group-data-[selected]/checkbox:text-destructive-foreground',
              /* Resets */
              'focus-visible:outline-none',
            )}
          >
            {renderProps.isIndeterminate ? (
              <MinusIcon className="size-4 duration-100 animate-in zoom-in" />
            ) : (
              renderProps.isSelected && (
                <CheckIcon className="size-4 duration-100 animate-in zoom-in" />
              )
            )}
          </div>
          {composedChildren}
        </>
      ))}
    </AriaCheckbox>
  ),
);

interface CheckboxGroupProps extends AriaCheckboxGroupProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

const CheckboxGroup = React.forwardRef<
  React.ElementRef<typeof BaseCheckboxGroup>,
  CheckboxGroupProps
>(({ label, description, errorMessage, className, children, ...props }, ref) => (
  <BaseCheckboxGroup
    ref={ref}
    className={composeRenderProps(className, (composedClassName) =>
      cn('group flex flex-col gap-2', composedClassName),
    )}
    {...props}
  >
    {composeRenderProps(children, (composedChildren) => (
      <>
        <Label>{label}</Label>
        {composedChildren}
        {description && <FieldDescription>{description}</FieldDescription>}
        <FieldError>{errorMessage}</FieldError>
      </>
    ))}
  </BaseCheckboxGroup>
));

export { Checkbox, BaseCheckboxGroup, CheckboxGroup };
export type { CheckboxGroupProps };

import { useState } from 'react';
import { ShipmentDTO, ShipmentStatus } from '@invenco/common-interface/shipping';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { useQuery } from '@tanstack/react-query';
import { Result } from 'shared/helpers/Result';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { useGatewayMutation, useQueryWithInput } from '../../../../shared/hooks/queries';
import { Filter, FilterSelections, useDataTable, View } from '../../../../components/data-table';
import { shipmentStatusTitle } from '../../../../shared/title-maps';
import { SortOption, SortSelection } from '../../../../components/data-table/types';
import { ShipmentSortBy } from '../../../../gateways/impl/AxiosShippingGateway';

export type ShipmentRow = Omit<ShipmentDTO, 'id'> & { id: string };
export type FilterKey = 'statuses';

type Models = {
  isLoading: boolean;
  isLoadingAccounts: boolean;
  hasError: boolean;
  accountsById?: Map<string, AccountDTO>;
  selectedShipmentForCost?: ShipmentRow;
  rows?: ShipmentRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  sort?: SortSelection;
  filterValues?: FilterSelections<FilterKey>;
  filters: Filter<FilterKey>[];
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  changeSort: (sort: SortSelection) => void;
  refresh: () => Promise<void>;
  recalculateCost: (shipmentId: string) => Promise<Result>;
  setCost: (shipment: Partial<ShipmentRow>) => Promise<Result>;
  openSetCostModal: (shipment: ShipmentRow) => void;
  closeSetCostModal: () => void;
};

const statusOrder = [
  ShipmentStatus.DRAFT,
  ShipmentStatus.WAITING,
  ShipmentStatus.PROCESSING,
  ShipmentStatus.SHIPPED,
  ShipmentStatus.CANCELLED,
];

export const sortOptions: SortOption[] = [
  { key: 'createdAt', title: 'Created' },
  { key: 'shippedAt', title: 'Shipped' },
];

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...statusOrder.map((status) => ({
    key: status,
    title: shipmentStatusTitle[status],
    filters: { statuses: [{ value: status, title: shipmentStatusTitle[status] }] },
  })),
];

const statusOptions = statusOrder.map((value) => ({ value, title: shipmentStatusTitle[value] }));

export function useShipmentListPage(): ComponentData<Models, Operations> {
  const { accountsGateway, shippingGateway } = useGateways();
  const [tableState, setTableState, { getMultiFilterValues }] = useDataTable({
    multiFilterKeys: ['statuses'],
    views,
    sortOptions,
    defaultSort: { key: 'createdAt', direction: 'desc' },
  });
  const [selectedShipmentForCost, setSelectedShipmentForCost] = useState<ShipmentRow>();

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'shipments',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      statuses: getMultiFilterValues<ShipmentStatus>('statuses'),
      sortBy: tableState.sort?.key as ShipmentSortBy,
      sortDirection: tableState.sort?.direction,
    },
    query: (input, { signal }) => shippingGateway.getShipments(input, { signal }),
  });

  const { data: accountsById, isLoading: isLoadingAccounts } = useQuery({
    queryKey: ['accounts'],
    queryFn: () => accountsGateway.getAccounts(),
    select: ({ items }) => new Map<string, AccountDTO>(items.map((acc) => [acc.id!, acc])),
  });

  // TODO: channel filter (need PLATFORM call for GET /channels)
  const filters: Filter<FilterKey>[] = [
    {
      key: 'statuses',
      title: 'Status',
      options: statusOptions,
      multiple: true,
    },
  ];

  // TODO LYNK-3489: add loading message here
  const { mutate: recalculateCost } = useGatewayMutation({
    mutationFn: (shipmentId: string) => shippingGateway.recalculateShipmentCost(shipmentId!),
    linkedQuery: ['shipments'],
    linkResponseToQuery: false,
    successMessage: 'Shipment cost recalculated',
  });

  const { mutate: setCost } = useGatewayMutation({
    mutationFn: (shipmentInfo: Partial<ShipmentRow>) =>
      shippingGateway.setShipmentCost(shipmentInfo),
    linkedQuery: ['shipments'],
    linkResponseToQuery: false,
    successMessage: 'Shipment cost updated',
  });

  return {
    models: {
      isLoading,
      isLoadingAccounts,
      hasError: isError,
      accountsById,
      selectedShipmentForCost,
      rows: data?.items as ShipmentRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filters,
      filterValues: tableState.filters,
      sort: tableState.sort,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      changeSort: (sort) => setTableState({ sort, page: undefined }),
      updateFilters: (values) =>
        setTableState({ filters: values, view: undefined, page: undefined }),
      refresh: async () => {
        await refetch();
      },
      recalculateCost,
      setCost,
      openSetCostModal: setSelectedShipmentForCost,
      closeSetCostModal: () => setSelectedShipmentForCost(undefined),
    },
  };
}

import * as z from 'zod';
import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormNumberField, getFormDefaults } from '@/components-shad/form';
import { formatMoney } from '@/shared/helpers';
import Decimal from 'decimal.js';
import { Button } from '@/components-shad/ui/button';
import { CheckIcon, PencilIcon, XIcon } from 'lucide-react';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { useEffect } from 'react';

const schema = z.object({
  maxCost: z.number().positive().optional(),
  markup: z.number(),
});
type FormData = z.infer<typeof schema>;

type Props = {
  priceBand: ShippingPriceBandDTO;
  previousBand?: ShippingPriceBandDTO;
  isEditing: boolean;
  exampleMarkup?: number;
  onEdit?: () => void;
  onCancel: () => void;
  onSave: (band: ShippingPriceBandDTO) => void;
  onDelete?: () => void;
};

export function BandItem({
  priceBand,
  previousBand,
  exampleMarkup,
  isEditing,
  onEdit,
  onCancel,
  onSave,
  onDelete,
}: Props) {
  const { handleSubmit, control, reset } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getFormDefaults(schema, priceBand),
  });

  useEffect(() => {
    reset();
  }, [isEditing, reset]);

  const hasNoMaxCost = !priceBand.maxCost && priceBand.maxCost !== 0;
  const getPreviousCostElems = () => (
    <>
      <span>From</span>
      <span className="text-foreground">
        {previousBand && !previousBand.maxCost && previousBand.maxCost !== 0 ? (
          <>&mdash;</>
        ) : (
          // ensure we don't use 0.01 for the first band
          formatMoney(previousBand ? (previousBand.maxCost ?? 0) + 0.01 : 0)
        )}
      </span>
    </>
  );

  return (
    // [&>*>*] targets all grid cells inside the two "contents" wrappers (li and form/div)
    <li className="contents text-sm text-muted-foreground [&>*>*]:flex [&>*>*]:items-center sm:[&>*>*]:h-9">
      {isEditing ? (
        <form onSubmit={handleSubmit(onSave)} className="contents">
          {getPreviousCostElems()}
          <span>to</span>
          <FormNumberField
            control={control}
            name="maxCost"
            aria-label="Maximum total cost"
            className="w-28 text-foreground"
            formatOptions={{ style: 'currency', currency: 'AUD', currencyDisplay: 'narrowSymbol' }}
            minValue={0}
            autoFocus
            hasHiddenError
          />
          <span>apply a markup of</span>
          <FormNumberField
            control={control}
            name="markup"
            aria-label="Markup percentange"
            className="w-28 text-foreground sm:w-24"
            formatOptions={{ style: 'percent', maximumFractionDigits: 2 }}
            hasHiddenError
          />
          <div className="mb-4 flex items-center gap-2 sm:mb-0 sm:justify-end">
            <Button
              variant="secondary"
              onPress={onCancel}
              size="sm"
              aria-label="Cancel"
              icon={<XIcon />}
            />
            <Button type="submit" aria-label="Save" icon={<CheckIcon />} size="sm" />
          </div>
        </form>
      ) : (
        <div className="contents">
          {getPreviousCostElems()}
          <span>{hasNoMaxCost ? 'and' : 'to'}</span>
          <span className="text-foreground">
            {hasNoMaxCost ? 'up' : formatMoney(priceBand.maxCost!)}
          </span>
          <span>apply a markup of</span>
          <span className="text-foreground">
            {new Decimal(priceBand.markup).mul(100).toString()}%
          </span>
          <div className="mb-4 flex items-center gap-2 sm:mb-0 sm:justify-end">
            {exampleMarkup !== undefined && (
              <div className="hidden text-xs text-muted-foreground md:block">
                + {formatMoney(exampleMarkup)}
              </div>
            )}
            <Button
              variant="secondary"
              onPress={onEdit}
              size="sm"
              aria-label="Edit"
              icon={<PencilIcon />}
            />
            <Button
              variant="secondary"
              onPress={onDelete}
              size="sm"
              aria-label="Delete"
              icon={<XIcon />}
            />
          </div>
        </div>
      )}
    </li>
  );
}

export function LoadingBandItem() {
  return (
    <li className="contents text-sm" aria-label="Loading price band">
      <span>From</span>
      <Skeleton className="h-4 w-12" />
      <span>to</span>
      <Skeleton className="h-4 w-12" />
      <span>apply a markup of</span>
      <Skeleton className="h-4 w-12" />
      <div />
    </li>
  );
}

import { Popconfirm, Space } from 'antd';
import { FulfilmentServiceType, IncoTerms } from '@invenco/common-interface/sales';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Header } from 'components/header';
import { Radio } from 'components/radio';
import { Button } from 'components/button';
import { orderedCategories, useShippingProfilePage } from './useShippingProfilePage';
import { Card } from '../../../../components/card';
import { DetailsCard } from '../../../../components/details-card';
import { DetailsBlock } from '../../../../components/details-block';
import { formatDateTime } from '../../../../shared/helpers';
import { DEFAULT_TIME_ZONE, EXPANDED_DATETIME_FORMAT } from '../../../../constants';
import { CarrierList } from './carrier-list/CarrierList';
import { LoadingCarrierList } from './carrier-list/LoadingCarrierList';
import { EditableList } from '../../../../components/editable-list';
import { carrierServiceCategoryTitle } from '../../../../shared/title-maps';

export function ShippingProfile() {
  const {
    models: {
      isNew,
      isLoading,
      isLoadingServices,
      isSaving,
      canSave,
      shippingProfile,
      breadcrumbs,
      carrierServiceCategory,
      carriersById,
      availableCarrierServicesByCategoryAndCarrierId,
      selectedCarrierServicesByCarrierId,
      availableCategories,
    },
    operations: {
      refresh,
      updateProfile,
      save,
      cancel,
      deleteProfile,
      removeShippingDescription,
      addShippingDescription,
      changeCarrierServiceCategory,
      selectCarrierServices,
      deselectCarrierServices,
    },
  } = useShippingProfilePage();

  const renderCarrierList = () => {
    if (isLoadingServices) return <LoadingCarrierList />;
    return carrierServiceCategory === 'custom' ? (
      Object.entries(availableCarrierServicesByCategoryAndCarrierId).map(
        ([category, servicesByCarrierId]) => (
          <div key={category}>
            <CarrierList
              carriersById={carriersById}
              servicesByCarrierId={servicesByCarrierId}
              selectedCarrierServices={shippingProfile.carrierServices}
              title={carrierServiceCategoryTitle[category]}
              onSelected={selectCarrierServices}
              onDeselected={deselectCarrierServices}
              selectable
            />
          </div>
        ),
      )
    ) : (
      <CarrierList
        carriersById={carriersById}
        servicesByCarrierId={selectedCarrierServicesByCarrierId}
      />
    );
  };

  return (
    <Container>
      <Header
        title={shippingProfile.name}
        documentTitle={isNew ? 'New Shipping Profile' : shippingProfile.name}
        backURL="/settings/shipping"
        links={breadcrumbs}
        loading={isLoading}
        loadDescription
        description={
          shippingProfile.createdAt &&
          formatDateTime(shippingProfile.createdAt, DEFAULT_TIME_ZONE, EXPANDED_DATETIME_FORMAT)
        }
        onRefresh={!isNew ? refresh : undefined}
        actionContent={
          <>
            {!isNew && (
              <Popconfirm
                placement="bottomRight"
                title="Are you sure to delete this shipping profile?"
                onConfirm={() => {
                  void deleteProfile();
                }}
                okText="Yes, delete it"
                cancelText="Cancel"
              >
                <Button danger disabled={isSaving}>
                  Delete
                </Button>
              </Popconfirm>
            )}
            <Button disabled={isSaving} onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isSaving}
              disabled={!canSave}
              onClick={() => void save()}
            >
              Save
            </Button>
          </>
        }
        editable
        editInitially={isNew}
        editPlaceholder="Enter shipping profile name..."
        editAriaLabel="Shipping profile name"
        onSave={(name) => updateProfile({ name })}
      />
      <DetailContainer>
        <MainContainer>
          <Card
            title="Match descriptions"
            count={shippingProfile.shippingDescriptions?.length ?? 0}
            description={`The shipping description${
              (shippingProfile.shippingDescriptions?.length ?? 0) > 1 ? 's' : ''
            } below will be used to automatically match incoming orders to this shipping profile`}
          >
            <EditableList
              loading={isLoading}
              items={shippingProfile.shippingDescriptions ?? []}
              onAdd={addShippingDescription}
              onRemove={removeShippingDescription}
              descriptor="match description"
              placeholder="Enter a shipping description to match"
            />
          </Card>

          <Card
            title="Carrier services"
            count={shippingProfile.carrierServices?.length ?? 0}
            description="Select one or more carrier services for this shipping profile"
          >
            <Radio.Group
              options={[
                // Initially display all categories while loading which ones are actually available. We will likely have
                // all or most categories available, so for most use cases it will be less jarring to display all in
                // this scenario instead of none.
                ...(isLoadingServices ? orderedCategories : availableCategories).map(
                  (category) => ({
                    value: category,
                    label: carrierServiceCategoryTitle[category],
                  }),
                ),
                { value: 'custom', label: 'Custom' },
              ]}
              onChange={({ target: { value } }) => changeCarrierServiceCategory(value)}
              value={carrierServiceCategory}
              optionType="button"
              buttonStyle="solid"
              disabled={isLoadingServices}
            />

            {renderCarrierList()}
          </Card>
        </MainContainer>

        <SideContainer>
          <DetailsCard title="Profile options">
            <DetailsBlock
              title="Fulfillment service"
              tooltip={
                'The fulfillment service level determines the speed of processing fulfillments through the warehouse. ' +
                'Set the fulfillment service level for this shipping profile to align with the intended processing time communicated to your customers.'
              }
            >
              <Radio.Group
                onChange={({ target: { value } }) =>
                  updateProfile({ fulfillmentServiceType: value })
                }
                value={shippingProfile.fulfillmentServiceType}
              >
                <Space direction="vertical">
                  <Radio
                    value={FulfilmentServiceType.STANDARD}
                    description="Ships in 1-2 business days"
                  >
                    Standard
                  </Radio>
                  <Radio
                    value={FulfilmentServiceType.PRIORITY}
                    description="Ships same business day"
                  >
                    Priority
                  </Radio>
                </Space>
              </Radio.Group>
            </DetailsBlock>

            <DetailsBlock
              title="Signature on delivery"
              tooltip="If supported by the selected carrier, indicate if the recipient needs to sign for their order, or if it can be left in a safe place."
            >
              <Radio.Group
                onChange={({ target: { value } }) =>
                  updateProfile({ signatureRequired: value === 'yes' })
                }
                value={shippingProfile.signatureRequired ? 'yes' : 'no'}
              >
                <Space direction="vertical">
                  <Radio value="yes" description="Parcel must be signed for">
                    Signature required
                  </Radio>
                  <Radio value="no" description="Leave in a safe place">
                    Authority to leave
                  </Radio>
                </Space>
              </Radio.Group>
            </DetailsBlock>

            <DetailsBlock
              title="Export terms of trade"
              tooltip="If an order requires export, you need to specify who will pay any import duties and taxes applicable."
            >
              <Radio.Group
                onChange={({ target: { value } }) => updateProfile({ incoTerms: value })}
                value={shippingProfile.incoTerms}
              >
                <Space direction="vertical">
                  <Radio
                    value={IncoTerms.DDU}
                    description="Delivered with duties and taxes unpaid - receiver to pay."
                  >
                    DDU
                  </Radio>
                  <Radio
                    value={IncoTerms.DDP}
                    description="Delivered with duties and taxes paid - shipper to pay."
                  >
                    DDP
                  </Radio>
                </Space>
              </Radio.Group>
            </DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>
    </Container>
  );
}

import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { SelectItem } from '@/components-shad/ui/select';
import { FormSelect, FormNumberField } from '@/components-shad/form';
import { ShipmentDTO } from '@invenco/common-interface/shipping';
import { OrderCurrency } from '@invenco/common-interface/sales';

type Props = {
  shipment?: ShipmentDTO;
  onSubmit: (shipment: Partial<ShipmentDTO>) => void;
};

const schema = z.object({
  cost: z.number().nonnegative(),
  currency: z.string().optional(),
});
type FormData = z.infer<typeof schema>;

export function SetCostDialog({ shipment, onSubmit }: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      cost: shipment?.cost,
      currency: OrderCurrency.AUD,
    },
  });

  const submit = async (data: FormData) => {
    if (!shipment) return;
    onSubmit({
      id: shipment.id,
      cost: data.cost,
      currency: OrderCurrency.AUD,
    });
  };

  return (
    <FormDialog form={{ handleSubmit }} onSubmit={submit} title="Set cost">
      <FormNumberField
        control={control}
        name="cost"
        label="Cost"
        formatOptions={{ style: 'currency', currency: 'AUD', currencyDisplay: 'narrowSymbol' }}
        minValue={0}
      />

      <FormSelect name="currency" label="Currency" control={control} isDisabled>
        <SelectItem id={OrderCurrency.AUD}>{OrderCurrency.AUD}</SelectItem>
      </FormSelect>
    </FormDialog>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';

const MINIMUM_DURATION = 300;

export function useVisiblePendingState<ArgT extends any[], RetT extends any>(
  fn: (...args: ArgT) => Promise<RetT>,
): [(...args: ArgT) => Promise<RetT>, boolean] {
  const [isPending, setIsPending] = useState(false);
  const unmountedRef = useRef(false);

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const callback = useCallback(
    async (...args: ArgT): Promise<RetT> => {
      setIsPending(true);
      const now = Date.now();
      const result = await fn(...args);
      const duration = Date.now() - now;
      setTimeout(
        () => {
          if (!unmountedRef.current) {
            setIsPending(false);
          }
        },
        Math.max(0, MINIMUM_DURATION - duration),
      );
      return result;
    },
    [fn],
  );

  return [callback, isPending];
}

import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LocationDTO, ServiceAgreementDTO } from '@invenco/common-interface/accounts';
import { Result } from '@/shared/helpers/Result';
import { FormDialog } from '@/components-shad/form-dialog/FormDialog';
import { TextField } from '@/components-shad/ui/text-field';
import { SelectItem } from '@/components-shad/ui/select';
import { FormDatePicker, FormSelect, getFormDefaults } from '@/components-shad/form';

type Props = {
  isTerminating?: boolean;
  serviceAgreement?: Partial<ServiceAgreementDTO>;
  locations?: LocationDTO[];
  addServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
  updateServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
  terminateServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
};

const schema = z.object({
  locationId: z.string().nonempty(),
  commencementDate: z.string().date().optional(),
  terminationDate: z.string().date().optional(),
});
type FormData = z.infer<typeof schema>;

// TODO: break this dialog into 3 (add, edit, terminate), removing the need for a special submit and schema refinement
export function ServiceAgreementDialog({
  isTerminating,
  serviceAgreement,
  locations,
  addServiceAgreement,
  updateServiceAgreement,
  terminateServiceAgreement,
}: Props) {
  const getFormTitle = () => {
    if (isTerminating) {
      return 'Terminate service agreement';
    }
    return serviceAgreement?.id ? 'Edit service agreement' : 'Add service agreement';
  };

  const getRefinedSchema = () => {
    if (isTerminating) {
      return schema.refine((data) => data.terminationDate !== undefined, {
        message: 'Termination date is required',
        path: ['terminationDate'],
      });
    }
    return schema.refine((data) => data.commencementDate !== undefined, {
      message: 'Commencement date is required',
      path: ['commencementDate'],
    });
  };

  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(getRefinedSchema()),
    defaultValues: getFormDefaults(schema, serviceAgreement),
  });

  const submit = (data: FormData) => {
    if (isTerminating) {
      return terminateServiceAgreement({
        terminationDate: data.terminationDate,
      });
    }
    if (serviceAgreement?.id) {
      return updateServiceAgreement({ commencementDate: data.commencementDate });
    }
    return addServiceAgreement({
      locationId: data.locationId,
      commencementDate: data.commencementDate,
    });
  };

  return (
    <FormDialog
      form={{ handleSubmit }}
      onSubmit={submit}
      title={getFormTitle()}
      submitLabel={isTerminating ? 'Terminate' : 'Save'}
    >
      {serviceAgreement?.id && (
        <TextField
          isDisabled
          label="Name"
          value={serviceAgreement.name}
          validationBehavior="aria"
        />
      )}

      <FormSelect
        name="locationId"
        label="Location"
        control={control}
        items={locations}
        isDisabled={!!serviceAgreement?.id}
      >
        {(location) => <SelectItem>{location.name}</SelectItem>}
      </FormSelect>

      <FormDatePicker
        name="commencementDate"
        label="Commencement date"
        control={control}
        isDisabled={isTerminating}
      />

      {isTerminating && (
        <FormDatePicker name="terminationDate" label="Termination date" control={control} />
      )}
    </FormDialog>
  );
}

import { DetailMain, DetailSide, Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Button } from '@/components-shad/ui/button';
import { Card } from '@/components-shad/ui/card';
import { DataTable } from '@/components-shad/data-table';
import { CarrierServiceCategory } from '@invenco/common-interface/shipping';
import { carrierServiceCategoryTitle } from '@/shared/title-maps';
import { DialogOverlay, DialogTrigger } from '@/components-shad/ui/dialog';
import { DetailList } from '@/components-shad/detail-list/DetailList';
import { useCarrierDetailsPage } from '../useCarrierDetailsPage';
import { CarrierDetailDialog } from './CarrierDetailDialog';

export function CarrierDetailsShad() {
  const {
    models: { isNew, isLoading, isSaving, canSave, carrier, existingName },
    operations: { refresh, save, updateCarrier, onClickService },
  } = useCarrierDetailsPage();

  return (
    <Page>
      <Header
        title={isNew ? 'New carrier' : carrier.name}
        pageTitle={isNew ? 'New carrier' : existingName}
        breadcrumbs={[{ title: 'Carriers', url: '/shipping/carriers' }]}
        hasBackButton
        onRefresh={!isNew ? refresh : undefined}
        actions={
          isNew && (
            <Button isDisabled={!canSave} isPending={isSaving} onPress={() => void save()}>
              Save
            </Button>
          )
        }
      />

      <PageContent variant="split">
        <DetailMain>
          <Card title="Services" count={carrier.carrierServices?.length ?? 0}>
            <DataTable
              simple
              rows={carrier.carrierServices}
              loading={isLoading}
              onClickRow={onClickService}
              columns={[
                { title: 'Name', key: 'name' },
                { title: 'Code', key: 'code' },
                {
                  title: 'Category',
                  key: 'category',
                  render: (category: CarrierServiceCategory) =>
                    carrierServiceCategoryTitle[category],
                },
                {
                  title: 'External Codes',
                  key: 'externalCodes',
                  render: (codes: string[]) => codes.sort().join(', '),
                },
              ]}
            />
          </Card>
        </DetailMain>

        <DetailSide>
          <Card
            title="Details"
            actions={
              isNew && (
                <DialogTrigger defaultOpen={isNew}>
                  <Button variant="outline" size="sm" isDisabled={isLoading}>
                    Edit
                  </Button>

                  <DialogOverlay>
                    <CarrierDetailDialog carrier={carrier} onSubmit={updateCarrier} />
                  </DialogOverlay>
                </DialogTrigger>
              )
            }
          >
            <DetailList
              isLoading={isLoading}
              items={[
                { label: 'Code', value: carrier.code },
                {
                  label: 'Base tracking URL',
                  value: carrier.baseTrackingUrl ? (
                    <code className="text-xs">{carrier.baseTrackingUrl}</code>
                  ) : undefined,
                },
              ]}
            />
          </Card>
        </DetailSide>
      </PageContent>
    </Page>
  );
}

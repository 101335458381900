import { DetailMain, DetailSide, Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Button } from '@/components-shad/ui/button';
import { Card } from '@/components-shad/ui/card';
import { DetailList } from '@/components-shad/detail-list/DetailList';
import { Link } from '@/components-shad/ui/link';
import {
  addressTypeTitle,
  carrierServiceCategoryTitle,
  carrierServiceRateMatchMethodTitle,
  shipmentClassificationTitle,
} from '@/shared/title-maps';
import { Select, SelectItem } from '@/components-shad/ui/select';
import {
  CarrierServiceRateMatchMethod,
  ShipmentClassification,
} from '@invenco/common-interface/shipping';
import { Checkbox, CheckboxGroup } from '@/components-shad/ui/checkbox';
import { AddressType } from '@invenco/common-interface/shared';
import { IncoTerms } from '@invenco/common-interface/sales';
import { EditableList } from '@/components-shad/editable-list/EditableList';
import { DialogOverlay, DialogTrigger } from '@/components-shad/ui/dialog';
import { CarrierServiceDetailDialog } from '@/pages/platform/carriers/carrier-service-details/shad/CarrierServiceDetailDialog';
import { useCarrierServiceDetailsPage } from '../useCarrierServiceDetailsPage';

export function CarrierServiceDetailsShad() {
  const {
    models: {
      carrierService,
      isLoading,
      isLoadingCarriers,
      isSaving,
      isNew,
      canSave,
      carriers,
      existingName,
      existingCarrier,
    },
    operations: { refresh, save, updateCarrierService },
  } = useCarrierServiceDetailsPage();
  return (
    <Page>
      <Header
        title={carrierService.name || (isNew ? 'New carrier service' : undefined)}
        pageTitle={isNew ? 'New carrier service' : existingName}
        breadcrumbs={
          isNew
            ? [{ title: 'Carrier services', url: '/shipping/carrier_services' }]
            : [
                { title: 'Carriers', url: '/shipping/carriers' },
                {
                  title: existingCarrier?.name,
                  url: `/shipping/carriers/${carrierService.carrierId}`,
                  loading: isLoading || isLoadingCarriers,
                },
              ]
        }
        loading={isLoading}
        hasBackButton
        onRefresh={!isNew ? refresh : undefined}
        actions={
          <Button isDisabled={!canSave} isPending={isSaving} onPress={() => void save()}>
            Save
          </Button>
        }
      />

      <PageContent variant="split">
        <DetailMain>
          <Card title="External codes" count={carrierService.externalCodes?.length ?? 0}>
            <EditableList
              items={carrierService.externalCodes}
              onChange={(externalCodes) => updateCarrierService({ externalCodes })}
              descriptor="external code"
            />
          </Card>
        </DetailMain>

        <DetailSide>
          <Card
            title="Details"
            actions={
              <DialogTrigger>
                <Button size="sm" variant="outline" isDisabled={isLoading}>
                  Edit
                </Button>

                <DialogOverlay>
                  <CarrierServiceDetailDialog
                    carrierService={carrierService}
                    onSubmit={updateCarrierService}
                    isLoadingCarriers={isLoadingCarriers}
                    carriers={carriers}
                  />
                </DialogOverlay>
              </DialogTrigger>
            }
          >
            <DetailList
              isLoading={isLoading}
              items={[
                {
                  label: 'Carrier',
                  value: carrierService.carrierId && (
                    <Link
                      size="inline"
                      variant="link"
                      href={`/shipping/carriers/${carrierService.carrierId}`}
                    >
                      {carriers?.find((c) => c.id === carrierService.carrierId)?.name}
                    </Link>
                  ),
                  isLoading: isLoadingCarriers,
                },
                { label: 'Code', value: carrierService.code },
                {
                  label: 'Category',
                  value:
                    carrierService.category && carrierServiceCategoryTitle[carrierService.category],
                },
                {
                  label: 'Shipment tracker gateway code',
                  value: carrierService.shipmentTrackerGatewayCode && (
                    <code className="text-sm">{carrierService.shipmentTrackerGatewayCode}</code>
                  ),
                },
              ]}
            />
          </Card>

          <Card title="Match settings">
            <div className="space-y-6">
              <Select
                label="Rate match method"
                selectedKey={carrierService.rateMatchMethod ?? null}
                onSelectionChange={(value) =>
                  updateCarrierService({ rateMatchMethod: CarrierServiceRateMatchMethod[value] })
                }
                isDisabled={isSaving || isLoading}
              >
                {Object.values(CarrierServiceRateMatchMethod).map((method) => (
                  <SelectItem id={method} key={method}>
                    {carrierServiceRateMatchMethodTitle[method]}
                  </SelectItem>
                ))}
              </Select>

              <CheckboxGroup
                label="Allowable address types"
                value={carrierService.allowableAddressTypes ?? []}
                onChange={(value) =>
                  updateCarrierService({ allowableAddressTypes: value as AddressType[] })
                }
                isDisabled={isSaving || isLoading}
              >
                {Object.values(AddressType).map((type) => (
                  <Checkbox key={type} value={type}>
                    {addressTypeTitle[type]}
                  </Checkbox>
                ))}
              </CheckboxGroup>

              <CheckboxGroup
                label="Allowable shipment classifications"
                value={carrierService.allowableShipmentClassifications ?? []}
                onChange={(value) =>
                  updateCarrierService({
                    allowableShipmentClassifications: value as ShipmentClassification[],
                  })
                }
                isDisabled={isSaving || isLoading}
              >
                {Object.values(ShipmentClassification).map((classification) => (
                  <Checkbox key={classification} value={classification}>
                    {shipmentClassificationTitle[classification]}
                  </Checkbox>
                ))}
              </CheckboxGroup>

              <CheckboxGroup
                label="Allowable terms of trade"
                value={carrierService.allowableIncoTerms ?? []}
                onChange={(value) =>
                  updateCarrierService({ allowableIncoTerms: value as IncoTerms[] })
                }
                isDisabled={isSaving || isLoading}
              >
                {Object.values(IncoTerms).map((term) => (
                  <Checkbox key={term} value={term}>
                    {term}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </div>
          </Card>
        </DetailSide>
      </PageContent>
    </Page>
  );
}

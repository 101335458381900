import styled from 'styled-components';
import { LockOutlined } from '@ant-design/icons';

export const Description = styled.div`
  && button {
    font-size: 12px;
  }

  p,
  ul {
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ul {
    padding-inline-start: 0;
    padding-left: 32px;
  }
`;

// this is a hack to get the spacing of the icon correct whilst not having it drop down when the detail is opened
// not great, but it beats building a custom Alert component in the soon-to-be deprecated UI
export const IconContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
`;

// same hack here
export const ActionContainer = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const LockIcon = styled(LockOutlined)`
  color: var(--blue-6);
`;

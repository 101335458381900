import {
  OrderAction,
  OrderDTO,
  OrderLineDTO,
  OrderLineManagedAction,
  OrderManagedAction,
  OrderStatus,
} from '@invenco/common-interface/sales';
import { Alert } from '@/components/alert';
import { Button } from '@/components/button';
import { useMemo, useState } from 'react';
import { EnumMap } from '@/shared/types';
import { orderActionToManagedAction } from '../actionDetails';
import { ActionContainer, Description, LockIcon, IconContainer } from './styles';

type Props = {
  orderDetails: Partial<OrderDTO>;
  orderLines: Partial<OrderLineDTO>[];
};

const managedActionLabel: EnumMap<OrderManagedAction> = {
  RELEASE: 'Releasing the order',
  CANCEL: 'Cancelling the order',
  CLOSE: 'Closing the order',
};

export function ManagedAlert({
  orderDetails: { availableActions, managedActions, status, externalUrl },
  orderLines,
}: Props) {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  // if we have managed lines and can edit or could revert to draft in order to edit them, show this in the alert detail
  const hasEditableManagedLines = useMemo(
    () =>
      (status === OrderStatus.DRAFT || availableActions?.includes(OrderAction.REVERT_TO_DRAFT)) &&
      orderLines.some((line) => line.managedActions?.includes(OrderLineManagedAction.EDIT)),
    [status, availableActions, orderLines],
  );

  // only show detail about actions that we could normally perform, but are managed
  const availableManagedActions = useMemo(
    () =>
      (availableActions
        ?.map((action) => orderActionToManagedAction[action])
        .filter(
          (managedAction) => managedAction && managedActions?.includes(managedAction),
        ) as OrderManagedAction[]) ?? [],
    [availableActions, managedActions],
  );
  const hasMoreDetail = hasEditableManagedLines || availableManagedActions.length > 0;

  return (
    <Alert
      type="info"
      // NOTE: Shopify is hardcoded for now, but will likely be made dynamic in the future
      message="This order is managed by Shopify"
      description={
        <Description>
          <p>
            Some changes to this order must be made in the Shopify platform.
            {hasMoreDetail && (
              <>
                {' '}
                <Button
                  inline
                  type="link"
                  size="small"
                  onClick={() => setIsDetailOpen(!isDetailOpen)}
                >
                  {isDetailOpen ? 'Less' : 'More'} detail.
                </Button>
              </>
            )}
          </p>
          {isDetailOpen && hasMoreDetail && (
            <>
              <p>The following actions are unavailable from within Lynk:</p>
              <ul>
                {availableManagedActions.map((action) => (
                  <li key={action}>{managedActionLabel[action]}</li>
                ))}
                {hasEditableManagedLines && (
                  <li>
                    Editing items marked by a lock <LockIcon />
                  </li>
                )}
              </ul>
            </>
          )}
        </Description>
      }
      showIcon
      icon={
        <IconContainer>
          <LockIcon />
        </IconContainer>
      }
      action={
        externalUrl ? (
          <ActionContainer>
            <Button type="default" href={externalUrl} target="_blank">
              Manage in Shopify
            </Button>
          </ActionContainer>
        ) : undefined
      }
    />
  );
}

import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { DataTable, DataTableHeader, DataTableSearchPanel } from '@/components-shad/data-table';
import { StatusBadge } from '@/components-shad/StatusBadge';
import { Badge } from '@/components-shad/ui/badge';
import { formatDateTime } from 'shared/helpers';
import { locationStatusType } from 'shared/status-maps';
import { locationStatusTitle } from 'shared/title-maps';
import { useGateways } from '@/gateways/GatewayProvider';
import { useDataFilter } from '@/components-shad/data-filter/hooks/useDataFilter';
import { useLocationListPage, sortOptions } from './useLocationListPage';

export function LocationListShad() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query, sort, filterSelections },
    operations: { search, navigate, changeSort, refresh, onClickRow, updateFilters },
  } = useLocationListPage();

  const { accountsGateway } = useGateways();

  const accountsFilter = useDataFilter({
    title: 'Account',
    queryKeyRoot: 'accounts',
    queryFn: (input, { signal }) => accountsGateway.getAccounts(input, { signal }),
    queryResponseMapper: (getAccountsData) =>
      getAccountsData.items.map(({ id, name }) => ({ displayName: name, value: id })),
    isMultiSelect: false,
    selectedItems: (() => {
      // TODO: improve the interface between filterSelections and selectedItems to avoid having to
      // check for array and non-array cases
      if (filterSelections?.accountId) {
        if (Array.isArray(filterSelections.accountId)) {
          return [
            {
              displayName: filterSelections.accountId[0].title,
              value: filterSelections.accountId[0].value,
            },
          ];
        }

        return [
          {
            displayName: filterSelections.accountId.title,
            value: filterSelections.accountId.value,
          },
        ];
      }

      return [];
    })(),
    onChange: (selectedItems) => {
      const item = selectedItems?.[0];

      updateFilters({
        accountId: item ? { value: item.value, title: item.displayName } : undefined,
      });
    },
  });

  return (
    <Page>
      <Header title="Locations" />
      <PageContent variant="column">
        <DataTableHeader>
          <DataTableSearchPanel
            loading={isLoading}
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
            filters={[accountsFilter]}
          />
        </DataTableHeader>

        <DataTable
          rows={rows}
          columns={[
            { title: 'Name', key: 'name' },
            {
              title: 'Type',
              key: 'type',
              render: (type) => <Badge variant="secondary">{type}</Badge>,
            },
            { title: 'Account', key: 'accountName' },
            { title: 'Warehouse', key: 'warehouseLocationName' },
            {
              title: 'Status',
              key: 'status',
              render: (status) => (
                <StatusBadge
                  status={status}
                  type={locationStatusType}
                  title={locationStatusTitle}
                />
              ),
            },
            {
              title: 'Created',
              key: 'createdAt',
              render: (createdAt: string) => formatDateTime(createdAt),
            },
          ]}
          loading={isLoading}
          hasError={hasError}
          onClickRow={onClickRow}
          sort={sort}
          sortOptions={sortOptions}
          onChangeSort={changeSort}
        />
      </PageContent>
    </Page>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { Menu, MenuItem, MenuPopover, MenuSeparator, MenuTrigger } from '@/components-shad/ui/menu';
import { LogOutIcon, Settings2Icon, SettingsIcon } from 'lucide-react';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { Button } from '@/components-shad/ui/button';
import { getEnv } from 'env';

export function UserMenu() {
  const { isLoading, user, logout } = useAuth0();

  // img over avatar since radix Avatar flickers each time it mounts
  const avatar = isLoading ? (
    <Skeleton className="size-8 shrink-0 rounded-full" />
  ) : (
    <img
      src={user?.picture}
      alt={user?.name}
      className="size-8 shrink-0 overflow-hidden rounded-full bg-primary/10"
    />
  );

  return (
    <MenuTrigger>
      <Button
        variant="transparent"
        aria-label={`User menu for ${user?.name}`}
        className="size-9 shrink-0 px-0"
      >
        {avatar}
      </Button>

      <MenuPopover placement="bottom end" className="max-w-64">
        <div className="flex items-center gap-2 px-2 pb-1.5 pt-2.5">
          {avatar}
          {isLoading ? (
            <div className="flex-1 space-y-1">
              <Skeleton className="h-5 w-36" />
              <Skeleton className="h-3 w-44" />
            </div>
          ) : (
            <div className="grid flex-1 leading-tight">
              <span className="truncate text-sm font-semibold">{user?.name}</span>
              <span className="truncate text-xs">{user?.email}</span>
            </div>
          )}
        </div>
        <Menu>
          <MenuSeparator className="mt-0" />

          <MenuItem onAction={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            <LogOutIcon /> Logout
          </MenuItem>
          {['LOCAL', 'DEV'].includes(getEnv()) && (
            <>
              <MenuSeparator />
              <MenuItem href="/dev-config">
                <SettingsIcon /> Developer config
              </MenuItem>
              <MenuItem href="/features">
                <Settings2Icon /> Features
              </MenuItem>
            </>
          )}
        </Menu>
      </MenuPopover>
    </MenuTrigger>
  );
}
